import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { useAppState } from "../hooks/state";
import common from '../services/common'
import SignatureCanvas from 'react-signature-canvas';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Store } from 'react-notifications-component';
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import { Button, Modal } from 'react-bootstrap'
import Config from '../config/config';
import ReactQuill from 'react-quill';
import ReactPaginate from 'react-paginate';
import 'react-quill/dist/quill.snow.css'; // Import styles
import IICPerformanceModal from '../forms/IICEvaluationForm'
import ChildAbuseFile from './ChildAbuseFile'
import ChildAbusesecFile from './ChildAbusesecFile'
import { debounce } from 'lodash'; // Install lodash if needed: npm install lodash





const EditApplication = () => {
    const sigRef = useRef();

    const depositsigRef= useRef();



    const navigate = useNavigate();

    const [apiData, setApiData] = useState([])
    // const [loading, setLoading] = useState(false);

    const [fieldSettings, setFieldSettings] = useState([])
    const [emailLabelText, setemailLabelText] = useState('Offer Notice');
    const [postionData, setPostionData] = useState([])
    const[formDataval,setformDataval]=useState({})
    const[childformDataval,setchildformDataval]=useState({})
    const[childupdateDataval,setchildupdateDataval]=useState(1)
    const[childjercyupdateDataval,setchildjercyupdateDataval]=useState(1)



    const [emailContent, setEmailContent] = useState('<p>Dear {APPLICANT},</p><p>Please </p><p>Position: {position_type}</p><p>Salary: {salary} per hour {employement_type} employee</p><p>This employment offer is contingent upon the successful completion of background check and all required forms. This offer is not a contract of employment, and either party may terminate employment at any time, with or without cause. As soon as all forms are submitted, we can start assigning you clients. </p><p>{salary} for all services {position_type} {employement_type} Contractor Agreement (jotform.com) {contract_form_link}</p>'); // State to hold email content

    // Preloaded email content (you can replace this with your actual content)
    const [expandedEmails, setExpandedEmails] = useState({});
    const [expandedInstruction, setexpandedInstruction] = useState({});

    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10); // Initialize with a default value
    const [pageCount, setPageCount] = useState(0);

    const [isEmailButtonDisabled, setIsEmailButtonDisabled] = useState(false);
    const [isContractButtonDisabled, setIsContractButtonDisabled] = useState(false);
const [childAbuseData, setchildabusedata] =useState(false);
const [childAbusesecData, setchildabusesecdata] =useState(false);

  
    const handlePageClick = ({ selected }) => {
      setCurrentPage(selected);
    };

    const toggleExpand = (emailId) => {
      setExpandedEmails((prevState) => ({
        ...prevState,
        [emailId]: !prevState[emailId],
      }));
    };
    const toggleExpand3 = (docId) => {
        console.log("started")
        setexpandedInstruction((prevState) => ({
          ...prevState,
          [docId]: !prevState[docId],
        }));
      };

    const handleCloseModal = () => {
        setchildabusedata(false); // This will close the modal
      };
      const handleClosesecModal = () => {
        setchildabusesecdata(false); // This will close the modal
      };
    const [expandedContract, setExpandedContract] = useState({});
    const [currentPage2, setCurrentPage2] = useState(0);
    const [perPage2, setPerPage2] = useState(10); // Initialize with a default value
    const [pageCount2, setPageCount2] = useState(0);
  
    const handlePageClick2 = ({ selected }) => {
        setCurrentPage2(selected);
    };

    const toggleExpand2 = (emailId) => {
        setExpandedContract((prevState) => ({
        ...prevState,
        [emailId]: !prevState[emailId],
      }));
    };

    const printContent = (content, id) =>{
        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.open();
        printWindow.document.write(`${content}`);
        printWindow.document.close();
        printWindow.print();        

        setTimeout(function(){
        //    printWindow.close()
        }, 2000)        
    }

    const [loading, setLoading] = useState(false);
    const [isShowContract, setisShowContract] = useState(false);
    const [signature, setSignature] = useState(null);

    const [depositsignature, setDepositsignature] = useState(null);
    const [authCheck, setAuthCheck] = useState();
    const [dynamicField, setDynamicField] = useAppState();
    const [positionId, setPositionId] = useState([]);
    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedValue2, setSelectedValue2] = useState(5);
    const [emailTypeId, setemailTypeId] = useState(5);
    const [emailSubject, setemailSubject] = useState('');
    const handleChange = async (e) => {
        const newValue = e.target.value;
        setSelectedValue2(newValue);
        
        var getEmailData = await common.getEmailContent(candidate_id_main, newValue);
        if (getEmailData?.data?.status == 202) {
                var emailContent =  getEmailData?.data?.message;
                var emailSubject =  getEmailData?.data?.subject;
                //setemailSubject(emailSubject);
                console.log(emailContent);
                console.log(emailSubject);
                console.log(newValue);
                setValue('offerSubject', emailSubject);
                setValue('offer_email_content', emailContent);
                if(newValue == 4){
                    setemailLabelText('Attend Orientation');
                }if(newValue == 5){
                    setemailLabelText('Offer Notice');
                }else if(newValue == 2){
                    setemailLabelText('Tax Forms & Direct Deposit');
                }else if(newValue == 7){
                    setemailLabelText('Termination');
                }else if(newValue == 13){
                    setemailLabelText('Background Check');
                }else if(newValue == 8){
                    setemailLabelText("Pay Increase");
                }
                setemailTypeId(newValue);
        }
    };

    const handleChangePosition = async (e) => {
        const newValue = e.target.value;
        setSelectedValue(newValue);
        var getEmailData = await common.getContractContent(candidate_id_main, newValue);
        if (getEmailData?.data?.status == 202) {
                setValue('contractSubject', 'Employment Contract - POSS HR & Staffing Pro system');
                var contract_email_content =  getEmailData?.data?.contract_email_content;
                // contract_email_content = contract_email_content.replaceAll('{date}', "");
                // contract_email_content = contract_email_content.replaceAll('{singature}', "");
                // contract_email_content = contract_email_content.replaceAll('{singature}', "");
                // contract_email_content = contract_email_content.replaceAll('{Pick a Date}', "");
                var contract_email_content_2 =  getEmailData?.data?.contract_email_content_2;
                
                // contract_email_content_2 = contract_email_content_2.replaceAll('{date}', "");
                // contract_email_content_2 = contract_email_content_2.replaceAll('{singature}', "");
                // contract_email_content_2 = contract_email_content_2.replaceAll('{singature}', "");
                // contract_email_content_2 = contract_email_content_2.replaceAll('{Pick a Date}', "");
                setValue('contract_email_content', "");
                setValue('contract_email_content_2', "");
                setValue('contract_email_content', contract_email_content);
                if(contract_email_content_2 != ""){
                    setValue('contract_email_content_2', contract_email_content_2);
                    setisShowContract(true);
                }else{
                    
                    setisShowContract(false);
                }
        }
    };

    const handleChangePosition2 = async (value) => {
        const newValue = value;
        setSelectedValue(newValue);
        var getEmailData = await common.getContractContent(candidate_id_main, newValue);
        if (getEmailData?.data?.status == 202) {
                var contract_email_content =  getEmailData?.data?.contract_email_content;
                // contract_email_content = contract_email_content.replaceAll('{date}', "");
                // contract_email_content = contract_email_content.replaceAll('{singature}', "");
                // contract_email_content = contract_email_content.replaceAll('{singature}', "");
                // contract_email_content = contract_email_content.replaceAll('{Pick a Date}', "");
                var contract_email_content_2 =  getEmailData?.data?.contract_email_content_2;
                setValue('contract_email_content', "");
                setValue('contract_email_content_2', "");
                // contract_email_content_2 = contract_email_content_2.replaceAll('{date}', "");
                // contract_email_content_2 = contract_email_content_2.replaceAll('{singature}', "");
                // contract_email_content_2 = contract_email_content_2.replaceAll('{singature}', "");
                // contract_email_content_2 = contract_email_content_2.replaceAll('{Pick a Date}', "");
                setValue('contract_email_content', contract_email_content);
                if(contract_email_content_2 != ""){
                    setValue('contract_email_content_2', contract_email_content_2);
                    setisShowContract(true);
                }else{
                    setisShowContract(false);
                }
        }
    };
    const [ddshow, setDdshow] = useState(false);
    const ddhandleClose = () => {




     

   
        reset2(apiData?.candidateDeposit?.[0])

        setTimeout(() => {
            setDdshow(false);
        }, 400);



    }

    

    const ddhandleShow = () =>
    {

        if (apiData?.candidateDeposit?.[0]?.date_of_birth && apiData?.candidateDeposit?.[0]?.date_of_birth != "" && apiData?.candidateDeposit?.[0]?.date_of_birth != "0000-00-00") {
            apiData.candidateDeposit[0].date_of_birth = moment(apiData.candidateDeposit[0].date_of_birth).toDate();


        }

        reset2(apiData?.candidateDeposit?.[0])
        setDdshow(true);
    } 
    const [iicShow, setIicshow] = useState(false);
    const iichandleClose = () => {

        reset10(apiData?.candidateFormIIC?.[0])

        setTimeout(() => {
            setIicshow(false);
        }, 400);

    }




    const iichandleShow = () => {

        setIicshow(true);




        if (apiData?.candidateFormIIC?.[0]?.eval_period_from && apiData?.candidateFormIIC?.[0]?.eval_period_from != "" && apiData?.candidateFormIIC?.[0]?.eval_period_from != "0000-00-00") {
            apiData.candidateFormIIC[0].eval_period_from = moment(apiData.candidateFormIIC[0].eval_period_from).toDate();
        }
        if (apiData?.candidateFormIIC?.[0]?.eval_period_to && apiData?.candidateFormIIC?.[0]?.eval_period_to != "" && apiData?.candidateFormIIC?.[0]?.eval_period_to != "0000-00-00") {
            apiData.candidateFormIIC[0].eval_period_to = moment(apiData.candidateFormIIC[0].eval_period_to).toDate();
        }
        if (apiData?.candidateFormIIC?.[0]?.employee_date && apiData?.candidateFormIIC?.[0]?.employee_date != "" && apiData?.candidateFormIIC?.[0]?.employee_date != "0000-00-00") {
            apiData.candidateFormIIC[0].employee_date = moment(apiData.candidateFormIIC[0].employee_date).toDate();
        }
        if (apiData?.candidateFormIIC?.[0]?.supervisor_date && apiData?.candidateFormIIC?.[0]?.supervisor_date != "" && apiData?.candidateFormIIC?.[0]?.supervisor_date != "0000-00-00") {
            apiData.candidateFormIIC[0].supervisor_date = moment(apiData.candidateFormIIC[0].supervisor_date).toDate();
        }


        reset10(apiData?.candidateFormIIC?.[0])
    }
    const [i9show, setI9show] = useState(false);
    const i9handleClose = () => {

        reset3(apiData?.I9EligibilityForm?.[0])

        setTimeout(() => {
            setI9show(false);
        }, 400);

    }




    const i9handleShow = () => {

        setI9show(true);




        if (apiData?.I9EligibilityForm?.[0]?.date_of_birth && apiData?.I9EligibilityForm?.[0]?.date_of_birth != "" && apiData?.I9EligibilityForm?.[0]?.date_of_birth != "0000-00-00") {
            apiData.I9EligibilityForm[0].date_of_birth = moment(apiData.I9EligibilityForm[0].date_of_birth).toDate();


        }


        reset3(apiData?.I9EligibilityForm?.[0])
    }


    const [w4show, setW4show] = useState(false);
    const w4handleClose = () => {
        reset4(apiData?.FederalW4Form?.[0])

        setTimeout(() => {
            setW4show(false);
        }, 400);

    }


    const w4handleShow = () => {
        setW4show(true);


        if (apiData?.FederalW4Form?.[0]?.first_date_employment && apiData?.FederalW4Form?.[0]?.first_date_employment != "" && apiData?.FederalW4Form?.[0]?.first_date_employment != "0000-00-00") {
            apiData.FederalW4Form[0].first_date_employment = moment(apiData.FederalW4Form[0].first_date_employment).toDate();


        }


        reset4(apiData?.FederalW4Form?.[0])

    }


    const [njshow, setNjshow] = useState(false);
    const [w9show, setw9show] = useState(false);
    const [genderInequityshow, setGenderInequityshow] = useState(false);
    const njhandleClose = () => {
        reset5(apiData?.NJEmployeeWithholding?.[0])

        setTimeout(() => {
            setNjshow(false);
        }, 400);

    }

    const w9handleClose = () => {
        reset7(apiData?.candidateFormW9?.[0])

        setTimeout(() => {
            setw9show(false);
        }, 400);

    }
    const w9handleShow = () => {
        if (apiData?.candidateFormW9?.[0]?.date && apiData?.candidateFormW9?.[0]?.date != "" && apiData?.candidateFormW9?.[0]?.date != "0000-00-00") {
            apiData.candidateFormW9[0].date = moment(apiData.candidateFormW9[0].date).toDate();
        }
        setw9show(true);
        reset7(apiData?.candidateFormW9?.[0])
    }

    const genderInequityhandleShow = () => {
        if (apiData?.TaxGenderInequity?.[0]?.date && apiData?.TaxGenderInequity?.[0]?.date != "" && apiData?.TaxGenderInequity?.[0]?.date != "0000-00-00") {
            apiData.TaxGenderInequity[0].date = moment(apiData.TaxGenderInequity[0].date).toDate();
        }
        setGenderInequityshow(true);
        reset8(apiData?.TaxGenderInequity?.[0])
    }
const genderInequityhandleShows = () =>{
 
    setchildabusedata(true)
}
 
const gendersecInequityhandleShows = () =>{
    setchildabusesecdata(true)
}
    const genderInequityhandleClose = () => {
        reset8(apiData?.TaxGenderInequity?.[0])
        setTimeout(() => {
            setGenderInequityshow(false);
        }, 400);
    }

    const njhandleShow = () => {

        setNjshow(true);
        reset5(apiData?.NJEmployeeWithholding?.[0])
    }




    const [scoreCardshow, setScoreCardshow] = useState(false);
    const [additionalDocumentshow, setAdditionalDocumentsshow] = useState(false);
    const scoreCardhandleClose = () => {
        reset6(apiData?.NJEmployeeWithholding?.[0])

        setTimeout(() => {
            setScoreCardshow(false);
        }, 400);

    }
    const scoreCardhandleShow = () => {

        setScoreCardshow(true);
        reset6(apiData?.NJEmployeeWithholding?.[0])
    }


    const additionalDocumentsShow = () => {
        setAdditionalDocumentsshow(true);
        reset9(apiData?.NJEmployeeWithholding?.[-1])
    }

    const additionalDocumentClose = () => {
        reset9(apiData?.NJEmployeeWithholding?.[-1])
        setTimeout(() => {
            setAdditionalDocumentsshow(false);
        }, 400);
    }


    var user_data = localStorage.getItem("user_data");
    var user_data_json = JSON.parse(user_data);


    var classNameDependsOnCondtion = user_data_json?.role_id == 4 ? "" : "m-0";

    const {
        control,
        register,
        getValues,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        defaultValues: apiData
    });


    const {
        control: control2,
        register: register2,
        formState: { errors: errors2 },
        getValues: getValues2,
        handleSubmit: handleSubmit2,
        reset: reset2,
    } = useForm({

        defaultValues: apiData?.candidateDeposit?.[0]
    });



    const {
        control: control3,
        register: register3,
        formState: { errors: errors3 },
        getValues: getValues3,
        handleSubmit: handleSubmit3,
        reset: reset3,
    } = useForm({

        defaultValues: apiData?.I9EligibilityForm?.[0]
    });


    const {
        control: control4,
        register: register4,
        formState: { errors: errors4 },
        getValues: getValues4,
        handleSubmit: handleSubmit4,
        reset: reset4,
    } = useForm({

        defaultValues: apiData?.FederalW4Form?.[0]
    });

    const {
        control: control7,
        register: register7,
        formState: { errors: errors7 },
        getValues: getValues7,
        handleSubmit: handleSubmit7,
        reset: reset7,
    } = useForm({

        defaultValues: apiData?.candidateFormW9?.[0]
    });

    const {
        control: control8,
        register: register8,
        formState: { errors: errors8 },
        getValues: getValues8,
        handleSubmit: handleSubmit8,
        reset: reset8,
    } = useForm({

        defaultValues: apiData?.TaxGenderInequity?.[0]
    });

    const {
        control: control5,
        register: register5,
        formState: { errors: errors5 },
        getValues: getValues5,
        handleSubmit: handleSubmit5,
        reset: reset5,
    } = useForm({

        defaultValues: apiData?.NJEmployeeWithholding?.[0]
    });


    const {
        control: control6,
        register: register6,
        formState: { errors: errors6 },
        getValues: getValues6,
        handleSubmit: handleSubmit6,
        reset: reset6,
    } = useForm({
        defaultValues: apiData?.NJEmployeeWithholding?.[0]
    });

    const {
        control: control9,
        register: register9,
        formState: { errors: errors9 },
        getValues: getValues9,
        handleSubmit: handleSubmit9,
        reset: reset9,
    } = useForm({
        defaultValues: [apiData?.NJEmployeeWithholding?.[0]]
    });
    const {
        control: control10,
        register: register10,
        formState: { errors: errors10},
        getValues: getValues10,
        handleSubmit: handleSubmit10,
        reset: reset10
    } = useForm({
        defaultValues: [apiData?.candidateFormIIC?.[0]]
    });


    const formatIntoPng = () => {


        if (sigRef.current) {
            setSignature(sigRef.current.toDataURL());
            // const dataURL = sigRef.current.toDataURL();

            return sigRef.current.getTrimmedCanvas().toDataURL("image/png");
            // return dataURL;
        }


    }
    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    }

    
    const depositformatIntoPng = () => {



        if (depositsigRef.current) {
            setDepositsignature(depositsigRef.current.toDataURL());
           // const dataURL = depositsigRef.current.toDataURL();

       // console.log(dataURL);

            return depositsigRef.current.getTrimmedCanvas().toDataURL("image/png");




            // return dataURL;
        }


    }
    const depositclearSignature = () => {


        reset2({
            electronically_signed_new: ""
          })
        depositsigRef.current.clear();
        setDepositsignature(null);
    }


    const arr = [];





    const [inputFields, setInputFields] = useState(arr);


    const addInputField = () => {
        setInputFields([...inputFields, {
            name: '',
            email: '',
            phone: '',
            relationship: ''
        }])

    }
    const removeInputFields = (index, length) => {
        const rows = [...inputFields];
        rows.splice(index, 1);


        setInputFields(rows);
    }

    const params = useParams();

    var candidate_id_main = params.id;


    //console.log(user_data_json);
    if (user_data_json?.role_id == 4) {
        var candidate_id_main = user_data_json.candidate_id;
    }


    const onErrors = async (data) => {


        console.log(data);

        if (data != "") {
            //  alert('aaaa');

            Store.addNotification({
                title: "Error!",
                message: "Please complete all the required fields.",
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });


        }
    }


    const onSubmitOfferEmail = async (data) => {
        setIsEmailButtonDisabled(true);
        const finaldata = new FormData()
        finaldata.append('candidate_id', candidate_id_main);
        finaldata.append('offer_email_content', data.offer_email_content);
        finaldata.append('email', data.email);
        finaldata.append('send_mail', 1);
        finaldata.append('emailTypeId', emailTypeId);
        finaldata.append('emailSubject', data.offerSubject);
        var updatecandidateapi = await common.updatecandidate(finaldata);
        setIsEmailButtonDisabled(false);
        if (updatecandidateapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }
        if (updatecandidateapi?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: updatecandidateapi?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails(1);
            setLoading(false);
        }
        else {
            setLoading(false);
            Store.addNotification({
                title: "Error!",
                message: updatecandidateapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }
    };

    const onSubmitContractEmail = async (data) => {
        setIsContractButtonDisabled(true);
        const finaldata = new FormData()
        finaldata.append('candidate_id', candidate_id_main);
        finaldata.append('contract_email_content', data.contract_email_content);
        finaldata.append('contract_email_content_2', data.contract_email_content_2);
        finaldata.append('contract_email_message', data.contract_email_message);
        finaldata.append('contractSubject', data.contractSubject);
        
        finaldata.append('email', data.email);
        finaldata.append('send_mail', 1);
        var updatecandidateapi = await common.updatecandidate(finaldata);
        setIsContractButtonDisabled(false);
        if (updatecandidateapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }
        
        if (updatecandidateapi?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: updatecandidateapi?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails();
            setLoading(false);
        }
        else {
            setLoading(false);
            Store.addNotification({
                title: "Error!",
                message: updatecandidateapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }
    };

    const onSubmit = async (data) => {
        //  setState({ ...state, ...data });        
        console.log("kannan",data)
        const finaldata = new FormData()
        finaldata.append('candidate_id', candidate_id_main);
        finaldata.append('first_name', data.first_name);
        finaldata.append('last_name', data.last_name);
        finaldata.append('email', data.email);
        finaldata.append('address', data.address);
        finaldata.append('address_2', data.address_2);
        finaldata.append('city', data.city);
        finaldata.append('state', data.state);
        finaldata.append('zipcode', data.zipcode);
        finaldata.append('phone', data.phone);
        finaldata.append('offer_email_content', data.offer_email_content);
        finaldata.append('contract_email_content', data.contract_email_content);
        finaldata.append('legally_entitled_us', data.legally_entitled_us);

        finaldata.append('diploma_new_checkbox', data.diploma_new_checkbox);
        finaldata.append('certification_bcba_rbt_new_checkbox', data.certification_bcba_rbt_new_checkbox);
        finaldata.append('professional_liability_insurance_new_checkbox', data.professional_liability_insurance_new_checkbox);
        finaldata.append('drivers_license_new_checkbox', data.drivers_license_new_checkbox);
        finaldata.append('vaccine_card_front_new_checkbox', data.vaccine_card_front_new_checkbox);
        finaldata.append('vaccine_card_back_new_checkbox', data.vaccine_card_back_new_checkbox);
        finaldata.append('cpr_certificate_new_checkbox',data.cpr_certificate_new_checkbox)
        finaldata.append('iic_professional_certificate_checkbox',data.iic_professional_certificate_checkbox)

        

        // console.log(data.vaccine_card_back_new_checkbox);
        var professional_references_update = [];
        var datata = data.professional_references;
        if(datata.length < 3){
            setLoading(false);

            Store.addNotification({
                title: "Error!",
                message: "Atleast three professional references should be required",
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return false;
        }
        inputFields.map((data, index) => {
            console.log(data);
            professional_references_update.push(datata[index])



        });


        //  console.log(professional_references_update);


        finaldata.append('employment_type_id', data.employment_type_id);
        finaldata.append('position_id', data.position_id);
        finaldata.append('desired_type', data.desired_type);
        finaldata.append('area_type', data.area_type);
        finaldata.append('perform_the_essential_functions', data.perform_the_essential_functions);
        finaldata.append('date_available', moment(data.date_available).format("YYYY-MM-DD"));
        finaldata.append('final_paycheck', moment(data.final_paycheck).format("YYYY-MM-DD"));

        finaldata.append('highest_level_education', data.highest_level_education);
        finaldata.append('highest_level_education_other', data.highest_level_education_other);


        finaldata.append('crime', data.crime);
        finaldata.append('crime_yes', data.crime_yes);


        finaldata.append('professional_references', JSON.stringify(professional_references_update));

        if (data.new_signature == undefined) {
            data.new_signature = "";
        }



        finaldata.append('name_of_applicant', data.name_of_applicant);
        finaldata.append('signature', data.new_signature);
        finaldata.append('resume', data.resume_new[0]);
        finaldata.append('availability', data.availability);





        finaldata.append('password', data.password);
        finaldata.append('county', data.county);
        finaldata.append('rate', data.rate);
        finaldata.append('additional_languages_spoken', data.additional_languages_spoken);
        finaldata.append('bt_skill_rating', data.bt_skill_rating);
        finaldata.append('special_note', data.special_note);
        finaldata.append('termination_reason', data.termination_reason);
        
        finaldata.append('assign_to', data.assign_to);






        finaldata.append('diploma', data.diploma_new[0]);
        finaldata.append('certification_bcba_rbt', data.certification_bcba_rbt_new[0]);
        finaldata.append('professional_liability_insurance', data.professional_liability_insurance_new[0]);
        finaldata.append('drivers_license', data.drivers_license_new[0]);
        finaldata.append('vaccine_card_front', data.vaccine_card_front_new[0]);
        finaldata.append('vaccine_card_back', data.vaccine_card_back_new[0]);
        finaldata.append('cpr_file_certificate',data?.cpr_file_certificate[0])
        // if(data?.iic_professional_certificate && data?.iic_professional_certificate.length >0)
        finaldata.append('iic_professional_certificate',data?.iic_professional_certificate[0])


        






        finaldata.append('certification_expiration', moment(data.certification_expiration).format("YYYY-MM-DD"));

        finaldata.append('completed_application', data.completed_application);
        finaldata.append('prof_of_liability_insurance', data.prof_of_liability_insurance);
        finaldata.append('copy_of_certification', data.copy_of_certification);
        finaldata.append('background_check_request', data.background_check_request);
        finaldata.append('insurance_recheck', data.insurance_recheck);
        finaldata.append('bcba_supervision_certification', data.bcba_supervision_certification);
        finaldata.append('send_ready_work_to_manager', data.send_ready_work_to_manager);
        finaldata.append('w2_contract', data.w2_contract);
        finaldata.append('pay_email_setup_direct_deposit', data.pay_email_setup_direct_deposit);
        finaldata.append('tp_due_date_list', data.tp_due_date_list);
        finaldata.append('provider_completely_hired', data.provider_completely_hired);
        finaldata.append('bcba_professional_license', data.bcba_professional_license);
        finaldata.append('supervision_documentation', data.supervision_documentation);
        finaldata.append('annual_evaluation', data.annual_evaluation);
        finaldata.append('documentation_that_staff_training_ceus', data.documentation_that_staff_training_ceus);
        finaldata.append('w2_jot_form_application_completed', data.w2_jot_form_application_completed);
        finaldata.append('bcba_completed_jotform_application', data.bcba_completed_jotform_application);
       finaldata.append('certification_bcba_rbt_check', data.certification_bcba_rbt_check);
       finaldata.append('independent_contract', data.independent_contract);
       finaldata.append('w_9_workers_to_complete', data.w_9_workers_to_complete);
       
       finaldata.append('jotform_application_completed', data.jotform_application_completed);
       
       finaldata.append('professional_license', data.professional_license);
       finaldata.append('license_number', data.license_number);
       finaldata.append('expiration_date', moment(data.expiration_date).format("YYYY-MM-DD"));
       finaldata.append('college_diploma', data.college_diploma);
       finaldata.append('professional_liability_insurance_check', data.professional_liability_insurance_check);
       finaldata.append('completed_adp_background_check', data.completed_adp_background_check);
       finaldata.append('identogo_fingerprint_receipt', data.identogo_fingerprint_receipt);
       finaldata.append('send_email_to_dcf_for_approval', data.send_email_to_dcf_for_approval);
       finaldata.append('attend_information_session', data.attend_information_session);
       finaldata.append('direct_deposit_and_w_9', data.direct_deposit_and_w_9);
       finaldata.append('reference_check_contact_person', data.reference_check_contact_person);
       finaldata.append('sent_contractor_agreement', data.sent_contractor_agreement);
       finaldata.append('activated_adp_account', data.activated_adp_account);
       finaldata.append('entered_information_in_poss_portal', data.entered_information_in_poss_portal);
       finaldata.append('endorsed_to_cmo', data.endorsed_to_cmo);
       finaldata.append('endorsed_to_clinical_supervisor', data.endorsed_to_clinical_supervisor);
       
       finaldata.append('activated_access_cyber', data.activated_access_cyber);
       finaldata.append('sent_email_of_cyber_access', data.sent_email_of_cyber_access);
       finaldata.append('added_to_adp', data.added_to_adp);
       
       
       
        


        finaldata.append('background_check_link', data.background_check_link);
        finaldata.append('adp_background_check_ordered', data.adp_background_check_ordered);
        finaldata.append('adp_background_check_seperate', data.adp_background_check_seperate);
        finaldata.append('send_request_attend', data.send_request_attend);
        finaldata.append('actually_attended', data.actually_attended);
        finaldata.append('setup_rasmus_account', data.setup_rasmus_account);
        finaldata.append('prescreening_email', data.prescreening_email);
        finaldata.append('evidence_of_ongoing_staff_training', data.evidence_of_ongoing_staff_training);
        finaldata.append('aba_team_manager', data.aba_team_manager);
        finaldata.append('send_email_agreement', data.send_email_agreement);
        finaldata.append('employee_agreement_received', data.employee_agreement_received);

        finaldata.append('contract_on_hr_folder', data.contract_on_hr_folder);
        finaldata.append('activate_poss_account', data.activate_poss_account);
        finaldata.append('email_sent_for_direct_deposit_setup', data.email_sent_for_direct_deposit_setup);
        finaldata.append('check_direct_deposit_link_completed', data.check_direct_deposit_link_completed);
        finaldata.append('check_provider_added_to_adp_system', data.check_provider_added_to_adp_system);
        finaldata.append('nj_w4_form_returned', data.nj_w4_form_returned);
        finaldata.append('w_4_form_returned', data.w_4_form_returned);
        finaldata.append('w_4_recheck', data.w_4_recheck);
        finaldata.append('i_9_form', data.i_9_form);
        finaldata.append('i_9_recheck', data.i_9_recheck);
        finaldata.append('nj_w4_recheck', data.nj_w4_recheck);
        finaldata.append('adp_recheck', data.adp_recheck);
        finaldata.append('reference_check', data.reference_check);
        finaldata.append('rbt_professional_license', data.rbt_professional_license);
        finaldata.append('job_description', data.job_description);
        finaldata.append('supervisor', data.supervisor);


        finaldata.append('performance_eval', data.performance_eval);
        finaldata.append('documentation_that_staff_received_training', data.documentation_that_staff_received_training);
        finaldata.append('supervision_activities', data.supervision_activities);
        finaldata.append('cpr_certification', data.cpr_certification);
        finaldata.append('resume_checklist', data.resume_checklist);
        finaldata.append('diploma_checklist', data.diploma_checklist);
        finaldata.append('drivers_license_checklist', data.drivers_license_checklist);
        finaldata.append('vaccine_card_checklist', data.vaccine_card_checklist);
        finaldata.append('employee_handbook_acknowledgement', data.employee_handbook_acknowledgement);
        finaldata.append('allow_candidate_to_edit_deposit', data.allow_candidate_to_edit_deposit);
        
        finaldata.append('application_form_with_references', data.application_form_with_references);
        finaldata.append('orientation_date', moment(data.orientation_date).format("YYYY-MM-DD"));
        finaldata.append('profeesional_liability', data.profeesional_liability);
        finaldata.append('orientation_foreseen', data.orientation_foreseen);
        finaldata.append('disclosure_send',data.disclosure_send);
        finaldata.append('disclosure_receive',data.disclosure_receive);

        var updatecandidateapi = await common.updatecandidate(finaldata);



        if (updatecandidateapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }


        if (updatecandidateapi?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: updatecandidateapi?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            //navigate('/admin/portal-users',{state:{success:'1'}});
            getcandidatedetails();
            setLoading(false);
        }
        else {

            // alert(UpdatePortalUserapi?.response?.message);




            setLoading(false);

            Store.addNotification({
                title: "Error!",
                message: updatecandidateapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });




        }



    }

    const saveDirectDeposit = async (data) => {


        const DirectDepositfinaldata = new FormData();

        DirectDepositfinaldata.append('candidate_detail_id', candidate_id_main);



        
        DirectDepositfinaldata.append('legal_name', data.legal_name);
        DirectDepositfinaldata.append('date_of_birth', moment(data.date_of_birth).format("YYYY-MM-DD"));
        DirectDepositfinaldata.append('your_address', data.your_address);
        DirectDepositfinaldata.append('your_email_address', data.your_email_address);



        DirectDepositfinaldata.append('tax_id', data.tax_id);
        DirectDepositfinaldata.append('operating_as', data.operating_as);
        DirectDepositfinaldata.append('pay_to_name', data.pay_to_name);
        DirectDepositfinaldata.append('bank_name', data.bank_name);
        DirectDepositfinaldata.append('bank_routing_number', data.bank_routing_number);
        DirectDepositfinaldata.append('bank_account_number', data.new_bank_account_number);
        DirectDepositfinaldata.append('account_type', data.account_type);
        DirectDepositfinaldata.append('accept_terms', data.accept_terms);
        DirectDepositfinaldata.append('electronically_signed', (data.electronically_signed_new==undefined)?'':data.electronically_signed_new);

        



        var addorupdatecandidatedepositapi = await common.addorupdatecandidatedeposit(DirectDepositfinaldata);


        if (addorupdatecandidatedepositapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }




        if (addorupdatecandidatedepositapi?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: addorupdatecandidatedepositapi?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails()
            setDdshow(false);
            setLoading(false);
        }
        else {





            setDdshow(false);
            setLoading(false);

            Store.addNotification({
                title: "Error!",
                message: addorupdatecandidatedepositapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });


        }

    }



    const saveI9 = async (data) => {




        const i9data = new FormData();

        i9data.append('candidate_detail_id', candidate_id_main);
        i9data.append('date_of_birth', moment(data.date_of_birth).format("YYYY-MM-DD"));
        i9data.append('social_security_number', data.social_security_number);
        i9data.append('citizen', data.citizen);
        i9data.append('noncitizen', data.noncitizen);
        i9data.append('lawful_permanent_resident', data.lawful_permanent_resident);
        i9data.append('alien_authorized_work', data.alien_authorized_work);
        i9data.append('uscis_number', data.uscis_number);
        i9data.append('admission_number', data.admission_number);
        i9data.append('passport_number', data.passport_number);
        i9data.append('country_of_issuance', data.country_of_issuance);


        var addorupdatetaxformi9api = await common.addorupdatetaxformi9(i9data);


        if (addorupdatetaxformi9api?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }



        if (addorupdatetaxformi9api?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: addorupdatetaxformi9api?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails()
            setI9show(false);
            setLoading(false);
        }
        else {





            setI9show(false);
            setLoading(false);

            Store.addNotification({
                title: "Error!",
                message: addorupdatetaxformi9api?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });


        }
    }
    // IIC , Psychotherapy Evaluation form
    const saveEvalform = async (data) => {
        const iicEvaldata = new FormData();

        iicEvaldata.append('candidate_detail_id', candidate_id_main);
        iicEvaldata.append('employee_name', data.employee_name);
        iicEvaldata.append('employee_title', data.employee_title);
        iicEvaldata.append('supervisor_name', data.supervisor_name);
        iicEvaldata.append('supervisor_title', data.supervisor_title);
        iicEvaldata.append('eval_period_from',  moment(data.eval_period_from).format("YYYY-MM-DD"));
        iicEvaldata.append('eval_period_to',  moment(data.eval_period_to).format("YYYY-MM-DD"));
        iicEvaldata.append('department', data.department);
        iicEvaldata.append('goal_1', data.goal_1);
        iicEvaldata.append('goal_2', data.goal_2);
        iicEvaldata.append('goal_3', data.goal_3);
        iicEvaldata.append('achievements_1', data.achievements_1);
        iicEvaldata.append('achievements_2', data.achievements_2);
        iicEvaldata.append('achievements_3', data.achievements_3);
        iicEvaldata.append('job_knowledge', data.job_knowledge);
        iicEvaldata.append('job_knowledge_role', data.job_knowledge_role);
        iicEvaldata.append('job_knowledge_comment', data.job_knowledge_comment);
        iicEvaldata.append('work_quality', data.work_quality);
        iicEvaldata.append('work_quality_time', data.work_quality_time);
        iicEvaldata.append('work_quality_notes', data.work_quality_notes);
        iicEvaldata.append('work_quality_plans', data.work_quality_plans);
        iicEvaldata.append('work_quality_design', data.work_quality_design);
        iicEvaldata.append('work_quality_code', data.work_quality_code);
        iicEvaldata.append('work_quality_behavior', data.work_quality_behavior);
        iicEvaldata.append('work_quality_comments', data.work_quality_comments);
        iicEvaldata.append('attendance', data.attendance);
        iicEvaldata.append('attendance_session', data.attendance_session);
        iicEvaldata.append('attendance_comments', data.attendance_comments);
        iicEvaldata.append('initiative', data.initiative);
        iicEvaldata.append('initiative_quality', data.initiative_quality);
        iicEvaldata.append('initiative_comments', data.initiative_comments);
        iicEvaldata.append('communication_skill', data.communication_skill);
        iicEvaldata.append('communication_client', data.communication_client);
        iicEvaldata.append('communication_role', data.communication_role);
        iicEvaldata.append('communication_concern', data.communication_concern);
        iicEvaldata.append('communication_feedback', data.communication_feedback);
        iicEvaldata.append('communication_comments', data.communication_comments);
        iicEvaldata.append('dependability', data.dependability);
        iicEvaldata.append('dependability_job', data.dependability_job);
        iicEvaldata.append('dependability_comments', data.dependability_comments);
        iicEvaldata.append('rating', data.rating);
        iicEvaldata.append('rating_comments', data.rating_comments);
        iicEvaldata.append('strength_develop_1', data.strength_develop_1);
        iicEvaldata.append('strength_develop_2', data.strength_develop_2);
        iicEvaldata.append('strength_develop_3', data.strength_develop_3);
        iicEvaldata.append('career_develop_1', data.career_develop_1);
        iicEvaldata.append('career_develop_2', data.career_develop_2);
        iicEvaldata.append('career_develop_3', data.career_develop_3);
        iicEvaldata.append('professional_develop_1', data.professional_develop_1);
        iicEvaldata.append('professional_develop_2', data.professional_develop_2);
        iicEvaldata.append('professional_develop_3', data.professional_develop_3);
        iicEvaldata.append('goal_develop_1', data.goal_develop_1);
        iicEvaldata.append('goal_develop_2', data.goal_develop_2);
        iicEvaldata.append('goal_develop_3', data.goal_develop_3);
        iicEvaldata.append('employee_sign', data.employee_sign[0]);
        iicEvaldata.append('employee_sign_name', data.employee_sign_name);
        iicEvaldata.append('employee_date',  moment(data.employee_date).format("YYYY-MM-DD"));
        iicEvaldata.append('supervisor_sign', data.supervisor_sign[0]);
        iicEvaldata.append('supervisor_sign_name', data.supervisor_sign_name);
        iicEvaldata.append('supervisor_date',  moment(data.supervisor_date).format("YYYY-MM-DD"));

        var addorupdateiicEvalformapi = await common.addorupdateiicEvalform(iicEvaldata);

        console.log(" data.job_knowledge_comment ",  data.job_knowledge_comment)

        if (addorupdateiicEvalformapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }



        if (addorupdateiicEvalformapi?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: addorupdateiicEvalformapi?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails()
            setIicshow(false);
            setLoading(false);
        }
        else {





            setIicshow(false);
            setLoading(false);

            Store.addNotification({
                title: "Error!",
                message: addorupdateiicEvalformapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });


        }
    }




    const saveW4 = async (data) => {




        const w4data = new FormData();

        w4data.append('candidate_detail_id', candidate_id_main);
        w4data.append('social_security_number', data.social_security_number);
        w4data.append('single_or_married', data.single_or_married);
        w4data.append('qualifying_children', data.qualifying_children);
        w4data.append('other_dependents', data.other_dependents);
        w4data.append('total_amount', data.total_amount);
        w4data.append('other_income', data.other_income);
        w4data.append('deductions', data.deductions);
        w4data.append('extra_withholding', data.extra_withholding);
        w4data.append('first_date_employment', moment(data.first_date_employment).format("YYYY-MM-DD"));

        w4data.append('two_jobs', data.two_jobs);
        w4data.append('three_jobs', data.three_jobs);
        w4data.append('two_a', data.two_a);
        w4data.append('two_b', data.two_b);
        w4data.append('two_c', data.two_c);
        w4data.append('number_pay_periods', data.number_pay_periods);
        w4data.append('annual_amount', data.annual_amount);
        w4data.append('deduction_1', data.deduction_1);
        w4data.append('deduction_2', data.deduction_2);
        w4data.append('deduction_3', data.deduction_3);
        w4data.append('deduction_4', data.deduction_4);
        w4data.append('deduction_5', data.deduction_5);




        var addorupdatetaxformw4api = await common.addorupdatetaxformw4(w4data);


        if (addorupdatetaxformw4api?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }



        if (addorupdatetaxformw4api?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: addorupdatetaxformw4api?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails()
            setW4show(false);
            setLoading(false);
        }
        else {





            setW4show(false);
            setLoading(false);

            Store.addNotification({
                title: "Error!",
                message: addorupdatetaxformw4api?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });


        }
    }




    const saveNJ = async (data) => {




        const njdata = new FormData();

        njdata.append('candidate_detail_id', candidate_id_main);
        njdata.append('ss', data.ss);
        njdata.append('filing_status', data.filing_status);
        njdata.append('chart_from_instruction', data.chart_from_instruction);
        njdata.append('total_number_allowances', data.total_number_allowances);
        njdata.append('additional_amount', data.additional_amount);
        njdata.append('claim_exemption', data.claim_exemption);
        njdata.append('under_penalties_perjury', data.under_penalties_perjury);

        var addorupdatetaxformnjapi = await common.addorupdatetaxformnj(njdata);


        if (addorupdatetaxformnjapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }


        if (addorupdatetaxformnjapi?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: addorupdatetaxformnjapi?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails()
            setNjshow(false);
            setLoading(false);
        }
        else {





            setNjshow(false);
            setLoading(false);

            Store.addNotification({
                title: "Error!",
                message: addorupdatetaxformnjapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });


        }
    }

    const saveW9Form = async (data) => {



       const W9FormData = new FormData();
        console.log(data);
        W9FormData.append('candidate_detail_id', candidate_id_main);
        W9FormData.append('date', moment(data.date).format("YYYY-MM-DD"));
        W9FormData.append('electronically_signed', (data.electronically_signed_new==undefined)?'':data.electronically_signed_new);
        W9FormData.append('name', data.name);
        W9FormData.append('business_name', data.business_name);
        W9FormData.append('federal_tax_classification', data.federal_tax_classification);
        W9FormData.append('limited_liablity_company', data.limited_liablity_company);
        W9FormData.append('extempt_payee_code', data.extempt_payee_code);
        W9FormData.append('exemption_fatcha_report_code', data.exemption_fatcha_report_code);
        W9FormData.append('address_one', data.address_one);
        W9FormData.append('city', data.city);
        W9FormData.append('state', data.state);
        W9FormData.append('zipcode', data.zipcode);
        W9FormData.append('first_name', data.first_name);
        W9FormData.append('last_name', data.last_name);
        W9FormData.append('requester_address', data.requester_address);
        W9FormData.append('requester_city', data.requester_city);
        W9FormData.append('requester_state', data.requester_state);
        W9FormData.append('requester_zipcode', data.requester_zipcode);
        W9FormData.append('account_number', data.account_number);
        W9FormData.append('social_security_number', data.social_security_number);
        W9FormData.append('employer_identification_number', data.employer_identification_number);
        var addorupdatetaxformw9api = await common.addorupdatetaxformW9(W9FormData);


        if (addorupdatetaxformw9api?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }


        if (addorupdatetaxformw9api?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: addorupdatetaxformw9api?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails()
            setNjshow(false);
            setLoading(false);
        }
        else {





            setNjshow(false);
            setLoading(false);

            Store.addNotification({
                title: "Error!",
                message: addorupdatetaxformw9api?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });


        }
    }
    const saveGenderInequity = async (data) => {

        // console.log(data);
        // return false;
        const GenderInequityData = new FormData();
         console.log(data);
         GenderInequityData.append('candidate_detail_id', candidate_id_main);
         GenderInequityData.append('date', moment(data.date).format("YYYY-MM-DD"));
         GenderInequityData.append('name_sign', (data.name_sign==undefined)?'':data.name_sign);
         GenderInequityData.append('name_print', data.name_print);
         var addorupdatetaxformw9api = await common.addorupdatetaxformGenderInequity(GenderInequityData);
console.log("addorupdatetaxformw9api ", addorupdatetaxformw9api) 
         if (addorupdatetaxformw9api?.data?.status == 400) {
             navigate('/user/logout');
             return;
         }
 
 
         if (addorupdatetaxformw9api?.data?.status == 202) {
             Store.addNotification({
                 title: "Success!",
                 message: addorupdatetaxformw9api?.data?.message,
                 type: "success",
                 container: "top-right",
                 animationIn: ["animate__animated", "animate__fadeIn"],
                 animationOut: ["animate__animated", "animate__fadeOut"],
                 dismiss: {
                     duration: 3000,
                     onScreen: true
                 }
             });
             getcandidatedetails()
             setGenderInequityshow(false);
             setLoading(false);
         }
         else {
 
 
 
 
 
            setGenderInequityshow(false);
             setLoading(false);
 
             Store.addNotification({
                 title: "Error!",
                 message: addorupdatetaxformw9api?.data?.message,
                 type: "danger",
                 container: "top-right",
                 animationIn: ["animate__animated", "animate__fadeIn"],
                 animationOut: ["animate__animated", "animate__fadeOut"],
                 dismiss: {
                     duration: 3000,
                     onScreen: true
                 }
             });
 
 
         }
     }


    const savescoreCard = async (data) => {


        const ssdata = new FormData();
        ssdata.append('candidate_detail_id', candidate_id_main);
        ssdata.append('score_card_id', data.id || 0);
        ssdata.append('comment', data.comment);
        ssdata.append('rate', data.rate);

        var addorupdatescorecardapi = await common.addorupdatescorecard(ssdata);


        if (addorupdatescorecardapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }


        if (addorupdatescorecardapi?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: addorupdatescorecardapi?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails()
            setScoreCardshow(false);
            setLoading(false);
        }
        else {
            setScoreCardshow(false);
            setLoading(false);
            Store.addNotification({
                title: "Error!",
                message: addorupdatescorecardapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });


        }
    }

    const saveadditionalDocuments = async (data) => {
        setLoading(true); 
        console.log(data.id);
        const ssdata = new FormData();
        ssdata.append('candidate_detail_id', candidate_id_main);
        ssdata.append('score_card_id', data.id || 0);
        ssdata.append('additonal_document', data.additonal_document[0]);
        ssdata.append('title', data.title);
        console.log(ssdata);
        var addorupdatescorecardapi = await common.addorupdateadditionalDoc(ssdata);


        if (addorupdatescorecardapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }


        if (addorupdatescorecardapi?.data?.status == 202) {
            setLoading(false); 
            Store.addNotification({
                title: "Success!",
                message: addorupdatescorecardapi?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            getcandidatedetails()
            setAdditionalDocumentsshow(false);
            setLoading(false);
        }
        else {
            setLoading(false); 
            setAdditionalDocumentsshow(false);
            setLoading(false);
            Store.addNotification({
                title: "Error!",
                message: addorupdatescorecardapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }
    }




    const editscorecardpopup = async (score_card_id) => {
        var editscorecardapi = await common.editscorecard(score_card_id);
        if (editscorecardapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }
        editscorecardapi.data.data.rate = editscorecardapi?.data?.data?.rate.toString()
        reset6(editscorecardapi.data.data);
        setScoreCardshow(true);
    }

    const editaddtionalDocPopup = async (score_card_id) => {
        var editadditionalapi = await common.editadditonalDoc(score_card_id);
        if (editadditionalapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }
        reset9(editadditionalapi.data.data);
        setAdditionalDocumentsshow(true);
    }




    const editcandidateDeposit = () => {
        ddhandleShow(true);



        if (apiData?.candidateDeposit[0]?.bank_routing_number) {
            apiData.candidateDeposit[0].confirm_bank_routing_number = apiData?.candidateDeposit[0]?.bank_routing_number;
        }
        if (apiData?.candidateDeposit[0]?.bank_account_number) {
            apiData.candidateDeposit[0].confirm_bank_account_number = apiData?.candidateDeposit[0]?.bank_account_number;
        }



        reset2(apiData?.candidateDeposit?.[0])


    }
    const offset = currentPage * perPage;
    const offset2 = currentPage2 * perPage2;


    const getcandidatedetails = async (istrue = 0) => {
        // alert("kannannvdns")
        try{
            var editapplication = await common.editapplication(candidate_id_main, istrue);
        }catch (error) {
            if (error.response?.status === 429) {
              console.warn('Too many requests. Retrying...');
              // Retry after a delay
              setTimeout(async () => await common.editapplication(candidate_id_main, istrue), 2000); // Retry after 2 seconds
          } else {
            return error;
          }      
          }
      

        console.log("kannan.m", editapplication)

        if (editapplication?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }
        
console.log("kannanvaledit",editapplication?.data?.data?.candidateJerseyAbuse?.[0])
        setchildformDataval(editapplication?.data?.data?.candidateFormAbuse?.[0])
        setformDataval(editapplication?.data?.data?.candidateJerseyAbuse?.[0])
        //console.log(editapplication.data.data);
        // handleChangePosition2(editapplication.data.data.position_id[0]);
        handleChangePosition2(editapplication?.data?.data?.empPositionOpt[0]['id']);
        var contract_email_content  = editapplication?.data?.data?.contract_email_content;
        console.log("rajaval",contract_email_content)
        // contract_email_content = contract_email_content.replaceAll('{date}', "");
        // contract_email_content = contract_email_content.replaceAll('{singature}', "");
        // contract_email_content = contract_email_content.replaceAll('{singature}', "");
        // contract_email_content = contract_email_content.replaceAll('{Pick a Date}', "");
        if (editapplication && editapplication.data && editapplication.data.data) 
        editapplication.data.data.contract_email_content = contract_email_content;
        setPostionData(editapplication?.data?.data?.org_position_id)
        if (editapplication?.data?.data?.password && editapplication?.data?.data?.password != "") {
            editapplication.data.data.password = "";
        }
        if(editapplication?.data?.data?.highest_level_education_other == "undefined"){
            editapplication.data.data.highest_level_education_other = "";
        }


        if (editapplication?.data?.data?.date_available && editapplication.data.data.date_available != "" && editapplication.data.data.date_available != "0000-00-00") {
            editapplication.data.data.date_available = moment(editapplication.data.data.date_available).toDate();


        }
        else {
            if (editapplication && editapplication.data && editapplication.data.data) 
            editapplication.data.data.date_available = "";
        }

        if (editapplication?.data?.data?.final_paycheck && editapplication.data.data.final_paycheck != "" && editapplication.data.data.final_paycheck != "0000-00-00") {
            editapplication.data.data.final_paycheck = moment(editapplication.data.data.final_paycheck).toDate();
        }else {
            if (editapplication && editapplication.data && editapplication.data.data) 
            editapplication.data.data.final_paycheck = "";
        }
        


        if (editapplication?.data?.data?.certification_expiration && editapplication.data.data.certification_expiration != "" && editapplication.data.data.certification_expiration != "0000-00-00") {
            editapplication.data.data.certification_expiration = moment(editapplication.data.data.certification_expiration).toDate();


        }
        else {
            if (editapplication && editapplication.data && editapplication.data.data) 
            editapplication.data.data.certification_expiration = "";
        }


        if (editapplication?.data?.data?.expiration_date && editapplication.data.data.expiration_date != "" && editapplication.data.data.expiration_date != "0000-00-00") {
            editapplication.data.data.expiration_date = moment(editapplication.data.data.expiration_date).toDate();


        }
        else {
            if (editapplication && editapplication.data && editapplication.data.data) 
            editapplication.data.data.expiration_date = "";
        }
        


        if (editapplication?.data?.data?.date && editapplication?.data?.data?.date != "") {
            // editapplication.data.data.newdate=  moment(editapplication.data.data.date).toDate();

            editapplication.data.data.newdate = moment(editapplication.data.data.date).format("MM/DD/YYYY");


        }

        if (editapplication?.data?.data?.orientation_date && editapplication.data.data.orientation_date != "" && editapplication.data.data.orientation_date != "0000-00-00") {
            editapplication.data.data.orientation_date = moment(editapplication.data.data.orientation_date).toDate();


        }
        else {
            if (editapplication && editapplication.data && editapplication.data.data) 
            editapplication.data.data.orientation_date = "";
        }





        setTimeout(() => {

             console.log("kannan",editapplication)
            // editapplication.data.data.offer_email_content = "";
            setApiData(editapplication.data.data);
            console.log("apidata ", apiData)
            reset(editapplication.data.data);

            const newPerPage = 10; // Calculate the number of rows per page based on your logic
            let newPageCount = 0;
            if (apiData && apiData.emailLog) {
                newPageCount = Math.ceil(apiData?.emailLog.length / newPerPage);
              }
            
              
            // Set the new pagination variables
            setPerPage(newPerPage);
            setPageCount(newPageCount);
      
            // Reset to the first page when data changes
            setCurrentPage(0);


            const newPerPage2 = 10; // Calculate the number of rows per page based on your logic
            let newPageCount2 = 0;
            if (apiData && apiData.contractDetailsReceived) {
                newPageCount2 = Math.ceil(apiData?.contractDetailsReceived.length / newPerPage2);
            }
            // setemailTypeId(apiData.offer_email_type);
      
            // Set the new pagination variables
            setPerPage2(newPerPage2);
            setPageCount2(newPageCount2);
      
            // Reset to the first page when data changes
            setCurrentPage(0);

            

            if (editapplication?.data?.data?.employment_type_id && editapplication?.data?.data?.position_id) {
                var settingfilter = {
                    employment_type_id: editapplication?.data?.data?.employment_type_id,
                    position_id: editapplication?.data?.data?.org_position_id.toString(),

                }
                getfieldsettingslist(settingfilter);

            }






            var fields = {
                name: '',
                email: '',
                phone: '',
                relationship: ''
            };
            var arr = [];
            if (editapplication?.data?.data?.professional_references) {

                editapplication.data.data.professional_references.map((data, index) => {
                    // alert("Hello");
                    if (data.name != "") {
                        arr.push({
                            name: data.name,
                            email: data.email,
                            phone: data.phone,
                            relationship: data.relationship
                        })
                    }


                });

                // console.log(arr);
                setInputFields(arr);
            }







        }, 500);

    }

    const getfieldsettingslist = async (data) => {


        var getfieldsettingsapi = await common.getfieldsettings(data);


         console.log("kanann",getfieldsettingsapi?.data);

        setFieldSettings(getfieldsettingsapi?.data?.data)
    }
useEffect(() =>{
     if (childupdateDataval==2 ) {
        getcandidatedetails();
        setchildupdateDataval(1)
    }
    // getcandidatedetails()
},[childupdateDataval])


useEffect(() =>{
    if (childjercyupdateDataval==2 ) {
        getcandidatedetails();
        setchildjercyupdateDataval(1)
    }
    // getcandidatedetails()
},[childjercyupdateDataval])
    useEffect(() => {


        console.log("deposit sign ", depositsigRef);
        clearInterval(Config.intervalId);

        (async () => {

            // console.log('dddd',ddshow);

            if (user_data == undefined || user_data == null) {
                navigate('/user/login');
                return;
            }


            if (user_data_json.role_id == 4 && params.id) {
                navigate('/user/login');
                return;
            }
// console.log("kandipa",user_data_json)
            setAuthCheck(user_data_json);


            //alert('aaaa');
            var dropdownvalue = await common.getdropdown(null);

            setDynamicField(dropdownvalue.data.data);

            setPositionId(dropdownvalue.data.data.position_field.map(item => item.position_id))
            getcandidatedetails();


            // Config.intervalId = setInterval(() => {
                // getcandidatedetails();
                // Fetch data every 2 minutes
            // }, Config.auto_refresh_time);




            // console.log(editapplication);


        })();

    }, [reset]);

    return (
        
        <div className="adminview wrapper">
                  <style>
        {`
          /* Add your CSS styles here */
          .pagination li {
            display: none;
          }
          
          .pagination .break-me {
            display: inline;
          }
            .pagination-container {
            display: flex;
            justify-content: flex-end;
            }

            .pagination {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            }
            .pagination li.previous{
                display:none;
            }
            .pagination li.next{
                display:none;
            }

            .pagination li {
            margin: 0 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            }

            .pagination li.active {
            background-color: #01bd9c;
            color: #fff;
            }

            .pagination li a {
            text-decoration: none;
            color: #01bd9c;
            padding: 5px 10px;
            border: 1px solid #01bd9c;
            border-radius: 5px;
            }

            .pagination li a:hover {
            background-color: #01bd9c;
            color: #fff;
            }

        `}
      </style>
            {authCheck?.role_id == 4 && <AdminHeader></AdminHeader>}
            {authCheck?.role_id == 4 && <AdminSidebar></AdminSidebar>}




            <div className={`content-wrapper content-applicant px-3 pt-1 pb-0  ${classNameDependsOnCondtion}`}>




                <div className="content-header">
                    <div className="row align-items-center">
                        <div className="col-sm-6 pl-0">
                            <h1 className="m-0">
                                <div className="candidate-color-bar-container"><div person="candidate" className="ng-isolate-scope md avatar color4 good" initial={apiData?.first_name ? Array.from(apiData?.first_name)[0] : ''}></div></div>

                                {apiData?.first_name} {apiData?.last_name}
                            </h1>
                        </div>
                        <div className="col-sm-6 pr-0">
                            <div className="float-right">

                                <span className="badge bg-primary edit-app-status">{apiData.application_status_name}</span>


                                {/* <button type="submit" className="btn btn-default">
                                  <i className="far fa-save"></i> Change Stage
  </button> */}
                                {(authCheck?.role_id == 1 || authCheck?.role_id == 2 || authCheck?.role_id == 4) && <button type="submit" className="btn btn-primary ml-3" form='edit_candidate'>
                                    <i className="far fa-save"></i> Save
                                </button>}


                                {(authCheck?.role_id != 4) && <button onClick={() => navigate(-1)} className="btn btn-link btn-cancel-link" ><i className="fas fa-remove"></i></button>}


                            </div>
                        </div>
                    </div>
                </div>
                <form key={1} onSubmit={handleSubmit(onSubmit, onErrors)} id="edit_candidate">
                    <div className="form-horizontal">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="row">

                                 <div className="col-md-12">
                                 {(authCheck?.role_id != 3) &&  <div className="nav nav-tabs" id="vert-tabs-tab" role="tablist" aria-orientation="vertical">
                                            <a className="nav-link active" id="vert-tabs-position-tab" data-toggle="pill" href="#vert-tabs-position" role="tab" aria-controls="vert-tabs-position" aria-selected="false">Position</a>
                                            <a className="nav-link" id="vert-tabs-education-tab" data-toggle="pill" href="#vert-tabs-education" role="tab" aria-controls="vert-tabs-education" aria-selected="false">Education and Training</a>
                                            { (authCheck?.role_id != 4) && <a className="nav-link" id="vert-tabs-documents-tab" data-toggle="pill" href="#vert-tabs-documents" role="tab" aria-controls="vert-tabs-documents" aria-selected="false">Documents</a>}
                                            <a className="nav-link" id="vert-tabs-background-tab" data-toggle="pill" href="#vert-tabs-background" role="tab" aria-controls="vert-tabs-background" aria-selected="false">Background Check</a>
                                            <a className="nav-link" id="vert-tabs-references-tab" data-toggle="pill" href="#vert-tabs-references" role="tab" aria-controls="vert-tabs-references" aria-selected="false">References</a>

                                            <a className="nav-link" id="vert-tabs-agreement-tab" data-toggle="pill" href="#vert-tabs-agreement" role="tab" aria-controls="vert-tabs-agreement" aria-selected="false">Certification Agreement</a>
                                            {(authCheck?.role_id != 4) && <a className="nav-link" id="vert-tabs-checklist-tab" data-toggle="pill" href="#vert-tabs-checklist" role="tab" aria-controls="vert-tabs-checklist" aria-selected="false">Checklist</a>}

                                            
                                            { (authCheck?.role_id != 4) && <a className="nav-link" id="vert-tabs-tax-forms-tab" data-toggle="pill" href="#vert-tabs-tax-forms" role="tab" aria-controls="vert-tabs-tax-forms" aria-selected="false">Tax Forms</a>}
                                            {(apiData.employment_type_id ==2 && authCheck?.role_id != 4) && <a className="nav-link" id="vert-tabs-deposit-tab" data-toggle="pill" href="#vert-tabs-deposit" role="tab" aria-controls="vert-tabs-deposit" aria-selected="false">Direct Deposit Enrollment</a>}
                                            {(authCheck?.role_id == 1 || authCheck?.role_id == 2 ) && <a className="nav-link" id="vert-tabs-scorecard-tab" data-toggle="pill" href="#vert-tabs-scorecard" role="tab" aria-controls="vert-tabs-scorecard" aria-selected="false">Scorecard</a>}
                                            {((authCheck?.role_id == 1 || authCheck?.role_id == 2) ) && <a className="nav-link" id="vert-tabs-email-tab" data-toggle="pill" href="#vert-tabs-email" role="tab" aria-controls="vert-tabs-email" aria-selected="false">Emails</a>}
                                            {((authCheck?.role_id == 1 || authCheck?.role_id == 2)) && <a className="nav-link" id="vert-tabs-contract-tab" data-toggle="pill" href="#vert-tabs-contract" role="tab" aria-controls="vert-tabs-contract" aria-selected="false">Contract</a>}
                                            <a className="nav-link" id="vert-tabs--additional-documents-tab" data-toggle="pill" href="#vert-tabs-additional-documents" role="tab" aria-controls="vert-tabs-additional-documents" aria-selected="false">Additional Documents</a>
                                            
                                            {(authCheck?.role_id != 4) && (Array.isArray(postionData)) && (postionData.includes("3") || postionData.includes("5")) && <a className="nav-link" id="vert-tabs-perf-tab" data-toggle="pill" href="#vert-tabs-perf" role="tab" aria-controls="vert-tabs-perf" aria-selected="false">Performance Evaluation</a>}

                                        </div>}

                                        {(authCheck?.role_id == 3) &&<div className="nav nav-tabs" id="vert-tabs-tab" role="tablist" aria-orientation="vertical">
                                        <a className="nav-link active" id="vert-tabs-agreement-tab" data-toggle="pill" href="#vert-tabs-agreement" role="tab" aria-controls="vert-tabs-agreement" aria-selected="false">Resume</a>
                                        </div>}

                                    </div>


                                    <div className="col-md-12">

                                    {(authCheck?.role_id != 3) &&     <div className="tab-content" id="vert-tabs-tabContent">


                                            <div className="tab-pane fade show active" id="vert-tabs-position" role="tabpanel" aria-labelledby="vert-tabs-position-tab">
                                                <div className="card card-secondary card-outline" data-card-name="position" id="position">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-object-group"></i> Position</div>
                                                    </div>
                                                    <div className="card-body">


                                                    {(authCheck?.role_id==1 || authCheck?.role_id==2) && <div className="form-group row">

                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Employment Type<span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="raw">
                                                                    {dynamicField.employement_type_field && dynamicField.employement_type_field.map((et, key) => {
                                                                        return <div className="form-check-inline" key={key}>
                                                                            <input type="radio" name="employment_type" className="form-check-input" value={et.id}    {...register('employment_type_id', { required: "Please select employment type." })} />
                                                                            <label className="form-check-label">{et.name}</label>
                                                                        </div>;
                                                                    })}
                                                                </div>
                                                            </div>
                                                            {errors.employment_type_id?.message && (
                                                                <p className='text-danger'>{errors.employment_type_id.message}</p>
                                                            )}
                                                        </div>
                                                    }
                                                        <div className="form-group row">
                                                        <div className="col-md-3">
                                                            <div className="label-wrapper">
                                                                <label className="col-form-label p-0">Position or Type of Employment Desired <br />(Check ALL that apply) <span className="required">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="">
                                                            {authCheck?.role_id != 4
                                                                ? (dynamicField.position_field || []).map((po, key) => (
                                                                    <div className="form-check" key={key}>
                                                                        <input
                                                                        type="checkbox"
                                                                        name="positionType"
                                                                        className="form-check-input"
                                                                        value={po.id}
                                                                        {...register('position_id', { required: "Please select position or type of employment." })}
                                                                        />
                                                                        <label className="form-check-label">
                                                                        {po.name} - {po.area_name}
                                                                        </label>
                                                                    </div>
                                                                    ))
                                                                : (dynamicField.position_field || []).map((po, key) => {
                                                                    const selectedPositions = (apiData?.position_id || []).map((position) => parseInt(position, 10));
                                                                    const isSelected = selectedPositions.includes(po.id);

                                                                    return isSelected ? (
                                                                        <div className="form-check" key={key}>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="positionType"
                                                                            className="form-check-input"
                                                                            value={po.id}
                                                                            checked={isSelected}
                                                                            readOnly
                                                                            {...register('position_id', { required: "Please select position or type of employment." })}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            {po.name} - {po.area_name}
                                                                        </label>
                                                                        </div>
                                                                    ) : null;
                                                                    })
                                                                }

                                                                {errors.position_id?.message && (
                                                                    <p className='text-danger'>{errors.position_id.message}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>







                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Interested In <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="raw">
                                                                    {dynamicField.interested_in_field && dynamicField.interested_in_field.map((ii, key) => {
                                                                        return <div className="form-check" key={key}>
                                                                            <input type="checkbox" name="desired_type" className="form-check-input" value={ii.id} {...register('desired_type', { required: "Please select interested in." })} />
                                                                            <label className="form-check-label">{ii.name}</label>
                                                                        </div>;

                                                                    })}

                                                                    {errors.desired_type?.message && (
                                                                        <p className='text-danger'>{errors.desired_type.message}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Preferred Areas <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="raw">
                                                                    {dynamicField.preferred_area_field && dynamicField.preferred_area_field.map((pa, key) => {
                                                                        return <div className="form-check" key={key}>
                                                                            <input type="checkbox" name="area_type" className="form-check-input" value={pa.id} {...register('area_type', { required: "Please select preferred area." })} />
                                                                            <label className="form-check-label">{pa.name}</label>
                                                                        </div>;

                                                                    })}

                                                                    {errors.area_type?.message && (
                                                                        <p className='text-danger'>{errors.area_type.message}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Are you able to perform the essential functions of the job you are applying for, with or without reasonable accommodation? <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="raw">
                                                                    <div className="form-check-inline">
                                                                        <input type="radio" className="form-check-input" name="perform_the_essential_functions" id="accommodation_Yes" value={1} {...register('perform_the_essential_functions', { required: "Please select perform the essential functions of the job." })} />
                                                                        <label className="form-check-label" >Yes</label>
                                                                    </div>
                                                                    <div className="form-check-inline">
                                                                        <input type="radio" className="form-check-input" name="perform_the_essential_functions" id="accommodation_No" value={0} {...register('perform_the_essential_functions', { required: "Please select perform the essential functions of the job." })} />
                                                                        <label className="form-check-label">No</label>
                                                                    </div>

                                                                    {errors.perform_the_essential_functions?.message && (
                                                                        <p className='text-danger'>{errors.perform_the_essential_functions.message}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label">Date Available <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">


                                                                <Controller
                                                                    control={control}
                                                                    name='date_available'
                                                                    rules={{ required: "Please select available date" }}
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            className="form-control text-box single-line datetimepicker-input"
                                                                            placeholderText='Select date'
                                                                            onChange={(date) => field.onChange(date)}
                                                                            selected={field.value}
                                                                            minDate={new Date()}
                                                                            dateFormat="MM/dd/yyyy"

                                                                        />


                                                                    )}
                                                                />
                                                                {errors.date_available?.message && (
                                                                    <p className='text-danger'>{errors.date_available.message}</p>
                                                                )}


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="vert-tabs-education" role="tabpanel" aria-labelledby="vert-tabs-education-tab">
                                                <div className="card card-secondary card-outline" data-card-name="education_and_training" id="education_and_training">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-random"></i> Education and Training</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0"> What is your highest level of education completed <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="raw">
                                                                    {dynamicField.highest_level_education_field && dynamicField.highest_level_education_field.map((hle, key) => {

                                                                        return <div className="form-check" key={key}>
                                                                            <input type="radio" className="form-check-input" name="highest_level_education" id={'education_high_school_' + hle.id} value={hle.id} {...register('highest_level_education', { required: "Please select education." })} />
                                                                            <label className="form-check-label" >{hle.name}</label>
                                                                        </div>;
                                                                    })}

                                                                </div>
                                                            </div>






                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0"> Other <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="raw">
                                                                    <input className="form-control text-box single-line" type="text"   {...register('highest_level_education_other', {
                                                                        validate: {
                                                                            required: value => {
                                                                                if (!value && getValues('highest_level_education') === '7') return 'Please enter other education.';
                                                                                return true;
                                                                            },
                                                                        }
                                                                    })} />

                                                                    {errors.highest_level_education_other?.message && (
                                                                        <p className='text-danger'>{errors.highest_level_education_other.message}</p>
                                                                    )}

                                                                </div>
                                                            </div>






                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="vert-tabs-documents" role="tabpanel" aria-labelledby="vert-tabs-documents-tab">
                                                <div className="card card-secondary card-outline" data-card-name="documents" id="documents">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-paperclip"></i> Documents</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Upload Highest Diploma Completed  </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input className="form-control text-box single-line" type="file" accept='.pdf,.doc,.docx'  {...register('diploma_new',
                                                                    {
                                                                        validate: {
                                                                            fileupload: (files) => {
                                                                                if (files.length != 0) {
                                                                                    if (files[0]?.size > 10000000) {
                                                                                        return "Allow only max 10MB.";
                                                                                    }
                                                                                    else if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0]?.type)) {
                                                                                        return "Allow only pdf, doc, docx format.";
                                                                                    }
                                                                                }

                                                                            }
                                                                        }
                                                                    })} />

                                                                {errors.diploma_new?.message && (
                                                                    <p className='text-danger'>{errors.diploma_new.message}</p>
                                                                )}
                                                                {(authCheck?.role_id == 1) &&
                                                                    <div className="mt-2" style={{ clear: "both" }}>
                                                                        <div className="float-left my-1">
                                                                        <label>
                                                                            <input
                                                                            type="checkbox"
                                                                            name="diploma_new_checkbox"
                                                                            value={1}
                                                                            style={{  'margin-right': '3px' }}
                                                                            {...register("diploma_new_checkbox")}
                                                                            onChange={(e) => {
                                                                                // Handle checkbox changes here if needed
                                                                            }}
                                                                            /> Make Required
                                                                        </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="mt-2" style={{ clear: 'both' }}>

                                                                    {apiData?.diploma != "" &&
                                                                        <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(apiData.diploma)} >
                                                                            <i className="fas fa-download"></i>Download file</a>
                                                                        </div>
                                                                    }
                                                                    {/*<div className="float-left my-1">
                                                      <span className="btn btn-default ml-2"><i className="far fa-trash-alt"></i>Remove file</span>
                                                  </div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Copy of Certification BCBA & RBT only </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input className="form-control text-box single-line" type="file" accept='.pdf,.doc,.docx'   {...register('certification_bcba_rbt_new',
                                                                    {
                                                                        validate: {
                                                                           
                                                                            fileupload: (files) => {
                                                                                console.log(files.length,"pdffilelength")
                                                                                console.log(files[0]?.type,"pdffiletype")
                                                                                if (files.length != 0) {
                                                                                    if (files[0]?.size > 10000000) {
                                                                                        return "Allow only max 10MB.";
                                                                                    }
                                                                                    else if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0]?.type)) {
                                                                                        return "Allow only pdf, doc, docx format.";
                                                                                    }
                                                                                }

                                                                            }
                                                                        }
                                                                    })} />
                                                                {errors.certification_bcba_rbt_new?.message && (
                                                                    <p className='text-danger'>{errors.certification_bcba_rbt_new.message}</p>
                                                                )}
                                                                {(authCheck?.role_id == 1) &&
                                                                    <div className="mt-2" style={{ clear: "both" }}>
                                                                        <div className="float-left my-1">
                                                                        <label>
                                                                            <input
                                                                            type="checkbox"
                                                                            name="certification_bcba_rbt_new_checkbox"
                                                                            value={1}
                                                                            style={{  'margin-right': '3px' }}
                                                                            {...register("certification_bcba_rbt_new_checkbox")}
                                                                            onChange={(e) => {
                                                                                // Handle checkbox changes here if needed
                                                                            }}
                                                                            /> Make Required
                                                                        </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="mt-2" style={{ clear: 'both' }}>
                                                                    {apiData?.certification_bcba_rbt != "" &&
                                                                        <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(apiData.certification_bcba_rbt)} >
                                                                            <i className="fas fa-download"></i>Download file</a>
                                                                        </div>
                                                                    }
                                                                    {/*<div className="float-left my-1">
                                                      <span className="btn btn-default ml-2"><i className="far fa-trash-alt"></i>Remove file</span>
                                                  </div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Copy of Professional Liability Insurance  </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input className="form-control text-box single-line" type="file" accept='.pdf,.doc,.docx'   {...register('professional_liability_insurance_new', {
                                                                    validate: {
                                                                        fileupload: (files) => {
                                                                            if (files.length != 0) {
                                                                                if (files[0]?.size > 10000000) {
                                                                                    return "Allow only max 10MB.";
                                                                                }
                                                                                else if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0]?.type)) {
                                                                                    return "Allow only pdf, doc, docx format.";
                                                                                }
                                                                            }

                                                                        }
                                                                    }
                                                                })} />
                                                                {errors.professional_liability_insurance_new?.message && (
                                                                    <p className='text-danger'>{errors.professional_liability_insurance_new.message}</p>
                                                                )}
                                                                {(authCheck?.role_id == 1) &&
                                                                    <div className="mt-2" style={{ clear: "both" }}>
                                                                        <div className="float-left my-1">
                                                                        <label>
                                                                            <input
                                                                            type="checkbox"
                                                                            name="professional_liability_insurance_new_checkbox"
                                                                            {...register("professional_liability_insurance_new_checkbox")}
                                                                            value={1}
                                                                            style={{  'margin-right': '3px' }}
                                                                            onChange={(e) => {
                                                                                // Handle checkbox changes here if needed
                                                                            }}
                                                                            /> Make Required
                                                                        </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="mt-2" style={{ clear: 'both' }}>
                                                                    {apiData?.professional_liability_insurance != "" &&
                                                                        <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(apiData.professional_liability_insurance)} >
                                                                            <i className="fas fa-download"></i>Download file</a>
                                                                        </div>
                                                                    }
                                                                    {/*<div className="float-left my-1">
                                                      <span className="btn btn-default ml-2"><i className="far fa-trash-alt"></i>Remove file</span>
                                                  </div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Driver's License  </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input className="form-control text-box single-line" type="file" accept='.pdf,.doc,.docx' {...register('drivers_license_new', {
                                                                    validate: {
                                                                        fileupload: (files) => {
                                                                            if (files.length != 0) {
                                                                                if (files[0]?.size > 10000000) {
                                                                                    return "Allow only max 10MB.";
                                                                                }
                                                                                else if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0]?.type)) {
                                                                                    return "Allow only pdf, doc, docx format.";
                                                                                }
                                                                            }

                                                                        }
                                                                    }
                                                                })} />

                                                                {errors.drivers_license_new?.message && (
                                                                    <p className='text-danger'>{errors.drivers_license_new.message}</p>
                                                                )}
                                                                {(authCheck?.role_id == 1) &&
                                                                    <div className="mt-2" style={{ clear: "both" }}>
                                                                        <div className="float-left my-1">
                                                                        <label>
                                                                            <input
                                                                            type="checkbox"
                                                                            name="drivers_license_new_checkbox"
                                                                            value={1}
                                                                            style={{  'margin-right': '3px' }}
                                                                            {...register("drivers_license_new_checkbox")}
                                                                            onChange={(e) => {
                                                                                // Handle checkbox changes here if needed
                                                                            }}
                                                                            /> Make Required
                                                                        </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="mt-2" style={{ clear: 'both' }}>
                                                                    {apiData?.drivers_license != "" &&
                                                                        <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(apiData.drivers_license)} >
                                                                            <i className="fas fa-download"></i>Download file</a>
                                                                        </div>
                                                                    }
                                                                    {/*<div className="float-left my-1">
                                                      <span className="btn btn-default ml-2"><i className="far fa-trash-alt"></i>Remove file</span>
                                                  </div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Front of COVID-19 Vaccine Card </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input className="form-control text-box single-line" type="file" accept='.pdf,.doc,.docx' {...register('vaccine_card_front_new', {
                                                                    validate: {
                                                                        fileupload: (files) => {
                                                                            if (files.length != 0) {
                                                                                if (files[0]?.size > 10000000) {
                                                                                    return "Allow only max 10MB.";
                                                                                }
                                                                                else if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0]?.type)) {
                                                                                    return "Allow only pdf, doc, docx format.";
                                                                                }
                                                                            }

                                                                        }
                                                                    }
                                                                })} />
                                                                {errors.vaccine_card_front_new?.message && (
                                                                    <p className='text-danger'>{errors.vaccine_card_front_new.message}</p>
                                                                )}
                                                                {(authCheck?.role_id == 1) &&
                                                                    <div className="mt-2" style={{ clear: "both" }}>
                                                                        <div className="float-left my-1">
                                                                        <label>
                                                                            <input
                                                                            type="checkbox"
                                                                            name="vaccine_card_front_new_checkbox"
                                                                            value={1}
                                                                            style={{  'margin-right': '3px' }}
                                                                            {...register("vaccine_card_front_new_checkbox")}
                                                                            onChange={(e) => {
                                                                                // Handle checkbox changes here if needed
                                                                            }}
                                                                            /> Make Required
                                                                        </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="mt-2" style={{ clear: 'both' }}>
                                                                    {apiData?.vaccine_card_front != "" &&
                                                                        <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(apiData.vaccine_card_front)} >
                                                                            <i className="fas fa-download"></i>Download file</a>
                                                                        </div>
                                                                    }
                                                                    {/*<div className="float-left my-1">
                                                      <span className="btn btn-default ml-2"><i className="far fa-trash-alt"></i>Remove file</span>
                                                  </div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Back of COVID-19 Vaccine Card  </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input className="form-control text-box single-line" type="file" accept='.pdf,.doc,.docx' {...register('vaccine_card_back_new', {
                                                                    validate: {
                                                                        fileupload: (files) => {
                                                                            if (files.length != 0) {
                                                                                if (files[0]?.size > 10000000) {
                                                                                    return "Allow only max 10MB.";
                                                                                }
                                                                                else if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0]?.type)) {
                                                                                    return "Allow only pdf, doc, docx format.";
                                                                                }
                                                                            }

                                                                        }
                                                                    }
                                                                })} />
                                                                {errors.vaccine_card_back_new?.message && (
                                                                    <p className='text-danger'>{errors.vaccine_card_back_new.message}</p>
                                                                )}
                                                                {(authCheck?.role_id == 1) &&
                                                                    <div className="mt-2" style={{ clear: "both" }}>
                                                                        <div className="float-left my-1">
                                                                        <label>
                                                                            <input
                                                                            type="checkbox"
                                                                            name="vaccine_card_back_new_checkbox"
                                                                            value={1}
                                                                            style={{  'margin-right': '3px' }}
                                                                            {...register("vaccine_card_back_new_checkbox")}
                                                                            onChange={(e) => {
                                                                                // Handle checkbox changes here if needed
                                                                            }}
                                                                            /> Make Required
                                                                        </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="mt-2" style={{ clear: 'both' }}>
                                                                    {apiData?.vaccine_card_back != "" &&
                                                                        <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(apiData.vaccine_card_back)} >
                                                                            <i className="fas fa-download"></i>Download file</a>
                                                                        </div>
                                                                    }
                                                                    {/*<div className="float-left my-1">
                                                      <span className="btn btn-default ml-2"><i className="far fa-trash-alt"></i>Remove file</span>
                                                  </div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
            { Array.isArray(postionData)  &&  (postionData.includes("2") ||  postionData.includes("1") ) &&                                                    
 <div className="form-group row">
  <div className="col-md-3">
    <div className="label-wrapper">
      <label className="col-form-label p-0">
        CPR certification for BCBA & RBT only
      </label>
    </div>
  </div>
  <div className="col-md-9">
    {/* File Input Field */}
    <input
      className="form-control text-box single-line"
      type="file"
      accept=".pdf,.doc,.docx"
      {...register('cpr_file_certificate', {
        validate: {
          fileupload: (files) => {
            if (!apiData?.cpr_file_certificate && files.length !== 0) {
              // File size validation
              if (files[0]?.size > 10000000) {
                return "Allow only max 10MB.";
              }
              // File type validation
              const allowedTypes = [
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ];
              if (!allowedTypes.includes(files[0]?.type)) {
                return "Allow only pdf, doc, docx format.";
              }
            }
          }
        }
      })}
    />
    
    {/* Error Message for the File Input */}
    {errors.cpr_file_certificate?.message && (
      <p className="text-danger">{errors.cpr_file_certificate.message}</p>
    )}
      {(authCheck?.role_id == 1) &&
                                                                    <div className="mt-2" style={{ clear: "both" }}>
                                                                        <div className="float-left my-1">
                                                                        <label>
                                                                            <input
                                                                            type="checkbox"
                                                                            name="cpr_certificate_new_checkbox"
                                                                            value={1}
                                                                            style={{  'margin-right': '3px' }}
                                                                            {...register("cpr_certificate_new_checkbox")}
                                                                            onChange={(e) => {
                                                                                // Handle checkbox changes here if needed
                                                                            }}
                                                                            /> Make Required
                                                                        </label>
                                                                        </div>
                                                                    </div>
                                                                }

    {/* File Download Section */}
    <div className="mt-2" style={{ clear: 'both' }}>
      {apiData?.cpr_file_certificate && (
        <div className="float-left my-1">
          <a
            className="btn btn-default"
            onClick={() => common.axiosDownloadFile(apiData.cpr_file_certificate)}
          >
            <i className="fas fa-download"></i>Download file
          </a>
        </div>
      )}
    </div>
  </div>
</div>
}
{ Array.isArray(postionData)  &&  (postionData.includes("3")  ) &&                                                    
 <div className="form-group row">
  <div className="col-md-3">
    <div className="label-wrapper">
      <label className="col-form-label p-0">
      Professional license for IIC Applicants
      </label>
    </div>
  </div>
  <div className="col-md-9">
    {/* File Input Field */}
    <input
      className="form-control text-box single-line"
      type="file"
      accept=".pdf,.doc,.docx"
      {...register('iic_professional_certificate', {
        validate: {
          fileupload: (files) => {
            if (!apiData?.iic_professional_certificate && files.length !== 0) {
              // File size validation
              if (files[0]?.size > 10000000) {
                return "Allow only max 10MB.";
              }
              // File type validation
              const allowedTypes = [
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ];
              if (!allowedTypes.includes(files[0]?.type)) {
                return "Allow only pdf, doc, docx format.";
              }
            }
          }
        }
      })}
    />
    
    {/* Error Message for the File Input */}
    {errors.iic_professional_certificate?.message && (
      <p className="text-danger">{errors.iic_professional_certificate.message}</p>
    )}

{(authCheck?.role_id == 1) &&
<div className="mt-2" style={{ clear: "both" }}>
<div className="float-left my-1">
 <label>
  <input type="checkbox" name="iic_professional_certificate_checkbox" value={1} style={{  'margin-right': '3px' }}
    {...register("iic_professional_certificate_checkbox")} onChange={(e) => {   // Handle checkbox changes here if needed
    }}
  /> Make Required
    </label>
     </div>
      </div>
      }                                                    

    {/* File Download Section */}
    <div className="mt-2" style={{ clear: 'both' }}>
      {apiData?.iic_professional_certificate && (
        <div className="float-left my-1">
          <a
            className="btn btn-default"
            onClick={() => common.axiosDownloadFile(apiData.iic_professional_certificate)}
          >
            <i className="fas fa-download"></i>Download file
          </a>
        </div>
      )}
    </div>
  </div>
</div>
}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="vert-tabs-background" role="tabpanel" aria-labelledby="vert-tabs-background-tab">
                                                <div className="card card-secondary card-outline" data-card-name="background" id="background">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-coins"></i> Background Check</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Have you ever been convicted of a crime (other than a moving violation) or served time? <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="raw">
                                                                    <div className="form-check">
                                                                        <input type="radio" className="form-check-input" name="convicted_crime" id="convicted_crime_no" value={0}  {...register('crime', { required: "Please select crim." })} />
                                                                        <label className="form-check-label"  >No</label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input type="radio" className="form-check-input" name="convicted_crime" id="convicted_crime_yes" value={1}  {...register('crime', { required: "Please select crim." })} />
                                                                        <label className="form-check-label"  >Yes</label>
                                                                    </div>

                                                                </div>
                                                                {errors.crime?.message && (
                                                                    <p className='text-danger'>{errors.crime.message}</p>
                                                                )}



                                                            </div>


                                                        </div>


                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">(If yes, please describe. Conviction of a crime does not automatically disqualify you from employment.) <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="raw">
                                                                    <input className="form-control text-box single-line" type="text" {...register('crime_yes', {
                                                                        validate: {
                                                                            required: value => {
                                                                                if (!value && getValues('crime') === '1') return 'Please describe.';
                                                                                return true;
                                                                            },
                                                                        }
                                                                    })} />
                                                                    {errors.crime_yes?.message && (
                                                                        <p className='text-danger'>{errors.crime_yes.message}</p>
                                                                    )}

                                                                </div>



                                                               {(Array.isArray(postionData)) && (!postionData.includes("11") ) && <div className='mt-4'>
                                                                    <a href='http://www.candidatelink.com/ProgressiveOptionSupport' target='_blank'>

                                                                        Background Check <i className="fa-solid fa-arrow-right"></i>
                                                                    </a>
                                                                </div>}

                                                               



                                                            </div>
                                                            
                                                           

                                                        </div>


                                                        {(Array.isArray(postionData)) && (postionData.includes("11")) &&
                                                            <div className="form-group row">
                                                                <div className="col-md-3">
                                                                    <div className="label-wrapper">
                                                                        <label className="col-form-label p-0">Instructions For Respite Candidates</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9">

                                                                    <div>
                                                                        {console.log("ggg ", (apiData.respiteInstructDoc))}
                                                                        {(apiData.respiteInstructDoc) && (
                                                                            <div key={apiData.respiteInstructDoc.id}>
                                                                                <a
                                                                                    type="button"
                                                                                    style={{ textDecoration: 'underline' }}
                                                                                    onClick={() => toggleExpand3(apiData.respiteInstructDoc.id)}
                                                                                >
                                                                                    {expandedInstruction[apiData.respiteInstructDoc.id] ? 'Close Instructions' : 'View Instructions'}
                                                                                </a>
                                                                            </div>
                                                                        )}

                                                                    </div>

                                                                    {apiData.respiteInstructDoc && expandedInstruction[apiData.respiteInstructDoc.id] && (
                                                                        <div
                                                                            dangerouslySetInnerHTML={{ __html: apiData.respiteInstructDoc.contnet }}
                                                                        />
                                                                    )}






                                                                </div>



                                                            </div>}


                                                            {(Array.isArray(postionData)) && (postionData.includes("3")) &&
                                                            <div className="form-group row">
                                                                <div className="col-md-3">
                                                                    <div className="label-wrapper">
                                                                        <label className="col-form-label p-0">Instructions For IIC Candidates</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9">

                                                                    <div>
                                                                        {console.log("ggg ", (apiData.iicInstructDoc))}
                                                                        {(apiData.iicInstructDoc) && (
                                                                            <div key={apiData.iicInstructDoc.id}>
                                                                                <a
                                                                                    type="button"
                                                                                    style={{ textDecoration: 'underline' }}
                                                                                    onClick={() => toggleExpand3(apiData.iicInstructDoc.id)}
                                                                                >
                                                                                    {expandedInstruction[apiData.iicInstructDoc.id] ? 'Close Instructions' : 'View Instructions'}
                                                                                </a>
                                                                            </div>
                                                                        )}

                                                                    </div>

                                                                    {apiData.iicInstructDoc && expandedInstruction[apiData.iicInstructDoc.id] && (
                                                                        <div
                                                                            dangerouslySetInnerHTML={{ __html: apiData.iicInstructDoc.contnet }}
                                                                        />
                                                                    )}






                                                                </div>



                                                            </div>}



                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="vert-tabs-references" role="tabpanel" aria-labelledby="vert-tabs-references-tab">
                                                <div className="card card-secondary card-outline" data-card-name="professional_references" id="professional_references">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-plus-square"></i> Professional References</div>
                                                    </div>
                                                    <div className="card-body">
                                                        {
                                                            inputFields.map((data, index) => {
                                                                const { name, email, phone, relationship } = data;
                                                                return (
                                                                    <div className="row" key={index}>

                                                                        <div className="col-md-10">
                                                                            <div className="row">
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label>Name <span className="required">*</span></label>
                                                                                        <input className="form-control text-box single-line" name="name" type="text"  {...register(`professional_references.[${index}].name`, { required: "Please enter name." })} />

                                                                                        <p className='text-danger'> {errors.professional_references?.[index]?.name?.message}</p>



                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label>Email Address <span className="required">*</span></label>
                                                                                        <input className="form-control text-box single-line" name="email" type="text" {...register(`professional_references.[${index}].email`, {
                                                                                            required: "Please enter email address.",
                                                                                            validate: {
                                                                                                maxLength: (v) =>
                                                                                                    v.length <= 50 || "The email should have at most 50 characters",
                                                                                                matchPattern: (v) =>
                                                                                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                                                                    "Please enter a valid email address",
                                                                                            },
                                                                                        })} />
                                                                                        <p className='text-danger'> {errors.professional_references?.[index]?.email?.message}</p>


                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label>Phone Number <span className="required">*</span></label>
                                                                                        <input className="form-control text-box single-line" name="phone" type="text" {...register(`professional_references.[${index}].phone`, {
                                                                                            required: {
                                                                                                value: true,
                                                                                                message: "Please enter cell phone number."
                                                                                            },
                                                                                            pattern: {
                                                                                                value: /^[0-9+-]+$/,
                                                                                                message: "This is not a valid cell phone number."
                                                                                            },
                                                                                            minLength: {
                                                                                                value: 10,
                                                                                                message: "Please enter valid cell phone number."
                                                                                            },
                                                                                            maxLength: {
                                                                                                value: 12,
                                                                                                message: "Please enter valid cell phone number."
                                                                                            }
                                                                                        })} />
                                                                                        <p className='text-danger'> {errors.professional_references?.[index]?.phone?.message}</p>


                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label>Years Known/Relationship <span className="required">*</span></label>
                                                                                        <input className="form-control text-box single-line" name="relationship" type="text" {...register(`professional_references.[${index}].relationship`, { required: "Please enter relationship." })} />
                                                                                        <p className='text-danger'> {errors.professional_references?.[index]?.relationship?.message}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-2">
                                                                            <div className="form-group">
                                                                                <div><label>&nbsp;</label></div>
                                                                                {(inputFields.length > 3) ? <div className="btn btn-outline-danger" value={index} onClick={e => removeInputFields(e.target.value, inputFields.length)}>Remove</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                        }

                                                        <div className="row">
                                                            <div className="col-md-4">

                                                                <div className="btn btn-default" onClick={addInputField}><i className="right fas fa-plus"></i> Add more</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="vert-tabs-tax-forms" role="tabpanel" aria-labelledby="vert-tabs-tax-forms-tab">
                                                <div className="card card-secondary card-outline" data-card-name="tax_forms" id="tax_forms">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-th-list"></i> State & Federal Tax Forms</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Form Name</th>
                                                                    <th>Status</th>
                                                                    <th className="Action">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { (apiData.employment_type_id == 1) &&
                                                                <tr>
                                                                    <td>I-9 Eligibility Form</td>
                                                                    <td>{(apiData?.I9EligibilityForm == null) ? 'Pending' : 'Completed'}</td>
                                                                    <td>

                                                                        <a href="#" onClick={i9handleShow} ><i className="fas fa-edit"></i></a>



                                                                        &nbsp;&nbsp;  {(apiData?.I9EligibilityForm == null) ? '' : <a href="#" onClick={() => common.axiosDownloadFile(apiData?.I9EligibilityForm[0]?.eligibility_file_name_url)} ><i className="fas fa-download"></i></a>}
                                                                    </td>
                                                                </tr>
                                                                }
                                                                { (apiData.employment_type_id == 1) &&
                                                                <tr>
                                                                    <td>Federal W-4 Form</td>
                                                                    <td>{(apiData?.FederalW4Form == null) ? 'Pending' : 'Completed'}</td>
                                                                    <td>

                                                                        <a href="#" onClick={w4handleShow} ><i className="fas fa-edit"></i></a>

                                                                        &nbsp;&nbsp;  {(apiData?.FederalW4Form == null) ? '' : <a href="#" onClick={() => common.axiosDownloadFile(apiData?.FederalW4Form[0]?.w4_file_name_url)} ><i className="fas fa-download"></i></a>}

                                                                    </td>
                                                                </tr>
                                                                }
                                                                { (apiData.employment_type_id == 1) &&
                                                                <tr>
                                                                    <td>NJ Employee Withholding</td>
                                                                    <td>{(apiData?.NJEmployeeWithholding == null) ? 'Pending' : 'Completed'}</td>
                                                                    <td>
                                                                        <a href="#" onClick={njhandleShow} ><i className="fas fa-edit"></i></a>
                                                                        &nbsp;&nbsp;  {(apiData?.NJEmployeeWithholding == null) ? '' : <a href="#" onClick={() => common.axiosDownloadFile(apiData?.NJEmployeeWithholding[0]?.allowance_file_name_url)} ><i className="fas fa-download"></i></a>}
                                                                    </td>
                                                                </tr>
                                                                }
                                                                { (apiData.employment_type_id == 1) &&
                                                                    <tr>
                                                                        <td>Right to be Free of Gender Inequity</td>
                                                                        <td>{(apiData?.TaxGenderInequity == null) ? 'Pending' : 'Completed'}</td>
                                                                        <td>
                                                                            <a href="#" onClick={genderInequityhandleShow} ><i className="fas fa-edit"></i></a>
                                                                            &nbsp;&nbsp;  {(apiData?.TaxGenderInequity == null) ? '' : <a href="#" onClick={() => common.axiosDownloadFile(apiData?.TaxGenderInequity[0]?.gender_inquity_pdf_url)} ><i className="fas fa-download"></i></a>}
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                 { (apiData.employment_type_id == 1) &&
                                                                    <tr>
                                                                        <td>Applicant Certification and Release</td>
                                                                        <td>{(apiData?.candidateFormAbuse == null) ? 'Pending' : 'Completed'}</td>
                                                                        <td>
                                                                            <a href="#" onClick={genderInequityhandleShows} ><i className="fas fa-edit"></i></a>
                                                                            &nbsp;&nbsp;  {(apiData?.candidateFormAbuse == null) ? '' : <a href="#" onClick={() => common.axiosDownloadFile(apiData?.candidateFormAbuse[0]?.abuse_pdf_url)} ><i className="fas fa-download"></i></a>}
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                 { (apiData.employment_type_id == 1 && apiData?.candidateFormAbuse != null) &&
                                                                    <tr>
                                                                        <td>Disclosure Release Section 2 to a current/previous employer</td>
                                                                        <td>{(apiData?.candidateJerseyAbuse == null) ? 'Pending' : 'Completed'}</td>
                                                                        <td>

                                                                            <a href="#" onClick={gendersecInequityhandleShows} ><i className="fas fa-edit"></i></a>
                                                                            &nbsp;&nbsp;  {(apiData?.candidateJerseyAbuse == null) ? '' : <a href="#" onClick={() => common.axiosDownloadFile(apiData?.candidateJerseyAbuse[0]?.jersey_abuse_pdf_url)} ><i className="fas fa-download"></i></a>}
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {/* { (apiData.employment_type_id == 2) &&
                                                                    <tr>
                                                                        <td>W-9 Form</td>
                                                                        <td>{(apiData?.candidateFormW9 == null) ? 'Pending' : 'Completed'}</td>
                                                                        <td><a href="#" onClick={w9handleShow} ><i className="fas fa-edit"></i></a></td>
                                                                    </tr>
                                                                } */}
                                                            </tbody>
                                                        </table>




                                                    </div>
                                                </div>
                                            </div>

                                            {/* IIC ,Psychotherepy performance form table */}

                                            <div className="tab-pane fade" id="vert-tabs-perf" role="tabpanel" aria-labelledby="vert-tabs-perf-tab">
                                                <div className="card card-secondary card-outline" data-card-name="tax_forms" id="tax_forms">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-th-list"></i> Performance Evaluation</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Form Name</th>
                                                                    <th>Status</th>
                                                                    <th className="Action">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Array.isArray(postionData) && (postionData.includes("3") || postionData.includes("5")) && (apiData.employment_type_id == 1) &&
                                                                <tr>
                                                                    <td>Performance Evaluation Form (IIC, Psychotherapy)</td>
                                                                    <td>{(apiData?.candidateFormIIC == null) ? 'Pending' : 'Completed'}</td>
                                                                    <td>

                                                                        <a href="#" onClick={iichandleShow} ><i className="fas fa-edit"></i></a>



                                                                        &nbsp;&nbsp;  {(apiData?.candidateFormIIC == null) ? '' : <a href="#" onClick={() => common.axiosDownloadFile(apiData?.candidateFormIIC[0]?.allowance_file_name_url)} ><i className="fas fa-download"></i></a>}
                                                                    </td>
                                                                </tr>
                                                                }
                                                                
                                                            </tbody>
                                                        </table>




                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="vert-tabs-agreement" role="tabpanel" aria-labelledby="vert-tabs-agreement-tab">
                                                <div className="card card-secondary card-outline" data-card-name="certification_agreement" id="certification_agreement">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="far fa-address-book"></i> Applicant's Certification Agreement</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label">Signature of Applicant <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">


                                                                {/* <Controller
                                                                    name="new_signature"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <SignatureCanvas
                                                                            ref={sigRef}
                                                                            onEnd={() => field.onChange(formatIntoPng())}
                                                                            penColor="black"
                                                                            canvasProps={{
                                                                                width: 315,
                                                                                height: 200,
                                                                                style: { border: "1px solid black" },
                                                                            }}
                                                                        />
                                                                    )}
                                                                />


                                                               <button onClick={clearSignature}>Clear</button>    */}



                                                                {apiData?.signature != "" && <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(apiData.signature)}>
                                                                    <i className="fas fa-download"></i>Download file</a>
                                                                </div>}
                                                                {/* <div className="float-left my-1">
                                                      <span className="btn btn-default ml-2"><i className="far fa-trash-alt"></i>Remove file</span>
</div> */}

                                                            </div>

                                                            <div className="col-md-4">
                                                                {apiData?.signature != "" && <img src={apiData?.signature}></img>}
                                                            </div>


                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label">Resume/CV <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input className="form-control text-box single-line" type="file" accept='.pdf,.doc,.docx' {...register('resume_new', {
                                                                    validate: {
                                                                        fileupload: (files) => {


                                                                            if (files.length != 0) {

                                                                                if (files[0]?.size > 10000000) {
                                                                                    return "Allow only max 10MB.";
                                                                                }
                                                                                else if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0]?.type)) {
                                                                                    return "Allow only pdf, doc, docx format.";
                                                                                }
                                                                            }

                                                                        }
                                                                    }
                                                                })} />

                                                                {errors.resume_new?.message && (
                                                                    <p className='text-danger'>{errors.resume_new.message}</p>
                                                                )}


                                                                <div className="mt-2" style={{ clear: 'both' }}>

                                                                    {apiData?.resume != "" &&
                                                                        <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(apiData.resume)} >
                                                                            <i className="fas fa-download"></i>Download file</a>
                                                                        </div>
                                                                    }


                                                                    {/*<div className="float-left my-1">
                                                      <span className="btn btn-default ml-2"><i className="far fa-trash-alt"></i>Remove file</span>
                                                  </div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label p-0">Availability <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="raw">
                                                                    {dynamicField.availabilities_field && dynamicField.availabilities_field.map((av, key) => {

                                                                        return <div className="form-check" key={key}>
                                                                            <input type="checkbox" className="form-check-input" value={av.id}  {...register('availability', { required: "Please select availability." })} />
                                                                            <label className="form-check-label">{av.name}</label>
                                                                        </div>;
                                                                    })}

                                                                    {errors.availability?.message && (
                                                                        <p className='text-danger'>{errors.availability.message}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <div className="label-wrapper">
                                                                    <label className="col-form-label">Date <span className="required">*</span></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div style={{ marginTop: 4 }}>
                                                                    {apiData?.newdate}


                                                                </div>

                                                            </div>
                                                        </div>






                                                    </div>
                                                </div>
                                            </div>



                                            {(authCheck?.role_id != 4) && <div className="tab-pane fade" id="vert-tabs-checklist" role="tabpanel" aria-labelledby="vert-tabs-checklist-tab">
                                                <div className="card card-secondary card-outline" data-card-name="checklist" id="checklist">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="far fa-address-book"></i> Checklist</div>
                                                    </div>
                                                    <div className="card-body">

                                                        <table className="table table-bordered table-striped">
                                                            <tbody>



                                                                {fieldSettings?.prescreening_email == 1 && <tr>
                                                                    <td>Prescreening Email/ Request for Documents Sent</td>
                                                                    <td>
                                                                        <div className="raw">
                                                                            <div className="form-check">
                                                                                <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1}  {...register('prescreening_email')} />
                                                                                    Yes</label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>}



                                                                {fieldSettings?.completed_application == 1 && <tr>
                                                                    <td>Completed Application (Resource Coordinator)</td>
                                                                    <td>
                                                                        <div className="raw">
                                                                            <div className="form-check">
                                                                                <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1}  {...register('completed_application')} />
                                                                                    Yes</label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>}




                                                                {fieldSettings?.resume_checklist == 1 && <tr>
                                                                    <td>Resume (Resource Coordinator)</td>
                                                                    <td>
                                                                        <div className="raw">
                                                                            <div className="form-check">
                                                                                <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('resume_checklist')} />
                                                                                    Yes</label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>}


                                                                {fieldSettings?.prof_of_liability_insurance == 1 && <tr>
                                                                    <td>Prof of Liability Insurance for those seeking supervision (Resource Specialist)</td>
                                                                    <td>
                                                                        <div className="raw">
                                                                            <div className="form-check">
                                                                                <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('prof_of_liability_insurance')} />
                                                                                    Yes</label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>}





                                                                {fieldSettings?.reference_check === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11")  && !postionData.includes("5") )) && (
                                                                <tr>
                                                                    <td>Reference Check</td>
                                                                    <td>
                                                                        <div className="raw">
                                                                            <div className="form-check">
                                                                                <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('reference_check')} />
                                                                                    Yes</label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                               ) }


                                                                {fieldSettings?.diploma_checklist == 1 && <tr>
                                                                    <td>Diploma(Resource Specialist)</td>
                                                                    <td>
                                                                        <div className="raw">
                                                                            <div className="form-check">
                                                                                <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('diploma_checklist')} />
                                                                                    Yes</label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>}



                                                                {fieldSettings?.copy_of_certification == 1 && <tr>
                                                                    <td>Copy of Certification BCBA, RBT, BCABa's only (Resource Specialist)</td>
                                                                    <td>
                                                                        <div className="raw">
                                                                            <div className="form-check">
                                                                                <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('copy_of_certification')} />
                                                                                    Yes</label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>}



                        {fieldSettings?.certification_expiration === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11")  && !postionData.includes("5") )) && (

                                                               <tr>
                                                                    <td>Certification Expiration (BCBA, RBT)</td>
                                                                    <td>
                                                                        <div className="raw">
                                                                            <div className="">
                                                                                <Controller
                                                                                    control={control}
                                                                                    name='certification_expiration'

                                                                                    render={({ field }) => (
                                                                                        <DatePicker
                                                                                            className="form-control text-box single-line datetimepicker-input"
                                                                                            placeholderText='Select date'
                                                                                            onChange={(date) => field.onChange(date)}
                                                                                            selected={field.value}
                                                                                            minDate={new Date()}
                                                                                            dateFormat="MM/dd/yyyy"

                                                                                        />


                                                                                    )}
                                                                                />
                                                                                {errors.certification_expiration?.message && (
                                                                                    <p className='text-danger'>{errors.certification_expiration.message}</p>
                                                                                )}


                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>)}


                                                                {fieldSettings?.drivers_license_checklist == 1 &&
                                                                    <tr>
                                                                        <td>Drivers License (Resoure Specialist)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">  <input type="checkbox" className="form-check-input" value={1}  {...register('drivers_license_checklist')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }

                                                            {fieldSettings?.vaccine_card_checklist === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                     !postionData.includes("11")) && (
                                                                
                                                                    <tr>
                                                                        <td>Vaccine Card (Resource Staff)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('vaccine_card_checklist')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                              )  }
                                                                  {fieldSettings?.background_check_link === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                     !postionData.includes("11")) && (
                                                                    <tr>
                                                                        <td>Background Check Link filled</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('background_check_link')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}

                                                                {fieldSettings?.background_check_request == 1 &&
                                                                    <tr>
                                                                        <td>Background Check Request</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('background_check_request')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }





                                                                {fieldSettings?.adp_background_check_ordered === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                     !postionData.includes("11")) && (
                                                                    <tr>
                                                                        <td>ADP background check Ordered</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('adp_background_check_ordered')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                 {fieldSettings?.adp_background_check_seperate === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                     !postionData.includes("11")) && (
                                                                    <tr>
                                                                        <td>ADP background Check seperate (result)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('adp_background_check_seperate')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                 {fieldSettings?.send_request_attend === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                     !postionData.includes("3")&& 
                                                                     !postionData.includes("1")  && !postionData.includes("5")) && (
                                                                    <tr>
                                                                        <td>Send a Request to Attend Training (Viviana)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1}  {...register('send_request_attend')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                 ) }
                                                                 {fieldSettings?.actually_attended === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                     !postionData.includes("3")) && (
                                                                    <tr>
                                                                        <td>Confirmation Actually Attended Training (Viviana)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">  <input type="checkbox" className="form-check-input" value={1}  {...register('actually_attended')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}


                                                                    {fieldSettings?.setup_rasmus_account === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11") && !postionData.includes("1")  && !postionData.includes("5"))) && (
                                                                    <tr>
                                                                        <td>Set up Hi Rasmus Account (Managers)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('setup_rasmus_account')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                               ) }



                                                                {fieldSettings?.insurance_recheck === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11"))&& !postionData.includes("2")  && !postionData.includes("5")) && (
                                                                    <tr>
                                                                        <td>Insurance recheck</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('insurance_recheck')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}



                                                            {fieldSettings?.bcba_supervision_certification === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11")&& !postionData.includes("2")  && !postionData.includes("5"))) && (
                                                                    <tr>
                                                                        <td>BCBA supervision certification</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1}  {...register('bcba_supervision_certification')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}




                                                                {fieldSettings?.send_email_agreement == 1 &&
                                                                    <tr>
                                                                        <td>Send Email to Sign Employee Agreement will be left in Folder (Resource Coordinator)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('send_email_agreement')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.employee_agreement_received == 1 &&
                                                                    <tr>
                                                                        <td>Employee Agreement Received (Resource Coordinator)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('employee_agreement_received')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.contract_on_hr_folder == 1 &&
                                                                    <tr>
                                                                        <td>Contract on HR folder</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('contract_on_hr_folder')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }


                                                            {fieldSettings?.send_ready_work_to_manager === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11")  && !postionData.includes("5"))) && (
                                                                    <tr>
                                                                        <td>Send out READY To Work to Manager</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('send_ready_work_to_manager')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}

                                                                {fieldSettings?.w2_contract == 1 &&
                                                                    <tr>
                                                                        <td>W-2 contract</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('w2_contract')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }



                                                                {fieldSettings?.pay_email_setup_direct_deposit == 1 &&
                                                                    <tr>
                                                                        <td>Pay Email to Setup Direct Deposit (Resource)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('pay_email_setup_direct_deposit')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }






                                                                {fieldSettings?.activate_poss_account == 1 &&
                                                                    <tr>
                                                                        <td>Activate POSS Account</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('activate_poss_account')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                   {fieldSettings?.independent_contract === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                (!postionData.includes("3") && !postionData.includes("11") && !postionData.includes("2")  && !postionData.includes("5"))) && (
                                                                    <tr>
                                                                        <td>Independent Contract -1099 (Resource Specialist)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('independent_contract')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                               ) }

                                                                {fieldSettings?.email_sent_for_direct_deposit_setup == 1 &&
                                                                    <tr>
                                                                        <td>Email Sent for Direct Deposit Setup, I-9 Form, NJ and Federal Tax Withholding to be Completed</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('email_sent_for_direct_deposit_setup')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.check_direct_deposit_link_completed == 1 &&
                                                                    <tr>
                                                                        <td>Check Direct Deposit Link Completed</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('check_direct_deposit_link_completed')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.check_provider_added_to_adp_system === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    ( !postionData.includes("11"))) && (
                                                               
                                                                    <tr>
                                                                        <td>Check Provider Added to ADP System: (Leo) </td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('check_provider_added_to_adp_system')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                             {fieldSettings?.tp_due_date_list === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11")  && !postionData.includes("5"))) && (
                                                                
                                                                    <tr>
                                                                        <td>Ensure they are added to the TP Due Date List </td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('tp_due_date_list')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                               ) }

                                                                {fieldSettings?.provider_completely_hired == 1 &&
                                                                    <tr>
                                                                        <td>Provider Completely Hired </td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('provider_completely_hired')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }

{fieldSettings?.bcba_professional_license === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11") && !postionData.includes("2")  && !postionData.includes("5"))) && (
                                                                    <tr>
                                                                        <td>BCBA Professional license </td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('bcba_professional_license')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                              )  }
                                                    {fieldSettings?.supervision_documentation === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                ( !postionData.includes("11")) && ( !postionData.includes("2"))) && (
                                                               
                                                                    <tr>
                                                                        <td>Supervision documentation/ Certification </td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('supervision_documentation')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}


{fieldSettings?.annual_evaluation === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11") && !postionData.includes("5") )) && (
                                                               
                                                                    <tr>
                                                                        <td>Annual Evaluation</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('annual_evaluation')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                              )  }






                                                                {fieldSettings?.nj_w4_form_returned == 1 &&
                                                                    <tr>
                                                                        <td>NJ-W4 Form Returned & Filed</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('nj_w4_form_returned')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.w_4_form_returned == 1 &&
                                                                    <tr>
                                                                        <td>W-4 Form Returned & Filed</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('w_4_form_returned')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.i_9_form == 1 &&
                                                                    <tr>
                                                                        <td>I-9 Form, Form Completed, Returned and Filed</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('i_9_form')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.i_9_recheck == 1 &&
                                                                    <tr>
                                                                        <td>I9 Re-check</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('i_9_recheck')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.nj_w4_recheck == 1 &&
                                                                    <tr>
                                                                        <td>NJ-W4 recheck</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('nj_w4_recheck')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }
                                                                {fieldSettings?.w_4_recheck == 1 &&
                                                                    <tr>
                                                                        <td>W4 Re-check</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('w_4_recheck')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }

                                                                
{fieldSettings?.adp_recheck === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("11"))) && (
                                                               
                                                                    <tr>
                                                                        <td>ADP Re-check</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('adp_recheck')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {fieldSettings?.application_form_with_references == 1 &&
                                                                    <tr>
                                                                        <td>Application form with references</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('application_form_with_references')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }
                                                                {fieldSettings?.job_description == 1 &&
                                                                    <tr>
                                                                        <td>Job description/ signed contract</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('job_description')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }

                                                                
{fieldSettings?.rbt_professional_license === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11") && !postionData.includes("1")  && !postionData.includes("5"))) && (
                                                              
                                                                    <tr>
                                                                        <td>RBT Professional license</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('rbt_professional_license')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                               ) }
                                                                {fieldSettings?.supervisor == 1 &&
                                                                    <tr>
                                                                        <td>Supervisor's Credentials/Certificate (managers)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('supervisor')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.performance_eval === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11"))) && (
                                                             
                                                                    <tr>
                                                                        <td>Performance Eval.</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('performance_eval')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                               ) }

{fieldSettings?.evidence_of_ongoing_staff_training === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11") && !postionData.includes("1")  && !postionData.includes("5"))) && (
                                                               
                                                                    <tr>
                                                                        <td>Evidence of ongoing staff training/ sign in sheet with Jaclyn & Mikaela</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('evidence_of_ongoing_staff_training')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                               ) }

{fieldSettings?.documentation_that_staff_received_training === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11")  && !postionData.includes("5"))) && (
                                                           
                                                                    <tr>
                                                                        <td>Documentation that staff received training/ Orientation with Viviana</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('documentation_that_staff_received_training')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>


                                                                )}
{fieldSettings?.documentation_that_staff_training_ceus === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11") && !postionData.includes("2")  && !postionData.includes("5"))) && (
                                                               
                                                                    <tr>
                                                                        <td>Documentation of Staff Training CEUs 1 per month up to 5 reimbursable up $30 each</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('documentation_that_staff_training_ceus')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>


                                                                )}
{fieldSettings?.supervision_activities === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                    (!postionData.includes("3") && !postionData.includes("11") && !postionData.includes("1")  && !postionData.includes("5"))) && (
                                                              
                                                                    <tr>
                                                                        <td>Supervision Activities/Monthly Logs (Month & Yr) Supervisor for BT</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('supervision_activities')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                               ) }

                                                                {fieldSettings?.w_9_workers_to_complete == 1 &&
                                                                    <tr>
                                                                        <td>Blank W-9 Form for Workers to Complete</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('w_9_workers_to_complete')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }



                                                                {fieldSettings?.resume_from_indeed == 1 &&
                                                                    <tr>
                                                                        <td>Resume from Indeed</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('resume_from_indeed')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }


                                                                {fieldSettings?.jotform_application_completed == 1  && (Array.isArray(postionData)) && (!postionData.includes("1")) &&
                                                                    <tr>
                                                                        <td>Jotform Completed Application Form</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('jotform_application_completed')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }




{fieldSettings?.license_number === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))) && (
                                                                    <tr>
                                                                        <td>License Number</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className=" ">
                                                                                    <input type="text" className="form-control"   {...register('license_number')} />

                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                               ) }

{fieldSettings?.expiration_date === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))) && (
                                                             
                                                                    <tr>
                                                                        <td>Expiration Date</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className=" ">



                                                                                    <Controller
                                                                                        control={control}
                                                                                        name='expiration_date'

                                                                                        render={({ field }) => (
                                                                                            <DatePicker
                                                                                                className="form-control text-box single-line datetimepicker-input"
                                                                                                placeholderText='Select date'
                                                                                                onChange={(date) => field.onChange(date)}
                                                                                                selected={field.value}
                                                                                                minDate={new Date()}
                                                                                                dateFormat="MM/dd/yyyy"

                                                                                            />


                                                                                        )}
                                                                                    />
                                                                                    {errors.expiration_date?.message && (
                                                                                        <p className='text-danger'>{errors.expiration_date.message}</p>
                                                                                    )}




                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                              )  }





{fieldSettings?.college_diploma === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))) && (
                                                           
                                                                    <tr>
                                                                        <td>College/Masters Diploma</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('college_diploma')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                               ) }


{fieldSettings?.professional_liability_insurance_check === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))&& 
                                                                   ( !postionData.includes("2"))) && (
                                                               
                                                                    <tr>
                                                                        <td>Professional Liability Insurance (Optional for IIC's)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('professional_liability_insurance_check')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                )}


{fieldSettings?.completed_adp_background_check === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))) && (
                                                              
                                                                    <tr>
                                                                        <td>Completed ADP Background Check</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('completed_adp_background_check')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                              )  }



                                                            
{fieldSettings?.completed_adp_background_check === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))) && (
                                                                    <tr>
                                                                        <td>Completed ADP Background Check</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('completed_adp_background_check')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                               ) }

                                                                {(apiData?.position_id?.filter(id => ['8', '2', '25'].includes(String(id))).length < 0) && (fieldSettings?.identogo_fingerprint_receipt == 1) && (Array.isArray(postionData) &&
                                                                    (!postionData.includes("2"))) &&
                                                                    <tr>
                                                                        <td>IdentoGo Fingerprinting Receipt</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('identogo_fingerprint_receipt')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }



{fieldSettings?.send_email_to_dcf_for_approval === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))&& 
                                                                   ( !postionData.includes("2"))) && (
                                                             
                                                                    <tr>
                                                                        <td>Send Email to DCF for Approval (Ailene needs to do)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('send_email_to_dcf_for_approval')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                             )   }




                                                                {(apiData?.position_id?.filter(id => ['8', '2', '25'].includes(String(id))).length < 0) && (fieldSettings?.attend_information_session == 1) &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("2")))&&
                                                                    <tr>
                                                                        <td>Attended Information Session w/ Ms. Melanie Mollozzi</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('attend_information_session')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }



                                                                {fieldSettings?.direct_deposit_and_w_9 == 1 &&
                                                                    <tr>
                                                                        <td>Direct Deposit and W-9</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('direct_deposit_and_w_9')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }



{fieldSettings?.reference_check_contact_person === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))) && (

                                                            
                                                                    <tr>
                                                                        <td>Reference Check's Contact Person</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('reference_check_contact_person')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                             )   }


                                                                {fieldSettings?.sent_contractor_agreement == 1 &&
                                                                    <tr>
                                                                        <td>Sent Contractor's Agreement Form</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('sent_contractor_agreement')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }

{fieldSettings?.activated_adp_account === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))) && (
                                                            
                                                                    <tr>
                                                                        <td>Activated ADP Account</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('activated_adp_account')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                              )  }
                                                                 {fieldSettings?.entered_information_in_poss_portal == 1 &&
                                                                    <tr>
                                                                        <td>Entered Information in POSS Portal</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('entered_information_in_poss_portal')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }
{fieldSettings?.endorsed_to_cmo === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11") )
                                                                &&( !postionData.includes("2") )) && (
                                                                    <tr>
                                                                        <td>Endorsed to CMO</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('endorsed_to_cmo')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                               ) }
{fieldSettings?.endorsed_to_clinical_supervisor === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))&& 
                                                                   ( !postionData.includes("2"))) && (
                                          
                                                                    <tr>
                                                                        <td>Endorsed to Clinical Supervisor (Applicable to LAC and LSW)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('endorsed_to_clinical_supervisor')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                              )  }
                                                                

                                                                {fieldSettings?.activated_access_cyber == 1 && (Array.isArray(postionData) &&
                                                                    (!postionData.includes("2"))) &&
                                                                    <tr>
                                                                        <td>Activated Access in Cyber</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('activated_access_cyber')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }    

                                                                {(apiData?.position_id?.filter(id => ['8', '2', '25'].includes(String(id))).length < 0) && (fieldSettings?.sent_email_of_cyber_access == 1) && (Array.isArray(postionData) &&
                                                                    (!postionData.includes("2"))) &&
                                                                    <tr>
                                                                        <td>Sent Email of Cyber Access</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('sent_email_of_cyber_access')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }   
                                                                {fieldSettings?.added_to_adp === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("3") && !postionData.includes("11") && !postionData.includes("2")  && !postionData.includes("5"))) && (
                                                                
 
                                                                    <tr>
                                                                        <td>Added to ADP (under 1099)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('added_to_adp')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                )}
                                                            


                                                            {fieldSettings?.professional_license === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11"))&& 
                                                                   ( !postionData.includes("2"))) && (
                                                               
                                                                    <tr>
                                                                        <td>Professional License (LSW, LAC, LCSW, LPC)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('professional_license')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                               ) }


                                                                {(apiData?.position_id?.filter(id => ['8', '2', '25'].includes(String(id))).length < 0) && (fieldSettings?.w2_jot_form_application_completed == 1)   && (Array.isArray(postionData)) && (!postionData.includes("1")) &&(!postionData.includes("2")) &&
                                                                    <tr>
                                                                        <td>Jotform Application Completed</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('w2_jot_form_application_completed')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                }
 {fieldSettings?.bcba_completed_jotform_application === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("11") && !postionData.includes("1")&& !postionData.includes("2"))) && (
                                                                    <tr>
                                                                        <td>Completed Jotform Application (Resource Coordinator)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('bcba_completed_jotform_application')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                              )  }

{fieldSettings?.certification_bcba_rbt_check === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("3") && !postionData.includes("11")  && !postionData.includes("5"))) && (

                                                                
                                                                    <tr>
                                                                        <td>Copy of Certification BCBA, RBT, BCABa's only (Resource Coordinator)</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('certification_bcba_rbt_check')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                               ) }


{fieldSettings?.cpr_certification === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("3") && !postionData.includes("11")  && !postionData.includes("5"))) && (
                                                                
                                                                    <tr>
                                                                        <td>CPR certification</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"><input type="checkbox" className="form-check-input" value={1} {...register('cpr_certification')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                              )  }

{fieldSettings?.employee_handbook_acknowledgement === 1 &&
                                                                  (  Array.isArray(postionData) && 
                                                                   ( !postionData.includes("3") && !postionData.includes("11")  && !postionData.includes("5"))) && 
                                                              
                                                                    <tr>
                                                                        <td>Employee Handbook Acknowledgement</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('employee_handbook_acknowledgement')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.profeesional_liability == 1 && (Array.isArray(postionData)) && (postionData.includes("1")) &&
                                                                    <tr>
                                                                        <td>Professional Liability Insurance</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('profeesional_liability')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                 {fieldSettings?.orientation_foreseen == 1 && (Array.isArray(postionData)) && (postionData.includes("1")) &&
                                                                    <tr>
                                                                        <td>Orientation with Farzeen</td>
                                                                        <td>
                                                                            <div className="raw">
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('orientation_foreseen')} />
                                                                                        Yes</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {fieldSettings?.orientation_date == 1 && (Array.isArray(postionData)) && (postionData.includes("1")) &&
                                                                    <tr>
                                                                        <td>Orientation date</td>
                                                                        <td>

                                                                            <Controller
                                                                                control={control}
                                                                                name="orientation_date"
                                                                                render={({ field }) => (
                                                                                    <DatePicker
                                                                                        className="form-control text-box single-line datetimepicker-input"
                                                                                        placeholderText="Select date"
                                                                                        onChange={(date) => field.onChange(date)}
                                                                                        selected={field.value && !isNaN(new Date(field.value)) ? new Date(field.value) : null} 
                                                                                        minDate={new Date()}
                                                                                        dateFormat="MM/dd/yyyy"
                                                                                    />
                                                                                )}
                                                                            />
                                                                            {errors.orientation_date?.message && (
                                                                                        <p className='text-danger'>{errors.orientation_date.message}</p>
                                                                                    )}

                                                                        </td>
                                                                    </tr>
                                                                }
                                                                     { (apiData.employment_type_id ==1 )  &&
                                                                 <tr>
                                                                 <td>Employment Disclosure Send 
                                                                                                                                     </td>
                                                                 <td>
                                                                     <div className="raw">
                                                                         <div className="form-check">
                                                                             <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('disclosure_send')} />
                                                                                 Yes</label>
                                                                         </div>
                                                                     </div>
                                                                 </td>
                                                             </tr>
                                                                 } 
                                                                 { (apiData.employment_type_id ==1 )  &&
                                                                 <tr>
                                                                 <td>Employment Disclosure Receive
 
                                                                                                                                     </td>
                                                                 <td>
                                                                     <div className="raw">
                                                                         <div className="form-check">
                                                                             <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('disclosure_receive')} />
                                                                                 Yes</label>
                                                                         </div>
                                                                     </div>
                                                                 </td>
                                                             </tr>
                                                                }
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        }

                                            <div className="tab-pane fade" id="vert-tabs-deposit" role="tabpanel" aria-labelledby="vert-tabs-deposit-tab">
                                                <div className="card card-secondary card-outline" data-card-name="direct-deposit" id="direct-deposit">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-dollar-sign"></i> Direct Deposit Enrollment</div>
                                                        
                                                    </div>
                                                    <div className="form-check">
                                                        <label className="form-check-label"> <input type="checkbox" className="form-check-input" value={1} {...register('allow_candidate_to_edit_deposit')} />
                                                            Allow candidate to edit</label>
                                                    </div>



                                                    <div className="card-body">

                                                        {apiData?.candidateDeposit == null ? (<div className="float-right  ">
                                                            <a className="btn btn-primary  mb-2" onClick={ddhandleShow} ><i className="far fa-save"></i> Add Direct Deposit</a>
                                                        </div>) :




                                                            (
                                                                <div>
                                                                    <table className="table table-bordered table-striped">
                                                                        <thead>
                                                                            <tr>

                                                                                <th>Tax ID or SSN</th>
                                                                                <th>Pay to Name</th>
                                                                                <th>Bank Name</th>
                                                                                <th>Routing Number</th>
                                                                                <th>Account Number</th>
                                                                                <th>Type of Account</th>



                                                                                <th className="Action">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {apiData?.candidateDeposit && apiData.candidateDeposit.map((cd, key) => {
                                                                                if (authCheck?.role_id != 1) {
                                                                                    var lastFourDigits = cd.tax_id.slice(-4);
                                                                                    cd.tax_id = `*** *** ${lastFourDigits}`;
                                                                                    var lastFourDigits = cd.bank_account_number.slice(-4);
                                                                                    cd.bank_account_number = `*** *** ${lastFourDigits}`;
                                                                                }
                                                                                return <tr key={key}>




                                                                                    <td>{cd.tax_id}</td>
                                                                                    <td>{cd.pay_to_name}</td>
                                                                                    <td>{cd.bank_name}</td>
                                                                                    <td>{cd.bank_routing_number}</td>
                                                                                    <td>{cd.bank_account_number}</td>
                                                                                    <td>{(cd.account_type == 1) ? 'Saving' : 'Checking'}</td>


                                                                                    <td>
                                                                                        <a href="#" data-toggle="modal" onClick={editcandidateDeposit}><i className="fas fa-edit"></i></a>
                                                                                        {/*<a href="#"><i className="fas fa-trash"></i></a>*/}
                                                                                    </td>
                                                                                </tr>;
                                                                            })}

                                                                        </tbody>
                                                                    </table>

                                                                    {/*  <div className='mt-4'>
                                                                        <a href='http://www.candidatelink.com/ProgressiveOptionSupport' target='_blank'>

                                                                            Background Check <i className="fa-solid fa-arrow-right"></i>
                                                                        </a>
                                                                        </div> */}

                                                                </div>

                                                            )}




                                                    </div>
                                                </div>
                                            </div>


                                            {(authCheck?.role_id != 4) && <div className="tab-pane fade" id="vert-tabs-scorecard" role="tabpanel" aria-labelledby="vert-tabs-scorecard-tab">
                                                <div className="card card-secondary card-outline">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-star"></i> Scorecard</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="float-right  ">


                                                            {apiData.useraddscoreData == false && <a className="btn btn-primary  mb-2" onClick={scoreCardhandleShow} ><i className="fas fa-edit"></i>  Add Scorecard</a>}
                                                        </div>


                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>

                                                                    <th>Created by</th>
                                                                    <th>Updated by</th>
                                                                    <th>Comment</th>
                                                                    <th>Overall rating</th>
                                                                    <th>Last updated</th>
                                                                    <th className="Action">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {apiData.scoreData != null && apiData.scoreData.map((scoredlopp, key) => {
                                                                    return <tr key={key}>
                                                                        <td> {scoredlopp.created_by_name} </td>
                                                                        <td> {scoredlopp.updated_by_name} </td>
                                                                        <td> {scoredlopp.comment} </td>
                                                                        <td>

                                                                            <div className="scorecard-switch scorecard-switch-list">



                                                                                {(() => {
                                                                                    if (scoredlopp.rate == '1') {
                                                                                        return <label><span className="scorecard-option poor"><span className="scorecard-name ng-binding">1</span></span></label>

                                                                                    } else if (scoredlopp.rate == '2') {
                                                                                        return <label>  <span className="scorecard-option poor"><span className="scorecard-name ng-binding">2</span></span></label>

                                                                                    }
                                                                                    else if (scoredlopp.rate == '3') {
                                                                                        return <label >  <span className="scorecard-option neutral">3</span></label>

                                                                                    }
                                                                                    else if (scoredlopp.rate == '4') {
                                                                                        return <label> <span className="scorecard-option good"> <span className="scorecard-name ng-binding">4</span></span></label>

                                                                                    }
                                                                                    else {
                                                                                        return <label><span className="scorecard-option good"><span className="scorecard-name ng-binding">5</span></span></label>
                                                                                    }
                                                                                })()}









                                                                            </div>




                                                                        </td>
                                                                        <td>{ scoredlopp.updated_date }</td>
                                                                        <td>{ (authCheck?.role_id == 1) ? <a href="#" onClick={() => editscorecardpopup(scoredlopp.id)}      ><i className="fas fa-edit"></i></a> : '' } </td>
                                                                    </tr>;
                                                                })}



                                                            </tbody>
                                                        </table>







                                                    </div>
                                                </div>
                                            </div>}

                                            <div className="tab-pane fade" id="vert-tabs-additional-documents" role="tabpanel" aria-labelledby="vert-tabs-additional-documents-tab">
                                                <div className="card card-secondary card-outline">
                                                    <div className="card-header with-border clearfix">
                                                        <div className="card-title"><i className="fas fa-star"></i> Additional Documents</div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="float-right">
                                                            {(authCheck?.role_id != 4) && <a className="btn btn-primary  mb-2" onClick={additionalDocumentsShow} ><i className="fas fa-edit"></i>  Upload Documents</a>}
                                                        </div>
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>

                                                                    <th>Document Name</th>
                                                                    <th>Document</th>
                                                                    <th>Last updated</th>
                                                                    {(authCheck?.role_id != 4) && <th className="Action">Action</th>}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {apiData.additionalDoc != null && apiData.additionalDoc.map((scoredlopp, key) => {
                                                                    return <tr key={key}>
                                                                        <td> {scoredlopp.title} </td>
                                                                        <td> 
                                                                            {scoredlopp.additonal_document != "" &&
                                                                                <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(scoredlopp.additonal_document)} >
                                                                                    <i className="fas fa-download"></i>Download file</a>
                                                                                </div>
                                                                            } </td>
                                                                        <td>{ scoredlopp.updated_date }</td>
                                                                        {(authCheck?.role_id != 4) && <td>{ (authCheck?.role_id == 1 || authCheck?.role_id == 2) ? <a href="#" onClick={() => editaddtionalDocPopup(scoredlopp.id)}      ><i className="fas fa-edit"></i></a> : '' } </td>}
                                                                    </tr>;
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            {(authCheck?.role_id != 4) && <div className="tab-pane fade" id="vert-tabs-email" role="tabpanel" aria-labelledby="vert-tabs-email-tab">
                                                <div className="card card-secondary card-outline">
                                                    <div className="card-header with-border clearfix">
                                                        <div className='row'>
                                                            <div className='col-sm-1 ml-2 m-0 p-0 mt-2'>
                                                                <label className='form-label'>Please select</label>
                                                            </div>
                                                            <div className='col-sm-4'>
                                                                <select
                                                                    className="form-control"
                                                                    value={selectedValue2}
                                                                    onChange={handleChange}
                                                                    >
                                                                        <option value={4}>Attend Orientation</option>
                                                                        <option value={13}>Background Check</option>
                                                                        <option value={5}>Offer Notice</option>
                                                                        <option value={2}>Tax Forms & Direct Deposit</option>
                                                                        <option value={7}>Termination</option>
                                                                        <option value={8}>Pay Increase</option>
                                                                        <option value={78}>Exit Survey</option>
     { Array.isArray(postionData)  &&  (postionData.includes("2") ||  postionData.includes("1") )&&                                                 
                                                                     
                                                                        <option value={79}>Policy Review/Warning Notice</option>}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                        <label className='form-label' id='labelName'>{emailLabelText}</label>
                                                        <div className="form-group">
                                                            <label>Subject</label>
                                                            <input className="form-control text-box single-line" type="text"  {...register("offerSubject", { required: "Please enter legal name." })} />
                                                            {errors2.offerSubject?.message && (
                                                                <p className='text-danger'>{errors2.offerSubject.message}</p>
                                                            )}

                                                        </div>
                                                        <Controller
                                                        name="offer_email_content"
                                                        control={control}
                                                        // defaultValue= // Set initial content here
                                                        render={({ field }) => (
                                                            <ReactQuill
                                                            {...field}
                                                            modules={{ toolbar: true }}
                                                            onChange={(value) => {
                                                                field.onChange(value); // Update the form data with the editor content
                                                            }}
                                                            />
                                                        )}
                                                        />
                                                        <button type="button" className='btn btn-primary mt-2' disabled={isEmailButtonDisabled} onClick={handleSubmit(onSubmitOfferEmail)}>Send Email</button>
                                                        <br></br>
                                                        </div>
                                                        <hr></hr>
                                                        <div>
                                                            <label className='form-label'>Email Log</label>
                                                            <table className="table table-striped dataTable dtr-inline data-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Subject</th>
                                                                        <th>Sent By</th>
                                                                        <th style={{ width: '150px' }}>Sent On</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {apiData?.emailLog && apiData.emailLog.length > 0 ? (
                                                                        apiData.emailLog.slice(offset, offset + perPage).map((email, key) => (
                                                                        <React.Fragment key={key}>
                                                                            <tr>
                                                                            <td>{email.subject}</td>
                                                                            <td>{email.user_name}</td>
                                                                            <td>{email.updated_date}</td>
                                                                            <td>
                                                                                <a
                                                                                type="button"
                                                                                style={{ textDecoration: 'underline' }}
                                                                                onClick={() => toggleExpand(email.email_id)}
                                                                                >
                                                                                {expandedEmails[email.email_id] ? 'View less' : 'View more'}
                                                                                </a>
                                                                            </td>
                                                                            </tr>
                                                                            {expandedEmails[email.email_id] ? (
                                                                            <tr>
                                                                                <td colSpan="4">
                                                                                <div dangerouslySetInnerHTML={{ __html: email.mail_body }} />
                                                                                </td>
                                                                            </tr>
                                                                            ) : null}
                                                                        </React.Fragment>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                        <td colSpan="4">No data available</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>

                                                            </table>

                                                            <div className="pagination-container">
                                                                <ReactPaginate
                                                                    previousLabel={''}
                                                                    nextLabel={''}
                                                                    breakLabel={'...'}
                                                                    pageCount={pageCount}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handlePageClick}
                                                                    containerClassName={'pagination'}
                                                                    activeClassName={'active'}
                                                                />
                                                            </div>
                                                        {/* <label className='form-label'>Contract Email</label>
                                                        <Controller
                                                        name="contract_email_content"
                                                        control={control}
                                                        // defaultValue={} // Set initial content here
                                                        render={({ field }) => (
                                                            <ReactQuill
                                                            {...field}
                                                            modules={{ toolbar: true }}
                                                            onChange={(value) => {
                                                                field.onChange(value); // Update the form data with the editor content
                                                            }}
                                                            />
                                                        )}
                                                        />
                                                        {apiData?.contract_mail_sent != 1 &&
                                                            <button type="button" className='btn btn-primary mt-2' onClick={handleSubmit(onSubmitContractEmail)}>Send Contract Email</button>
                                                        } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                            {(authCheck?.role_id != 4) && <div className="tab-pane fade" id="vert-tabs-contract" role="tabpanel" aria-labelledby="vert-tabs-contract-tab">
                                                <div className="card card-secondary card-outline">
                                                    <div className="card-header with-border clearfix">
                                                        <div className='row'>
                                                            <div className='col-sm-1 ml-2 m-0 p-0 mt-2'>
                                                                <label className='form-label'>Please select</label>
                                                            </div>
                                                            <div className='col-sm-4'>
                                                                <select
                                                                    className="form-control"
                                                                    value={selectedValue}
                                                                    onChange={handleChangePosition}
                                                                    >
                                                                        <option value={0}>Select Position</option>
                                                                    {dynamicField?.position_field_2 && dynamicField.position_field_2.map((pf, key) => {        
                                                                        return <option value={pf.id}>{pf.name}</option>
                                                                    })} 
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                        <label className='form-label' id='labelName'>Contract details</label>
                                                        <Controller
                                                        name="contract_email_content"
                                                        control={control}
                                                        // defaultValue= // Set initial content here
                                                        render={({ field }) => (
                                                            <ReactQuill
                                                            {...field}
                                                            modules={{ toolbar: true }}
                                                            onChange={(value) => {
                                                                field.onChange(value); // Update the form data with the editor content
                                                            }}
                                                            />
                                                        )}
                                                        />
                                                        <br></br>
                                                        </div>
                                                        <hr></hr>
                                                        <div>
                                                        {isShowContract && (
                                                            <div>
                                                                <label className="form-label">Second Contract</label>
                                                                <Controller
                                                                    name="contract_email_content_2"
                                                                    control={control}
                                                                    // defaultValue={} // Set initial content here
                                                                    render={({ field }) => (
                                                                        <ReactQuill
                                                                            {...field}
                                                                            modules={{ toolbar: true }}
                                                                            onChange={(value) => {
                                                                                field.onChange(value); // Update the form data with the editor content
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        )}

                                                        <hr></hr>
                                                        <div>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label>Subject</label>
                                                                    <input className="form-control text-box single-line" type="text"  {...register("contractSubject", { required: "Please enter legal name." })} />
                                                                    {errors2.contractSubject?.message && (
                                                                        <p className='text-danger'>{errors2.contractSubject.message}</p>
                                                                    )}

                                                                </div>
                                                                
                                                                <label className="form-label">Contract Email</label>
                                                                <Controller
                                                                    name="contract_email_message"
                                                                    control={control}
                                                                    // defaultValue={} // Set initial content here
                                                                    render={({ field }) => (
                                                                        <ReactQuill
                                                                            {...field}
                                                                            modules={{ toolbar: true }}
                                                                            onChange={(value) => {
                                                                                field.onChange(value); // Update the form data with the editor content
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        {apiData?.contract_mail_sent != 1 &&
                                                            <button type="button" className='btn btn-primary mt-4' disabled={isContractButtonDisabled} onClick={handleSubmit(onSubmitContractEmail)}>Send Contract Email</button>
                                                        }

                                                        <hr></hr>
                                                        <div>
                                                            <label className="form-label">Documents List</label>
                                                            <table className="table table-striped dataTable dtr-inline data-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr.</th>
                                                                        <th>Subject</th>
                                                                        <th>Sent By</th>
                                                                        <th>Status</th>
                                                                        <th>Sent On</th>
                                                                        <th>Accepted On</th>
                                                                        <th>Contract</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {apiData?.allContractDeatil && apiData.allContractDeatil.length > 0 ? (
                                                                        apiData.allContractDeatil.slice(offset, offset + perPage).map((email, key) => (
                                                                        <React.Fragment key={key}>
                                                                            <tr>
                                                                            <td>{key + 1}</td>
                                                                            <td>{email.contract_subject}</td>
                                                                            <td>{email.user_name}</td>
                                                                            <td>{email.status == 1 ? 'Accepted' : 'Pending'}</td>
                                                                            <td>{email.created_date}</td>
                                                                            <td>{email.status == 1 ? email.updated_date : '-'}</td>
                                                                            <td>
                                                                                <a
                                                                                type="button"
                                                                                style={{ textDecoration: 'underline' }}
                                                                                onClick={() => toggleExpand2(email.id)}
                                                                                >
                                                                                {expandedContract[email.id] ? 'Less' : 'View Contract'}
                                                                                </a>
                                                                            </td>
                                                                            </tr>
                                                                            {expandedContract[email.id] ? (
                                                                            <tr>
                                                                                <td colSpan="7" align='center'>
                                                                                <div dangerouslySetInnerHTML={{ __html: email.contract_text }} />
                                                                                {email.status === 1 && (
                                                                                    <button
                                                                                    type="button"
                                                                                    className='btn btn-primary'
                                                                                    onClick={() => printContent(email.contract_text, email.id)}
                                                                                    >
                                                                                    Print
                                                                                    </button>
                                                                                )}
                                                                                </td>
                                                                            </tr>
                                                                            ) : null}
                                                                        </React.Fragment>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                        <td colSpan="7">No data available</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>

                                                            </table>
                                                            <div className="pagination-container">
                                                                <ReactPaginate
                                                                    previousLabel={''}
                                                                    nextLabel={''}
                                                                    breakLabel={'...'}
                                                                    pageCount={pageCount2}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handlePageClick2}
                                                                    containerClassName={'pagination'}
                                                                    activeClassName={'active'}
                                                                />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>
}

{(authCheck?.role_id == 3) &&  <div className="tab-content" id="vert-tabs-tabContent">

<div className="card card-secondary card-outline" data-card-name="background" id="background">
    <div className="card-header with-border clearfix">
        <div className="card-title"><i className="fas fa-coins"></i> Resume</div>
    </div>
    <div className="card-body">
        <div className="form-group row">
            <div className="col-md-2">
                <div className="label-wrapper"><label className="col-form-label p-3">Resume</label></div>
            </div>
            <div className="col-md-9">
                <div className="raw">
                    {apiData?.resume != "" &&
                    <div className="float-left my-1"><a className="btn btn-default" onClick={() => common.axiosDownloadFile(apiData.resume)} >
                        <i className="fas fa-download"></i>Download file</a>
                    </div>
                }
                </div>
            </div>
        </div>
 
    </div>
</div>

    

 
    </div>
}


                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card card-secondary card-outline candidate-fields">
                                    <div className="card-header with-border clearfix">
                                        <div className="card-title">Details</div>

                                    </div>
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">First Name <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text"   {...register("first_name", { required: "Please enter first name." })} />
                                                {errors.first_name?.message && (
                                                    <p className='text-danger'>{errors.first_name.message}</p>
                                                )}


                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Last Name <span className="required" >*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text" {...register("last_name", { required: "Please enter last name." })} />
                                                {errors.last_name?.message && (
                                                    <p className='text-danger'>{errors.last_name.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Phone <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text"

                                                    {...register("phone", {
                                                        required: {
                                                            value: true,
                                                            message: "Please enter cell phone number."
                                                        },
                                                        pattern: {
                                                            value: /^[0-9+-]+$/,
                                                            message: "This is not a valid cell phone number."
                                                        },
                                                        minLength: {
                                                            value: 10,
                                                            message: "Please enter valid cell phone number."
                                                        },
                                                        maxLength: {
                                                            value: 12,
                                                            message: "Please enter valid cell phone number."
                                                        }
                                                    })}

                                                />
                                                {errors.phone?.message && (
                                                    <p className='text-danger'>{errors.phone.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Email <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text"

                                                    {...register("email", {
                                                        required: "Please enter email address.",
                                                        validate: {
                                                            maxLength: (v) =>
                                                                v.length <= 50 || "The email should have at most 50 characters",
                                                            matchPattern: (v) =>
                                                                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                                "Please enter a valid email address",
                                                        },
                                                    })}


                                                />

                                                {errors.email?.message && (
                                                    <p className='text-danger'>{errors.email.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Password </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <input className="form-control text-box single-line password" type="password" {...register("password")} />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Street Address <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text" {...register("address", { required: "Please enter street address." })} />
                                                {errors.address?.message && (
                                                    <p className='text-danger'>{errors.address.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Street Address Line 2</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text" {...register("address_2")} />
                                                {errors.address_2?.message && (
                                                    <p className='text-danger'>{errors.address_2.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">City <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text"  {...register("city", { required: "Please enter city." })} />
                                                {errors.city?.message && (
                                                    <p className='text-danger'>{errors.city.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">State / Province <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text" {...register("state", { required: "Please enter state." })} />
                                                {errors.state?.message && (
                                                    <p className='text-danger'>{errors.state.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Postal / Zip Code <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text"  {...register("zipcode", {
                                                    required: {
                                                        value: true,
                                                        message: "Please enter zip code."
                                                    },
                                                    pattern: {
                                                        value: /^[0-9+-]+$/,
                                                        message: "This is not a valid zip code."
                                                    },
                                                    minLength: {
                                                        value: 5,
                                                        message: "Please enter valid zip code."
                                                    },
                                                    maxLength: {
                                                        value: 5,
                                                        message: "Please enter valid zip code."
                                                    }
                                                })} />

                                                {errors.zipcode?.message && (
                                                    <p className='text-danger'>{errors.zipcode.message}</p>
                                                )}

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">County  </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text"  {...register("county")} />
                                                {errors.county?.message && (
                                                    <p className='text-danger'>{errors.county.message}</p>
                                                )}


                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label p-0">Are you legally entitled to work in the U.S.? <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="raw">
                                                    <div className="form-check-inline">
                                                        <label className="form-check-label" > <input type="radio" className="form-check-input" name="legally_entitled" id="legally_entitled_Yes" value={1}  {...register("legally_entitled_us", { required: "Please select legally entitled." })} />
                                                            Yes</label>
                                                    </div>
                                                    <div className="form-check-inline">
                                                        <label className="form-check-label" ><input type="radio" className="form-check-input" name="legally_entitled" id="legally_entitled_No" value={0} {...register("legally_entitled_us", { required: "Please select legally entitled." })} />
                                                            No</label>
                                                    </div>
                                                </div>

                                                {errors.legally_entitled_us?.message && (
                                                    <p className='text-danger'>{errors.legally_entitled_us.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Rate (Hourly)  </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                {authCheck?.role_id == 4 && 
                                                <input className="form-control text-box single-line" type="text"  {...register("rate")}  readOnly />
                                                }
                                                {authCheck?.role_id != 4 && 
                                                <input className="form-control text-box single-line" type="text"  {...register("rate")}  />
                                                }
                                                {errors.rate?.message && (
                                                    <p className='text-danger'>{errors.rate.message}</p>
                                                )}


                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Additional Languages Spoken  </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text"  {...register("additional_languages_spoken")} />
                                                {errors.additional_languages_spoken?.message && (
                                                    <p className='text-danger'>{errors.additional_languages_spoken.message}</p>
                                                )}

                                            </div>
                                        </div>


                                        {(authCheck?.role_id != 4) && <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">BT Skill Rating </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="form-control text-box single-line" type="text" {...register("bt_skill_rating")} />

                                                {errors.bt_skill_rating?.message && (
                                                    <p className='text-danger'>{errors.bt_skill_rating.message}</p>
                                                )}

                                            </div>
                                        </div>}

                                        {(authCheck?.role_id != 4) && <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Assigned to</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">

                                                <select className="form-control"  {...register('assign_to')}>
                                                    <option value="0">Please Select</option>




                                                    {dynamicField.hr_list_field && dynamicField.hr_list_field.map((hr, key) => {

                                                        return <option key={key} value={hr.id}>{hr.first_name} {hr.last_name}</option>


                                                    })}


                                                    {errors.assign_to?.message && (
                                                        <p className='text-danger'>{errors.assign_to.message}</p>
                                                    )}
                                                </select>
                                            </div>
                                        </div>}




                                        {(authCheck?.role_id != 4) && <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Special Note </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <textarea rows="5" className="form-control textarea" {...register("special_note")}></textarea>
                                                {errors.special_note?.message && (
                                                    <p className='text-danger'>{errors.special_note.message}</p>
                                                )}

                                            </div>
                                        </div>
                                        }
                                        {(authCheck?.role_id != 4) && <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Termination reason </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <textarea rows="5" maxLength={255} className="form-control textarea" {...register("termination_reason")}></textarea>
                                                {errors.termination_reason?.message && (
                                                    <p className='text-danger'>{errors.termination_reason.message}</p>
                                                )}

                                            </div>
                                        </div>
                                        }
                                        {(authCheck?.role_id != 4) && <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Final Paycheck Date</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                            <Controller
                                                control={control}
                                                name='final_paycheck'
                                                // rules={{ required: "Please select final paycheck date" }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        className="form-control text-box single-line datetimepicker-input"
                                                        placeholderText='Select date'
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        minDate={new Date()}
                                                        dateFormat="MM/dd/yyyy"

                                                    />


                                                )}
                                            />
                                            {errors.final_paycheck?.message && (
                                                <p className='text-danger'>{errors.final_paycheck.message}</p>
                                            )}

                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </form>

              { childAbuseData &&
              <>
            
          <ChildAbuseFile childupdateDataval={childupdateDataval} setchildupdateDataval={setchildupdateDataval} childformDataval={childformDataval} ddshow={childAbuseData} onClose={handleCloseModal} setchildformDataval={setchildformDataval} />
          </>
              } 
              
        {         childAbusesecData && 
            <>
          <ChildAbusesecFile  childjercyupdateDataval={childjercyupdateDataval} setchildjercyupdateDataval={setchildjercyupdateDataval} formDataval={formDataval} ddshow={childAbusesecData} onClose={handleClosesecModal} setformDataval={setformDataval} />
          </> }

                
              

                <Modal show={ddshow} onHide={ddhandleClose} dialogClassName="custom-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>Deposit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form key={2} onSubmit={handleSubmit2(saveDirectDeposit)} id="directdeposit_candidate">
                            <div className="row">
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Legal Name(No Nicknames)<span className="required">*</span></label>
                                        <input className="form-control text-box single-line" type="text"  {...register2("legal_name", { required: "Please enter legal name." })} />
                                        {errors2.legal_name?.message && (
                                            <p className='text-danger'>{errors2.legal_name.message}</p>
                                        )}

                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Date of Birth <span className="required">*</span></label>
                                        <Controller
    control={control2}
    name='date_of_birth'
    rules={{ required: "Please select date of birth" }}
    render={({ field }) => (
      <DatePicker
      className="form-control text-box single-line"  
        placeholderText='Select date'
        onChange={(date) => field.onChange(date)}
        selected={field.value}
        maxDate={new Date()}
        dateFormat="MM/dd/yyyy"
      
      />
 

 
   )}
  />
                                        {errors2.date_of_birth?.message && (
                                            <p className='text-danger'>{errors2.date_of_birth.message}</p>
                                        )}

                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Your Address (with zip code)<span className="required">*</span></label>
                                        <label> Ex: 227 Payday Avenue, Perth Amboy, NJ 07077</label>
                                        <input className="form-control text-box single-line" type="text"  {...register2("your_address", { required: "Please enter your address." })} />
                                        {errors2.your_address?.message && (
                                            <p className='text-danger'>{errors2.your_address.message}</p>
                                        )}

                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                    <label>Your Email Address<span className="required">*</span></label><br></br>
                                        <label>(janiejones@gmail.com)</label>
                                        <input className="form-control text-box single-line" type="text"  {...register2("your_email_address", {
                                        required: "Please enter your email address.",
                                        validate: {
                                          maxLength: (v) =>
                                            v.length <= 50 || "The email should have at most 50 characters",
                                          matchPattern: (v) =>
                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                            "Please enter a valid email address",
                                        },
                                      })} />
                                        {errors2.your_email_address?.message && (
                                            <p className='text-danger'>{errors2.your_email_address.message}</p>
                                        )}

                                    </div>
                                </div>



                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Tax ID or SSN (Please enter your social security or EIN.)<span className="required">*</span></label>
                                        <input className="form-control text-box single-line" type="text"    {...register2("tax_id", {
                                            required: {
                                                value: true,
                                                message: "Please enter Tax ID or SSN."
                                            },
                                            minLength: {
                                                value: 9,
                                                message: "Please enter valid Tax ID or SSN."
                                            },
                                            maxLength: {
                                                value: 12,
                                                message: "Please enter valid Tax ID or SSN."
                                            }
                                        })} />
                                        {errors2.tax_id?.message && (
                                            <p className='text-danger'>{errors2.tax_id.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Operating As <span className="required">*</span></label>
                                        <select className="form-control"  {...register2("operating_as", { required: "Please select operating as." })}>
                                            <option value="">Please Select</option>
                                            <option value={1}>An Individual</option>
                                            <option value={2}>A Business</option>
                                        </select>
                                        {errors2.operating_as?.message && (
                                            <p className='text-danger'>{errors2.operating_as.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Pay to Name (Address associated with Pay To name on bank account)<span className="required">*</span></label>
                                        <input className="form-control text-box single-line" type="text"  {...register2("pay_to_name", { required: "Please enter pay to name." })} />
                                        {errors2.pay_to_name?.message && (
                                            <p className='text-danger'>{errors2.pay_to_name.message}</p>
                                        )}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Bank Name <span className="required">*</span></label>
                                        <input className="form-control text-box single-line" type="text" {...register2("bank_name", { required: "Please enter bank name." })} />
                                        {errors2.bank_name?.message && (
                                            <p className='text-danger'>{errors2.bank_name.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Bank Routing Number <span className="required">*</span></label>
                                        <input className="form-control text-box single-line" type="text"   {...register2("bank_routing_number", {
                                            required: {
                                                value: true,
                                                message: "Please enter bank routing number."
                                            },
                                            minLength: {
                                                value: 5,
                                                message: "Please enter  valid bank routing number."
                                            },
                                            maxLength: {
                                                value: 12,
                                                message: "Please enter  valid bank routing number."
                                            }
                                        })} />
                                        {errors2.bank_routing_number?.message && (
                                            <p className='text-danger'>{errors2.bank_routing_number.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Routing Number <span className="required">*</span></label>
                                        <input className="form-control text-box single-line" type="text"    {...register2("confirm_bank_routing_number", {
                                            required: {
                                                value: true,
                                                message: "Please confirm enter bank routing number."
                                            },
                                            validate: (match) => {
                                                const bank_routing_number = getValues2("bank_routing_number")
                                                return match === bank_routing_number || "Bank routing number should match!"
                                            },
                                            minLength: {
                                                value: 5,
                                                message: "Please enter valid bank routing number."
                                            },
                                            maxLength: {
                                                value: 12,
                                                message: "Please enter  valid bank routing number."
                                            }
                                        })} />
                                        {errors2.confirm_bank_routing_number?.message && (
                                            <p className='text-danger'>{errors2.confirm_bank_routing_number.message}</p>
                                        )}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Bank Account Number <span className="required">*</span></label>
                                        <input className="form-control text-box single-line" type="text"  {...register2("new_bank_account_number", {

                                            validate: {
                                                required: value => {
                                                    if (!apiData?.candidateDeposit?.[0]?.bank_account_number && value == "") return 'Please enter bank account number.';
                                                    return true;
                                                },
                                            },
                                            minLength: {
                                                value: 5,
                                                message: "Please enter valid bank account number."
                                            },
                                            maxLength: {
                                                value: 12,
                                                message: "Please enter valid bank account number."
                                            }
                                        })} />
                                        {apiData?.candidateDeposit?.[0]?.bank_account_number && <span>Current Account: {apiData?.candidateDeposit[0]?.bank_account_number}</span>}
                                        {errors2.new_bank_account_number?.message && (
                                            <p className='text-danger'>{errors2.new_bank_account_number.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Account Number <span className="required">*</span></label>
                                        <input className="form-control text-box single-line" type="text"   {...register2("confirm_new_bank_account_number", {

                                            validate: {

                                                required: value => {


                                                    if (!apiData?.candidateDeposit?.[0]?.bank_account_number && value == "") return 'Please enter bank account number.';
                                                    const new_bank_account_number = getValues2("new_bank_account_number")
                                                    return value === new_bank_account_number || "Bank account number should match!"


                                                }



                                            },
                                            minLength: {
                                                value: 5,
                                                message: "Please enter valid bank account number."
                                            },
                                            maxLength: {
                                                value: 12,
                                                message: "Please enter  valid bank account number."
                                            }
                                        })} />

                                        {errors2.confirm_new_bank_account_number?.message && (
                                            <p className='text-danger'>{errors2.confirm_new_bank_account_number.message}</p>
                                        )}


                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Type of Account <span className="required">*</span></label>
                                        <select className="form-control" {...register2("account_type", { required: "Please select account type." })}>
                                            <option value="">Please Select</option>
                                            <option value={1}>Savings</option>
                                            <option value={2}>Checking</option>
                                        </select>
                                        {errors2.account_type?.message && (
                                            <p className='text-danger'>{errors2.account_type.message}</p>
                                        )}

                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        By checking this box, I attest to the accuracy of the information entered about the business or individual entity - both Tax ID (SSN/EIN) and banking information. I also agree to allow Progressive Option Support Services, LLC and it's program affiliates to deposit funds owed to me for service delivery in the account that I have identified. I also hereby agree to send a voided check to Progressive Option Support Services, LLC prior to payroll being processed.
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" value={1}  {...register2('accept_terms', { required: "Please accept above statement." })} />
                                            <label className="form-check-label">Accept</label>
                                        </div>
                                        
                                        {errors2.accept_terms?.message && (
                                            <p className='text-danger'>{errors2.accept_terms.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Electronically Signed <span className="required">*</span></label>                           
                                        <Controller
                                        name="electronically_signed_new"
                                        control={control2}
                                        rules={{
                                            validate: (value) => { 

                                                console.log(apiData?.candidateDeposit?.[0]?.electronically_signed)

                                            if (apiData?.candidateDeposit?.[0]?.electronically_signed == undefined && value == undefined) 
                                            {

                                            // console.log('dddd');
                                            // console.log(apiData?.candidateDeposit?.[0]?.electronically_signed);
                                                return 'Please write a signature.';          

                                            }
                                            
                                            
                                            }
                                        }}
                                        render={({ field }) => (
                                            <SignatureCanvas
                                            ref={depositsigRef}
                                            onEnd={() => field.onChange(depositformatIntoPng())}
                                            penColor="black"
                                            canvasProps={{
                                                width: 315,
                                                height: 200,
                                                style: { border: "1px solid black" },
                                            }}
                                            />
                                        )}
                                        />
    

                                        {errors2.electronically_signed_new?.message && (
                                            <p className='text-danger'>{errors2.electronically_signed_new.message}</p>
                                        )}
                                        <p><a onClick={depositclearSignature} className='btn btn-default'>Clear</a> </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {apiData?.candidateDeposit?.[0]?.electronically_signed && <img src={apiData?.candidateDeposit?.[0]?.electronically_signed}></img>}
                                </div>

                            </div>
                        </form>


                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" form='directdeposit_candidate'>Save</button>
                        <span className="btn btn-default" onClick={ddhandleClose}>Cancel</span>

                    </Modal.Footer>
                </Modal>


                <Modal show={i9show} onHide={i9handleClose} dialogClassName="custom-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>I-9 Eligibility Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-i9">
                            <h6><b>Employment Eligibility Verification</b></h6>
                            <p>Department of Homeland Security <br />U.S. Citizenship and Immigration Services</p>
                            <hr />
                            <p>START HERE: Read instructions carefully before completing this form. The instructions must be available, either in paper or electronically, during completion of this form. Employers are liable for errors in the completion of this form.</p>
                            <p>ANTI-DISCRIMINATION NOTICE: It is illegal to discriminate against work-authorized individuals. Employers CANNOT specify which document(s) an employee may present to establish employment authorization and identity. The refusal to hire or continue to employ an individual because the documentation presented has a future expiration date may also constitute illegal discrimination.</p>
                            <p>Section 1. Employee Information and Attestation (Employees must complete and sign Section 1 of Form I-9 no later than the first day of employment, but not before accepting a job offer.)s</p>

                            <form key={3} onSubmit={handleSubmit3(saveI9)} id="i9_candidate">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date of Birth <span className="required">*</span></label><br></br>
                                            <Controller
                                                control={control3}
                                                name='date_of_birth'
                                                rules={{ required: "Please select date of birth" }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        className="form-control text-box single-line datetimepicker-input"
                                                        placeholderText='Select date'
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        maxDate={new Date()}
                                                        dateFormat="MM/dd/yyyy"

                                                    />


                                                )}
                                            />
                                            {errors3.date_of_birth?.message && (
                                                <p className='text-danger'>{errors3.date_of_birth.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>U.S. Social Security Number <span className="required">*</span></label>
                                            <input className="form-control text-box single-line" type="text"  {...register3('social_security_number', { required: "Please enter social security number." })} />
                                            {errors3.social_security_number?.message && (
                                                <p className='text-danger'>{errors3.social_security_number.message}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <p>I am aware that federal law provides for imprisonment and/or fines for false statements or use of false documents in connection with the completion of this form.</p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>I attest, under penalty of perjury, that I am (check one of the following boxes):</label>
                                            <div className="raw">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" value={1}  {...register3('citizen')} />
                                                    <label className="form-check-label">A citizen of the United States</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" value={1} {...register3('noncitizen')} />
                                                    <label className="form-check-label">A noncitizen national of the United States (See instructions)</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" value={1}  {...register3('lawful_permanent_resident')} />
                                                    <label className="form-check-label">A lawful permanent resident (Alien Registration Number/USCIS Number):</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" value={1}  {...register3('alien_authorized_work')} />
                                                    <label className="form-check-label">An alien authorized to work until (expiration date, if applicable, mm/dd/yyyy)</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>Aliens authorized to work must provide only one of the following document numbers to complete Form I-9:</p>
                                <p>An Alien Registration Number/USCIS Number OR Form I-94 Admission Number OR Foreign Passport Number.</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Alien Registration Number/USCIS Number</label>
                                            <input className="form-control text-box single-line" type="text" {...register3('uscis_number')} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-2">OR</div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Form I-94 Admission Number</label>
                                            <input className="form-control text-box single-line" type="text"  {...register3('admission_number')} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-2">OR</div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Foreign Passport Number</label>
                                            <input className="form-control text-box single-line" type="text" {...register3('passport_number')} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Country of Issuance</label>
                                            <input className="form-control text-box single-line" type="text"  {...register3('country_of_issuance')} />
                                        </div>
                                    </div>
                                </div>


                            </form>
                            <p>(Fields below must be completed and signed when preparers and/or translators assist an employee in completing Section 1.)</p>
                            <p>I attest, under penalty of perjury, that I have assisted in the completion of Section 1 of this form and that to the best of my knowledge the information is true and correct.</p>
                            <p>Section 2. Employer or Authorized Representative Review and Verification</p>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <iframe src="/USCIS-Form I-9(employer).pdf" style={{ border: "1px solid #ddd", width: 700, height: 500, maxWidth: 600, overflow: 'auto' }}></iframe>
                                    </div>
                                </div>
                            </div>

                            <p>LISTS OF ACCEPTABLE DOCUMENTS</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <iframe src="/USCIS-Form-I-9..pdf" style={{ border: "1px solid #ddd", width: 700, height: 500, maxWidth: 600, overflow: 'auto' }}></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" form='i9_candidate'>Save</button>
                        <span className="btn btn-default" onClick={i9handleClose}>Cancel</span>

                    </Modal.Footer>
                </Modal>
              

{/*  modal form for IIC Evaluation form*/}

  
                <IICPerformanceModal
                    show={iicShow}
                    handleClose={iichandleClose}
                    handleSubmit={handleSubmit10(saveEvalform)}
                    getValues10={getValues10}
                    register10={register10}
                    control10={control10}
                    errors10={errors10}
                />       

                <Modal show={w4show} onHide={w4handleClose} dialogClassName="custom-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>Federal W-4 Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="form-w4">
                            <h6><b>Employee's Withholding Certificate</b></h6>
                            <p>Form W4: Department of the Treasury Internal Revenue Service</p><hr />
                            <p>OMB No. 1545-0074</p>
                            <p>Complete Form W-4 so that your employer can withhold the correct federal income tax from your pay.</p>
                            <p>Give Form W-4 to your employer.</p>
                            <p>Your withholding is subject to review by the IRS.</p>


                            <form key={4} onSubmit={handleSubmit4(saveW4)} id="w4_candidate">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Social security number <span className="required">*</span></label>
                                            <input className="form-control text-box single-line" type="text" {...register4('social_security_number', { required: "Please enter social security number." })} />

                                            {errors4.social_security_number?.message && (
                                                <p className='text-danger'>{errors4.social_security_number.message}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <p>Does your name match the name on your social security card? If not, to ensure you get credit for your earnings, contact SSA at 800-772-1213 or go to www.ssa.gov.</p>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>I attest, under penalty of perjury, that I am (check one of the following boxes):</label>
                                            <div className="raw">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" value={1} {...register4('single_or_married')} />
                                                    <label className="form-check-label">Single or Married filing separately</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" value={2} {...register4('single_or_married')} />
                                                    <label className="form-check-label">Married filing jointly or Qualifying widow(er)</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" value={3} {...register4('single_or_married')} />
                                                    <label className="form-check-label">Head of household (Check only if you're unmarried and pay more than half the costs of keeping up a home for yourself and a qualifying individual.)</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>Complete Steps 2–4 ONLY if they apply to you; otherwise, skip to Step 5. See page 2 for more information on each step, who can claim exemption from withholding, when to use the estimator at www.irs.gov/W4App, and privacy.</p>
                                <p><b>Step 2: Multiple Jobs or Spouse Works</b></p>
                                <p>Complete this step if you (1) hold more than one job at a time, or (2) are married filing jointly and your spouse also works. The correct amount of withholding depends on income earned from all of these jobs.</p>
                                <p>Do only one of the following.<br />
                                    (a) Use the estimator at www.irs.gov/W4App for most accurate withholding for this step (and Steps 3–4); or<br />
                                    (b) Use the Multiple Jobs Worksheet on page 3 and enter the result in Step 4(c) below for roughly accurate withholding; or<br />
                                    (c) If there are only two jobs total, you may check this box. Do the same on Form W-4 for the other job. This option is accurate for jobs with similar pay; otherwise, more tax than necessary may be withheld . .</p>
                                <p>TIP: To be accurate, submit a 2022 Form W-4 for all other jobs. If you (or your spouse) have self-employment income, including as an independent contractor, use the estimator.</p>
                                <p>Complete Steps 3–4(b) on Form W-4 for only ONE of these jobs. Leave those steps blank for the other jobs. (Your withholding will be most accurate if you complete Steps 3–4(b) on the Form W-4 for the highest paying job.)</p>
                                <p><b>Step 3: Claim Dependents</b></p>
                                <p>If your total income will be $200,000 or less ($400,000 or less if married filing jointly):</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Multiply the number of qualifying children under age 17 by $2,000 ($)</label>
                                            <input className="form-control text-box single-line" type="text" {...register4("qualifying_children", {


                                                minLength: {
                                                    value: 1,
                                                    message: "Please enter valid  value."
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: "Please enter valid value."
                                                }
                                            })} />

                                            {errors4.qualifying_children?.message && (
                                                <p className='text-danger'>{errors4.qualifying_children.message}</p>
                                            )}

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Multiply the number of other dependents by $500 ($)</label>
                                            <input className="form-control text-box single-line" type="text" {...register4("other_dependents", {


                                                minLength: {
                                                    value: 1,
                                                    message: "Please enter valid  value."
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: "Please enter valid value."
                                                }
                                            })} />
                                            {errors4.other_dependents?.message && (
                                                <p className='text-danger'>{errors4.other_dependents.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Add the amounts above and enter the total here. ($)</label>
                                            <input className="form-control text-box single-line" type="text"  {...register4("total_amount", {


                                                minLength: {
                                                    value: 1,
                                                    message: "Please enter valid  value."
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: "Please enter valid value."
                                                }
                                            })} />
                                            {errors4.total_amount?.message && (
                                                <p className='text-danger'>{errors4.total_amount.message}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <p><b>Step 4 (optional): Other Adjustments</b></p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>(a) Other income (not from jobs).</label>
                                            <input className="form-control text-box single-line" type="text"   {...register4("other_income", {


                                                minLength: {
                                                    value: 1,
                                                    message: "Please enter valid  value."
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: "Please enter valid value."
                                                }
                                            })} />
                                            {errors4.other_income?.message && (
                                                <p className='text-danger'>{errors4.other_income.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>(b) Deductions.</label>
                                            <input className="form-control text-box single-line" type="text"  {...register4("deductions", {


                                                minLength: {
                                                    value: 1,
                                                    message: "Please enter valid  value."
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: "Please enter valid value."
                                                }
                                            })} />
                                            {errors4.deductions?.message && (
                                                <p className='text-danger'>{errors4.deductions.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>(c) Extra withholding.</label>
                                            <input className="form-control text-box single-line" type="text"  {...register4("extra_withholding", {


                                                minLength: {
                                                    value: 1,
                                                    message: "Please enter valid  value."
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: "Please enter valid value."
                                                }
                                            })} />
                                            {errors4.extra_withholding?.message && (
                                                <p className='text-danger'>{errors4.extra_withholding.message}</p>
                                            )}

                                        </div>
                                    </div>
                                </div>

                            </form>
                            <p><strong>Employers&nbsp;Only</strong></p>

                            <p><strong>Employer's name and address:</strong></p>
                            <p>Progressive Option Support Services</p>
                            <p>221 River St 9th Floor, Hoboken, NJ 07030</p>

                            <p><strong>Employer identification&nbsp;number (EIN):</strong></p>
                            <p>833454176</p>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>First date of employment: </label>
                                        <Controller
                                            control={control4}
                                            name='first_date_employment'
                                            rules={{ required: "Please select first date of employment" }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    className="form-control text-box single-line datetimepicker-input"
                                                    placeholderText='Select date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    maxDate={new Date()}
                                                    dateFormat="MM/dd/yyyy"

                                                />


                                            )}
                                        />
                                        {errors4.first_date_employment?.message && (
                                            <p className='text-danger'>{errors4.first_date_employment.message}</p>
                                        )}


                                    </div>
                                </div>
                            </div>
                            <h5><span><strong>General Instructions</strong></span></h5>
                            <p>Section references are to the Internal Revenue Code.</p>

                            <p><span><strong>Future Developments</strong></span><br />For the latest information about developments related to&nbsp;Form W-4, such as legislation enacted after it was published,&nbsp;go to <em>www.irs.gov/FormW4</em>.</p>

                            <p><span><strong>Purpose of Form</strong></span><br />Complete Form W-4 so that your employer can withhold the&nbsp;correct federal income tax from your pay. If too little is&nbsp;withheld, you will generally owe tax when you file your tax&nbsp;return and may owe a penalty. If too much is withheld, you&nbsp;will generally be due a refund. Complete a new Form W-4&nbsp;when changes to your personal or financial situation would&nbsp;change the entries on the form. For more information on&nbsp;withholding and when you must furnish a new Form W-4,<br />see Pub. 505, Tax Withholding and Estimated Tax.</p>

                            <p><strong>Exemption from withholding.</strong> You may claim exemption&nbsp;from withholding for 2022 if you meet both of the following&nbsp;conditions: you had no federal income tax liability in 2021&nbsp;<strong>and</strong> you expect to have no federal income tax liability in<br />2022. You had no federal income tax liability in 2021 if (1)&nbsp;your total tax on line 24 on your 2021 Form 1040 or 1040-SR&nbsp;is zero (or less than the sum of lines 27a, 28, 29, and 30), or&nbsp;(2) you were not required to file a return because your&nbsp;income was below the filing threshold for your correct filing<br />status. If you claim exemption, you will have no income tax&nbsp;withheld from your paycheck and may owe taxes and&nbsp;penalties when you file your 2022 tax return. To claim&nbsp;exemption from withholding, certify that you meet both of&nbsp;the conditions above by writing “Exempt” on Form W-4 in&nbsp;the space below Step 4(c). Then, complete Steps 1(a), 1(b),&nbsp;and 5. Do not complete any other steps. You will need to&nbsp;submit a new Form W-4 by February 15, 2023.</p>

                            <p><strong>Your privacy.</strong> If you prefer to limit information provided in&nbsp;Steps 2 through 4, use the online estimator, which will also&nbsp;increase accuracy.</p>
                            <p>As an alternative to the estimator: if you have concerns&nbsp;with Step 2(c), you may choose Step 2(b); if you have&nbsp;concerns with Step 4(a), you may enter an additional amount&nbsp;you want withheld per pay period in Step 4(c). If this is the<br />only job in your household, you may instead check the box&nbsp;in Step 2(c), which will increase your withholding and&nbsp;significantly reduce your paycheck (often by thousands of&nbsp;dollars over the year).</p>

                            <p><strong>When to use the estimator.</strong> Consider using the estimator at&nbsp;<em>www.irs.gov/W4App</em> if you:<br />1. Expect to work only part of the year;<br />2. Have dividend or capital gain income, or are subject to&nbsp;additional taxes, such as Additional Medicare Tax;<br />3. Have self-employment income (see below); or<br />4. Prefer the most accurate withholding for multiple job&nbsp;situations.</p>

                            <p><strong>Self-employment.</strong> Generally, you will owe both income and&nbsp;self-employment taxes on any self-employment income you&nbsp;receive separate from the wages you receive as an&nbsp;employee. If you want to pay these taxes through<br />withholding from your wages, use the estimator at&nbsp;<em>www.irs.gov/W4App</em> to figure the amount to have withheld.&nbsp;</p>
                            <p><strong>Nonresident alien.</strong> If you're a nonresident alien, see Notice&nbsp;1392, Supplemental Form W-4 Instructions for Nonresident&nbsp;Aliens, before completing this form.</p>
                            <h5><span><strong>Specific Instructions</strong></span></h5>

                            <p><strong>Step 1(c).</strong> Check your anticipated filing status. This will&nbsp;determine the standard deduction and tax rates used to&nbsp;compute your withholding.</p>
                            <p><strong>Step 2.</strong> Use this step if you (1) have more than one job at the&nbsp;same time, or (2) are married filing jointly and you and your&nbsp;spouse both work.</p>
                            <p>Option (<strong>a</strong>) most accurately calculates the additional tax&nbsp;you need to have withheld, while option (<strong>b</strong>) does so with a&nbsp;little less accuracy.</p>
                            <p>If you (and your spouse) have a total of only two jobs, you&nbsp;may instead check the box in option (<strong>c</strong>). The box must also&nbsp;be checked on the Form W-4 for the other job. If the box is&nbsp;checked, the standard deduction and tax brackets will be&nbsp;cut in half for each job to calculate withholding. This option&nbsp;is roughly accurate for jobs with similar pay; otherwise, more&nbsp;tax than necessary may be withheld, and this extra amount&nbsp;will be larger the greater the difference in pay is between the&nbsp;two jobs.</p>

                            <p><em><strong>CAUTION!&nbsp;</strong></em><strong>Multiple jobs.</strong> Complete Steps 3 through 4(b) on only&nbsp;one Form W-4. Withholding will be most accurate if&nbsp;you do this on the Form W-4 for the highest paying job.</p>

                            <p><strong>Step 3.</strong> This step provides instructions for determining the&nbsp;amount of the child tax credit and the credit for other&nbsp;dependents that you may be able to claim when you file your&nbsp;tax return. To qualify for the child tax credit, the child must<br />be under age 17 as of December 31, must be your&nbsp;dependent who generally lives with you for more than half&nbsp;the year, and must have the required social security number.&nbsp;You may be able to claim a credit for other dependents for<br />whom a child tax credit can't be claimed, such as an older&nbsp;child or a qualifying relative. For additional eligibility&nbsp;requirements for these credits, see Pub. 501, Dependents,&nbsp;Standard Deduction, and Filing Information. You can also&nbsp;include <strong>other tax credits</strong> for which you are eligible in this&nbsp;step, such as the foreign tax credit and the education tax&nbsp;credits. To do so, add an estimate of the amount for the year&nbsp;to your credits for dependents and enter the total amount in&nbsp;Step 3. Including these credits will increase your paycheck&nbsp;and reduce the amount of any refund you may receive when&nbsp;you file your tax return.</p>

                            <p><strong>Step 4 (optional).</strong></p>

                            <p><strong>Step 4(a)</strong>. Enter in this step the total of your other&nbsp;estimated income for the year, if any. You shouldn't include&nbsp;income from any jobs or self-employment. If you complete&nbsp;Step 4(a), you likely won't have to make estimated tax&nbsp;payments for that income. If you prefer to pay estimated tax&nbsp;rather than having tax on other income withheld from your&nbsp;paycheck, see Form 1040-ES, Estimated Tax for Individuals.&nbsp;</p>

                            <p><strong>Step 4(b).</strong> Enter in this step the amount from the&nbsp;Deductions Worksheet, line 5, if you expect to claim&nbsp;deductions other than the basic standard deduction on your&nbsp;2022 tax return and want to reduce your withholding to&nbsp;account for these deductions. This includes both itemized&nbsp;deductions and other deductions such as for student loan&nbsp;interest and IRAs.</p>

                            <p><strong>Step 4(c).</strong> Enter in this step any additional tax you want&nbsp;withheld from your pay each pay period, including any&nbsp;amounts from the Multiple Jobs Worksheet, line 4. Entering&nbsp;an amount here will reduce your paycheck and will either&nbsp;increase your refund or reduce any amount of tax that you&nbsp;owe.</p>
                            <p><strong><span>Step 2(b)—Multiple Jobs Worksheet</span>&nbsp;</strong>(Keep for your records.)</p>
                            <p>If you choose the option in Step 2(b) on Form W-4, complete this worksheet (which calculates the total extra tax for all jobs) on only ONE&nbsp;Form W-4. Withholding will be most accurate if you complete the worksheet and enter the result on the Form W-4 for the highest paying job.</p>

                            <p><strong>Note:</strong> If more than one job has annual wages of more than $120,000 or there are more than three jobs, see Pub. 505 for additional&nbsp;tables; or, you can use the online withholding estimator at <em>www.irs.gov/W4App</em>.</p>


                            <div className="row">


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>1. Two jobs.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('two_jobs')} />
                                        {errors4.two_jobs?.message && (
                                            <p className='text-danger'>{errors4.two_jobs.message}</p>
                                        )}
                                        <p><small>If you have two jobs or you’re married filing jointly and you and your spouse each have one job, find the amount from the appropriate table on page 4. Using the “Higher Paying Job” row and the “Lower Paying Job” column, find the value at the intersection of the two household salaries and enter that value on line 1. Then, skip to line 3 ($)</small></p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>2. Three jobs.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('three_jobs')} />
                                        {errors4.three_jobs?.message && (
                                            <p className='text-danger'>{errors4.three_jobs.message}</p>
                                        )}
                                        <p><small>If you and/or your spouse have three jobs at the same time, complete lines 2a, 2b, and2c below. Otherwise, skip to line 3.</small></p>
                                    </div>
                                </div>



                            </div>

                            <div className="row">


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>2a.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('two_a')} />
                                        {errors4.two_a?.message && (
                                            <p className='text-danger'>{errors4.two_a.message}</p>
                                        )}
                                        <p><small>Find the amount from the appropriate table on page 4 using the annual wages from the highestpaying job in the “Higher Paying Job” row and the annual wages for your next highest paying jobin the “Lower Paying Job” column. Find the value at the intersection of the two household salariesand enter that value on line 2a</small></p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>2b.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('two_b')} />
                                        {errors4.two_b?.message && (
                                            <p className='text-danger'>{errors4.two_b.message}</p>
                                        )}
                                        <p><small>Add the annual wages of the two highest paying jobs from line 2a together and use the total as the wages in the “Higher Paying Job” row and use the annual wages for your third job in the “Lower Paying Job” column to find the amount from the appropriate table on page 4 and enter this amount on line 2b ($)</small></p>
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>2c.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('two_c')} />
                                        {errors4.two_c?.message && (
                                            <p className='text-danger'>{errors4.two_c.message}</p>
                                        )}
                                        <p><small>Add the amounts from lines 2a and 2b and enter the result on line 2c ($)</small></p>
                                    </div>
                                </div>

                            </div>


                            <div className="row">


                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>3. Enter the number of pay periods per year for the highest-paying job. For example, if that job pays weekly, enter 52; if it pays every other week, enter 26; if it pays monthly, enter 12, etc.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('number_pay_periods')} />
                                        {errors4.number_pay_periods?.message && (
                                            <p className='text-danger'>{errors4.number_pay_periods.message}</p>
                                        )}
                                    </div>
                                </div>
                            </div>


                            <div className="row">


                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>4. Divide the annual amount on line 1 or line 2c by the number of pay periods on line 3. Enter this amount here and in Step 4(c) of Form W-4 for the highest paying job (along with any other additional amount you want to be withheld) ($)</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('annual_amount', {


                                            minLength: {
                                                value: 1,
                                                message: "Please enter valid  value."
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "Please enter valid value."
                                            }
                                        })} />
                                        {errors4.annual_amount?.message && (
                                            <p className='text-danger'>{errors4.annual_amount.message}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>Step 4(b)—Deductions Worksheet</div>

                            <div className="row">


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>1.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('deduction_1', {


                                            minLength: {
                                                value: 1,
                                                message: "Please enter valid  value."
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "Please enter valid value."
                                            }
                                        })} />
                                        {errors4.deduction_1?.message && (
                                            <p className='text-danger'>{errors4.deduction_1.message}</p>
                                        )}

                                        <p><small>Enter an estimate of your 2022 itemized deductions (from Schedule A (Form 1040)). Such deductionsmay include qualifying home mortgage interest, charitable contributions, state and local taxes (up to$10,000), and medical expenses in excess of 7.5% of your income ($)</small></p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>2.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('deduction_2', {


                                            minLength: {
                                                value: 1,
                                                message: "Please enter valid  value."
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "Please enter valid value."
                                            }
                                        })} />
                                        {errors4.deduction_2?.message && (
                                            <p className='text-danger'>{errors4.deduction_2.message}</p>
                                        )}
                                        <p><small>• $25,900 if you’re married filing jointly or qualifying widow(er)• $19,400 if you’re head of household• $12,950 if you’re single or married filing separately</small></p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>3.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('deduction_3', {


                                            minLength: {
                                                value: 1,
                                                message: "Please enter valid  value."
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "Please enter valid value."
                                            }
                                        })} />
                                        {errors4.deduction_3?.message && (
                                            <p className='text-danger'>{errors4.deduction_3.message}</p>
                                        )}
                                        <p><small>If line 1 is greater than line 2, subtract line 2 from line 1 and enter the result here. If line 2 is greaterthan line 1, enter “-0-” ($)</small></p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>4.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('deduction_4', {


                                            minLength: {
                                                value: 1,
                                                message: "Please enter valid  value."
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "Please enter valid value."
                                            }
                                        })} />
                                        {errors4.deduction_4?.message && (
                                            <p className='text-danger'>{errors4.deduction_4.message}</p>
                                        )}
                                        <p><small>Enter an estimate of your student loan interest, deductible IRA contributions, and certain other adjustments (from Part II of Schedule 1 (Form 1040)). See Pub. 505 for more information ($)</small></p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>5.</label>
                                        <input className="form-control text-box single-line" type="text"  {...register4('deduction_5', {


                                            minLength: {
                                                value: 1,
                                                message: "Please enter valid  value."
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "Please enter valid value."
                                            }
                                        })} />
                                        {errors4.deduction_5?.message && (
                                            <p className='text-danger'>{errors4.deduction_5.message}</p>
                                        )}
                                        <p><small>Add lines 3 and 4. Enter the result here and in Step 4(b) of Form W-4 ($)</small></p>
                                    </div>
                                </div>

                            </div>

                            <p>Privacy Act and Paperwork Reduction Act Notice. We ask for the information on this form to carry out the Internal Revenue laws of the United States. Internal Revenue Code sections 3402(f)(2) and 6109 and their regulations require you to provide this information; your employer uses it to determine your federal income tax withholding. Failure to provide a properly completed form will result in your being treated as a single person with no other entries on the form; providing fraudulent information may subject you to penalties. Routine uses of this information include giving it to the Department of Justice for civil and criminal litigation; to cities, states, the District of Columbia, and U.S. commonwealths and possessions for use in administering their tax laws; and to the Department of Health and Human Services for use in the National Directory of New Hires. We may also disclose this information to other countries under a tax treaty, to federal and state agencies to enforce federal nontax criminal laws, or to federal law enforcement and intelligence agencies to combat terrorism.</p>
                            <p>You are not required to provide the information requested on a form that is
                                subject to the Paperwork Reduction Act unless the form displays a valid OMB
                                control number. Books or records relating to a form or its instructions must be
                                retained as long as their contents may become material in the administration of
                                any Internal Revenue law. Generally, tax returns and return information are
                                confidential, as required by Code section 6103.</p>
                            <p>The average time and expenses required to complete and file this form will vary depending on individual circumstances. For estimated averages, see the
                                instructions for your income tax return. If you have suggestions for making this form simpler, we would be happy to hear from you. See the instructions for your income tax return.</p>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group text-center">
                                        <p><img style={{ maxWidth: 400 }} src="/w4-1.png" /></p>
                                        <p><img style={{ maxWidth: 400 }} src="/w4-2.png" /></p>
                                        <p><img style={{ maxWidth: 400 }} src="/w4-3.png" /></p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" form='w4_candidate'>Save</button>
                        <span className="btn btn-default" onClick={w4handleClose}>Cancel</span>

                    </Modal.Footer>
                </Modal>



                <Modal show={njshow} onHide={njhandleClose} dialogClassName="custom-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>NJ Employee Withholding</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form key={5} onSubmit={handleSubmit5(saveNJ)} id="nj_candidate">
                            <div className="form-w2">
                                <h6><b>Employee's Withholding Allowance Certificate</b></h6>
                                <p>State of New Jersey - Division of Taxation</p>
                                <hr />
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>SS# <span className="required">*</span></label>
                                            <input className="form-control text-box single-line" type="text"  {...register5("ss", { required: "Please enter SS." })} />
                                            {errors5.ss?.message && (
                                                <p className='text-danger'>{errors5.ss.message}</p>
                                            )}
                                        </div>
                                    </div>
                                </div><div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Filing Status: (Check only one box) <span className="required">*</span></label>
                                            <div className="raw">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="filing_status" value={1} {...register5("filing_status", { required: "Please select filing status." })} />
                                                    <label className="form-check-label">Single</label>

                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="filing_status" value={2} {...register5("filing_status", { required: "Please select filing status." })} />
                                                    <label className="form-check-label">Married/Civil Union Couple Joint</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="filing_status" value={3} {...register5("filing_status", { required: "Please select filing status." })} />
                                                    <label className="form-check-label">Married/Civil Union Partner Separate</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="filing_status" value={4} {...register5("filing_status", { required: "Please select filing status." })} />
                                                    <label className="form-check-label">Head of Household</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="filing_status" value={5} {...register5("filing_status", { required: "Please select filing status." })} />
                                                    <label className="form-check-label">Qualifying Widow(er)/Surviving Civil Union Partner</label>
                                                </div>

                                                {errors5.filing_status?.message && (
                                                    <p className='text-danger'>{errors5.filing_status.message}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>If you have chosen to use the chart from instruction A, <br />enter the appropriate letter here</label>
                                            <input className="form-control text-box single-line" type="text" {...register5("chart_from_instruction")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Total number of allowances you are claiming <br />(see instructions)</label>
                                            <input className="form-control text-box single-line" type="text"  {...register5("total_number_allowances")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Additional amount you want to be deducted from each pay. ($)</label>
                                            <input className="form-control text-box single-line" type="text"  {...register5("additional_amount")} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>I claim exemption from withholding of NJ Gross Income Tax and I certify that I have met the conditions in the instructions of the NJ-W4. If you have met the conditions, enter “EXEMPT” here</label>
                                            <input className="form-control text-box single-line" type="text"  {...register5("claim_exemption")} />
                                        </div>
                                    </div>
                                </div><div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Under penalties of perjury, I certify that I am entitled to the number of withholding allowances claimed on this certificate or entitled to claim exempt status.</label>
                                            <input className="form-control text-box single-line" type="text"   {...register5("under_penalties_perjury")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6><b>BASIC INSTRUCTIONS</b></h6>
                                        <p><strong>BASIC INSTRUCTIONS</strong> Line 1 Enter your name, address and social security number in the spaces provided.</p>
                                        <p>Line 2 Check the box that indicates your filing status. If you checked Box 1 (Single) or Box 3 (Married/Civil Union Partner Separate) you will be withheld at<br /> Rate A.<br /><em>Note:</em> If you have checked Box 2 (Married/Civil Union Couple Joint), Box 4 (Head of Household) or Box 5 (Qualifying<br />Widow(er)/Surviving Civil Union Partner) and either your spouse/civil union partner works or you have more than one job<br />or more than one source of income and the combined total of all wages is greater than $50,000, see instruction A below.<br />If you do not complete Line 3, you will be withheld at Rate B.</p>
                                        <p>Line 3 If you have chosen to use the wage chart below, enter the appropriate letter.</p>
                                        <p>Line 4 Enter the number of allowances you are claiming. Entering a number on this line will decrease the amount of withholding and could result in an<br />underpayment on your return.</p>
                                        <p>Line 5 Enter the amount of additional withholdings you want deducted from each pay.</p>
                                        <p>Line 6 Enter “EXEMPT” to indicate that you are exempt from New Jersey Gross Income Tax Withholdings, if you meet one of the following conditions:<br />• Your filing status is <strong>SINGLE or MARRIED/CIVIL UNION PARTNER SEPARATE</strong> and your wages plus your taxable nonwageincome will be $10,000 or less for the current year.<br />• Your filing status is <strong>MARRIED/CIVIL UNION COUPLE JOINT</strong>, and your wages combined with your spouse’s/civil unionpartner’s wages plus your taxable non wage income will be $20,000 or less for the current year.<br />• Your filing status is <strong>HEAD OF HOUSEHOLD</strong> or <strong>QUALIFYING WIDOW(ER)/SURVIVING CIVIL UNION PARTNER</strong> and yourwages plus your taxable nonwage income will be $20,000 or less for the current year.</p>
                                        <p>Your exemption is good for <strong>ONE</strong> year only. You must complete and submit a form each year certifying you have no New Jersey Gross Income Tax liability andclaim exemption from withholding. If you have questions about eligibility, filing status, withholding rates, etc. when completing this form, call the Division ofTaxation’s Customer Service Center at 609-292-6400.</p>
                                        <p><strong>Instruction A - Wage Chart</strong><br /> This chart is designed to increase withholdings on your wages, if these wages will be taxed at a higher rate due to inclusion of other wages or income onour NJ-1040 return. It is not intended to provide withholding for other income or wages. If you need additional withholdings for other income or wagesuse Line 5 on the NJ-W4. This Wage Chart applies to taxpayers who are married/civil union couple filing jointly, heads of households or qualifying<br />widow(er)/surviving civil union partner. Single individuals or married/civil union partners filing separate returns do not need to use this chart. If you<br />have indicated filing status #2, 4 or 5 on the above NJ-W4 and your taxable income is greater than $50,000, you should strongly consider using the Wage<br />Chart. (See the Rate Tables on the reverse side to estimate your withholding amount).</p>

                                        <p>HOW TO USE THE CHART<br />
                                            1) Find the amount of your wages in the left-hand column.<br />
                                            2) Find the amount of the total for all other wages (including
                                            your spouse’s/civil union partner’s wages) along the top
                                            row.<br />
                                            3) Follow along the row that contains your wages until you
                                            come to the column that contains the other wages.<br />
                                            4) This meeting point indicates the Withholding Table that best
                                            reflects your income situation.<br />
                                            5) If you have chosen this method, enter the “letter” of the
                                            withholding rate table on Line 3 of the NJ-W4.</p>

                                        <p>NOTE: If your income situation substantially increases (or
                                            decreases) in the future, you should resubmit a
                                            revised NJ-W4 to your employer.</p>
                                    </div>
                                </div><div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group text-center">
                                            <p><img style={{ maxWidth: 400 }} src="/Wage-Chart.png" /></p>
                                            <p><img style={{ maxWidth: 400 }} src="/Rate-A.png" /></p>
                                            <p><img style={{ maxWidth: 400 }} src="/Rate-B-C.png" /></p>
                                            <p><img style={{ maxWidth: 400 }} src="/Rate-D-E.png" /></p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" form='nj_candidate'>Save</button>
                        <span className="btn btn-default" onClick={njhandleClose}>Cancel</span>

                    </Modal.Footer>
                </Modal>
                <Modal show={w9show} onHide={w9handleClose} dialogClassName="custom-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>Request for Taxpayer Identification Number and Certification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form key={5} onSubmit={handleSubmit7(saveW9Form)} id="nj_candidate">
                            <div className="form-w2">
                                <h6><b>Go to www.irs.gov/FormW9 for instructions and the latest information.</b></h6>
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Name as shown on your income tax return Name is required on this line; do not leave this line blank.<span className="required">*</span></label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("name", { required: "Please enter name." })} />
                                            {errors7.name?.message && (
                                                <p className='text-danger'>{errors7.name.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Business name/disregarded entity name, if different from above<span className="required">*</span></label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("business_name", { required: "Please enter Business Name." })} />
                                            {errors7.business_name?.message && (
                                                <p className='text-danger'>{errors7.business_name.message}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Check appropriate box for federal tax classification of the person whose name is entered on line 1. Check only one of the following seven boxes. <span className="required">*</span></label>
                                            <div className="raw">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="federal_tax_classification" value={1} {...register7("federal_tax_classification", { required: "Please select federal tax." })} />
                                                    <label className="form-check-label">Individual/sole proprietor or single-member LLC</label>

                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="federal_tax_classification" value={2} {...register7("federal_tax_classification", { required: "Please select federal tax." })} />
                                                    <label className="form-check-label">C Corporation</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="federal_tax_classification" value={3} {...register7("federal_tax_classification", { required: "Please select federal tax." })} />
                                                    <label className="form-check-label">S Corporation</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="federal_tax_classification" value={4} {...register7("federal_tax_classification", { required: "Please select federal tax." })} />
                                                    <label className="form-check-label">Partnership</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="federal_tax_classification" value={5} {...register7("federal_tax_classification", { required: "Please select federal tax." })} />
                                                    <label className="form-check-label">Trust/estate</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="federal_tax_classification" value={5} {...register7("federal_tax_classification", { required: "Please select federal tax." })} />
                                                    <label className="form-check-label">Limited liability company</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" name="federal_tax_classification" value={5} {...register7("federal_tax_classification", { required: "Please select federal tax." })} />
                                                    <label className="form-check-label">Other</label>
                                                </div>

                                                {errors7.federal_tax_classification?.message && (
                                                    <p className='text-danger'>{errors7.federal_tax_classification.message}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>If you selected "Limited liability company", enter the tax classification (C=C corporation, S=S corporation, P=Partnership)</label>
                                            <input className="form-control text-box single-line" type="text" {...register7("limited_liablity_company")} />
                                            {errors7.limited_liablity_company?.message && (
                                                <p className='text-danger'>{errors7.limited_liablity_company.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label> Exemptions (codes apply only to certain entities, not individuals; see instructions on page 3):</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Exempt payee code (if any)</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("extempt_payee_code")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Exemption from FATCA reporting code (if any)</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("exemption_fatcha_report_code")} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("address_one")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("city")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>State</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("state")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Zip Code</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("zipcode")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Requester's name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("first_name")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("last_name")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Requester's address</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Number, Street, and Apt. or Suite No.</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("requester_address")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("requester_city")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>State</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("requester_state")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Zip Code</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("requester_zipcode")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>List account number(s) here (optional)</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("account_number")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6><b>Part I - Taxpayer Identification Number (TIN)</b></h6>
                                        <p>Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid backup withholding. For individuals, this is generally your social security number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other entities, it is your employer identification number (EIN). If you do not have a number, see How to get a TIN, later.</p>
                                        <p><strong>Note:</strong> If the account is in more than one name, see the instructions for line 1. Also see What Name and Number To Give the Requester for guidelines on whose number to enter.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Social security number</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("social_security_number")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Employer identification number</label>
                                            <input className="form-control text-box single-line" type="text"  {...register7("employer_identification_number")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6><b>Part II - Certification</b></h6>
                                        <p>Under penalties of perjury, I certify that:</p>
                                        <p>1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and</p>
                                        <p>2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and</p>
                                        <p>3. I am a U.S. citizen or other U.S. person (defined below); and</p>
                                        <p>4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.</p>
                                        <p><strong>Certification instructions.</strong> You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Electronically Signed <span className="required">*</span></label>                           
                                            <Controller
                                            name="electronically_signed_new"
                                            control={control7}
                                            rules={{
                                                validate: (value) => { 
                                                    console.log(apiData?.candidateFormW9?.[0]?.singature)
                                                    if (apiData?.candidateFormW9?.[0]?.singature == undefined && value == undefined) 
                                                    {
                                                        return 'Please write a signature.';          
                                                    }
                                                }
                                            }}
                                            render={({ field }) => (
                                                <SignatureCanvas
                                                ref={depositsigRef}
                                                onEnd={() => field.onChange(depositformatIntoPng())}
                                                penColor="black"
                                                canvasProps={{
                                                    width: 315,
                                                    height: 200,
                                                    style: { border: "1px solid black" },
                                                }}
                                                />
                                            )}
                                            />
        

                                            {errors2.electronically_signed_new?.message && (
                                                <p className='text-danger'>{errors2.electronically_signed_new.message}</p>
                                            )}
                                            <p><a onClick={depositclearSignature} className='btn btn-default'>Clear</a> </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {apiData?.candidateFormW9?.[0]?.singature && <img src={apiData?.candidateFormW9?.[0]?.singature}></img>}
                                    </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Date</label>
                                        <Controller
                                            control={control7}
                                            name='date'
                                            rules={{ required: "Please select date of birth" }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    className="form-control text-box single-line datetimepicker-input"
                                                    placeholderText='Select date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    maxDate={new Date()}
                                                    dateFormat="MM/dd/yyyy"

                                                />


                                            )}
                                        />
                                    </div>
                                </div>
                                </div>

                            </div>

                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" form='nj_candidate'>Save</button>
                        <span className="btn btn-default" onClick={w9handleClose}>Cancel</span>

                    </Modal.Footer>
                </Modal>

                <Modal show={genderInequityshow} onHide={genderInequityhandleClose} dialogClassName="custom-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>Right to be Free of Gender Inequity</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form key={5} onSubmit={handleSubmit8(saveGenderInequity)} id="nj_candidate">
                            <div className="form-w2">
                                
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <h6><b>Part II - Certification</b></h6> */}
                                        <p>or Bias in Pay, Compensation, Benefits
                                            or Other Terms and Conditions of Employment
                                        </p>

                                        <p>New Jersey and federal laws prohibit employers from discriminating against an individual with respect to his/her pay, compensation, benefits, or terms, conditions or privileges of employment because of the individual's sex.</p>

                                        <h6><b>FEDERAL LAW</b></h6>
                                        <p>Title VII of the Civil Rights Act of 1964 prohibits employment discrimination based on, among other things, an individual's sex. Title VII claims must be filed with the United States Equal Employment Opportunity Commission (EEOC) before they can be brought in court. Remedies under Title VII may include an order restraining unlawful discrimination, back pay, and compensatory and punitive damages. The Equal Pay Act of 1963 (EPA) prohibits discrimination in compensation based on sex. EPA claims can be filed either with the EEOC or directly with the court. Remedies under the EPA may include the amount of the salary or wages due from the employer, plus an additional equal amount as liquidated damages. Please be mindful that in order for a disparity in compensation based on sex to be actionable under the EPA, it must be for equal work on jobs the performance of which requires equal skill, effort, and responsibility, and which are performed under similar working conditions. There are strict time limits for filing charges of employment discrimination. For further information, contact the EEOC at 800-669-4000 or at www.eeoc.gov.</p>

                                        <h6><b>NEW JERSEY LAW</b></h6>
                                        <p>The New Jersey Law Against Discrimination (LAD) prohibits employment discrimination based on, among other things, an individual's sex. LAD claims can be filed with the New Jersey Division on Civil Rights (NJDCR) or directly in court. Remedies under the LAD may include an order restraining unlawful discrimination, back pay, and compensatory and punitive damages.</p>
                                        <p>Another State law, N.J.S.A. 34:11-56.1 et seq., prohibits discrimination in the rate or method of payment of wages to an employee because of his or her sex. Claims under this wage discrimination law may be filed with the New Jersey Department of Labor and Workforce Development (NJDLWD) or directly in court. Remedies under this law may include the full amount of the salary or wages owed, plus an additional equal amount as liquidated damages.</p>
                                        <p>Please be mindful that under the State wage discrimination law a differential in pay between employees based on a reasonable factor or factors other than sex shall not constitute discrimination.</p>
                                        <p>There are strict time limits for filing charges of employment discrimination. For more information regarding LAD claims, contact the NJDCR at 609-292-4605 or at <a>www.njcivilrights.gov</a>. For information concerning N.J.S.A. 34:11-56.1 et seq., contact the Division of Wage and Hour Compliance within the NJDLWD at 609-292-2305 or at <a>http://ww.state.nj.us</a></p>
                                        <p>This notice must be conspicuously displayed.</p>
                                    </div>

                                    <div className="col-md-12">
                                        <h5><b>LWD</b></h5>
                                        <hr></hr>
                                        <p>LABOR AND WORKFORCE DEVELOPMENT</p>
                                    </div>
                                    <div className="col-md-12">
                                        <h5><b>AD-290 (1/14)</b></h5>
                                        <hr></hr>
                                        <p>Acknowledgment of Receipt of Gender Equity Notification</p>
                                        <p>I received a copy of the gender equity notification on the date listed below.</p>
                                        <p>I have read it and I understand it.</p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Full Name (print)</label>
                                            <input className="form-control text-box single-line" type="text"  {...register8("name_print")} />
                                            {/* {console.log("register8name_printedit ", register8("name_print")) }   */}
                                            {errors8.name_print?.message && (
                                                <p className='text-danger'>{errors8.name_print.message}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name (signature) </label>                           
                                            <Controller
                                            name="name_sign"
                                            control={control8}
                                            // rules={{
                                            //     validate: (value) => { 
                                            //         console.log(apiData?.TaxGenderInequity?.[0]?.signature)
                                            //         if (apiData?.TaxGenderInequity?.[0]?.signature == undefined && value == undefined) 
                                            //         {
                                            //             return 'Please write a signature.';          
                                            //         }
                                            //     }
                                            // }}
                                            render={({ field }) => (
                                                <SignatureCanvas
                                                ref={depositsigRef}
                                                onEnd={() => field.onChange(depositformatIntoPng())}
                                                penColor="black"
                                                canvasProps={{
                                                    width: 315,
                                                    height: 200,
                                                    style: { border: "1px solid black" },
                                                }}
                                                />
                                            )}
                                            />
        

                                            {errors8.name_sign?.message && (
                                                <p className='text-danger'>{errors8.name_sign.message}</p>
                                            )}
                                            <p><a onClick={depositclearSignature} className='btn btn-default'>Clear</a> </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {apiData?.TaxGenderInequity?.[0]?.name_sign && <img src={`${Config.API_URL}${apiData?.TaxGenderInequity?.[0]?.name_sign}`} ></img>}
                                    </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Date</label>
                                        <Controller
                                            control={control8}
                                            name='date'
                                            rules={{ required: "Please select date of birth" }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    className="form-control text-box single-line datetimepicker-input"
                                                    placeholderText='Select date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    maxDate={new Date()}
                                                    dateFormat="MM/dd/yyyy"

                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                </div>

                            </div>

                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" form='nj_candidate'>Save</button>
                        <span className="btn btn-default" onClick={genderInequityhandleClose}>Cancel</span>

                    </Modal.Footer>
                </Modal>



                <Modal show={scoreCardshow} onHide={scoreCardhandleClose} dialogClassName="custom-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>Scorecard</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form key={6} onSubmit={handleSubmit6(savescoreCard)} id="scorecard_candidate">

                            <div className="modal-body">
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <div class="col-md-12 row">
                                            <label className="col-form-label">Updated By: {authCheck?.first_name} {authCheck?.last_name}</label>
                                            <div class="col-md-4">
                                                {/* <label></label>
                                                <input class="form-control" style={{
    border: 'none',
    background: 'white',
    fontSize: '14px',
  }}  {...register6("updated_by_name")}></input> */}
                                            </div>
                                        </div>
                                        <div className="label-wrapper float-left">
                                            <label className="col-form-label">Comment <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <textarea rows="5" maxLength={255} className="form-control textarea"   {...register6("comment", { required: "Please enter comment." })}></textarea>
                                        <label className="col-form-label">255 characters are allowed</label>
                                        {errors6.comment?.message && (
                                            <p className='text-danger'>{errors6.comment.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <div className="label-wrapper float-left">
                                            <label className="col-form-label">Overall Rating? <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="scorecard-overall">
                                            <div className="scorecard-switch">
                                                <label><input type="radio" name="scorecard_overall" value={1}   {...register6("rate", { required: "Please select overall rating." })} /><span className="scorecard-option poor"> <span className="scorecard-name ng-binding">1</span></span></label>
                                                <label><input type="radio" name="scorecard_overall" value={2} {...register6("rate", { required: "Please select overall rating." })} /><span className="scorecard-option poor"> <span className="scorecard-name ng-binding">2</span></span></label>
                                                <label><input type="radio" name="scorecard_overall" value={3}  {...register6("rate", { required: "Please select overall rating." })} /><span className="scorecard-option neutral"><span className="scorecard-name ng-binding">3</span></span></label>
                                                <label><input type="radio" name="scorecard_overall" value={4}  {...register6("rate", { required: "Please select overall rating." })} /><span className="scorecard-option good"> <span className="scorecard-name ng-binding">4</span></span></label>
                                                <label><input type="radio" name="scorecard_overall" value={5} {...register6("rate", { required: "Please select overall rating." })} /><span className="scorecard-option good"> <span className="scorecard-name ng-binding">5</span></span></label></div>
                                        </div>

                                        {errors6.rate?.message && (
                                            <p className='text-danger'>{errors6.rate.message}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" form='scorecard_candidate'>Save</button>
                        <span className="btn btn-default" onClick={scoreCardhandleClose}>Cancel</span>

                    </Modal.Footer>
                </Modal>
                <Modal show={additionalDocumentshow} onHide={additionalDocumentClose} dialogClassName="custom-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>Additional Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form key={9} onSubmit={handleSubmit9(saveadditionalDocuments)} id="additonal_doc_form">

                            <div className="modal-body">
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <div className="label-wrapper float-left">
                                            <label className="col-form-label">Document Name <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <input type="hidden" {...register9("id")} />
                                        <input className="form-control text-box single-line" type="text"  {...register9("title", { required: "Please enter title." })} />
                                        {errors9.name?.message && (
                                            <p className='text-danger'>{errors9.name.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <div className="label-wrapper float-left">
                                            <label className="col-form-label">Upload Document <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <input className="form-control text-box single-line" type="file" accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,.pdf,.doc,.docx,.xlsx,image/png, image/gif, image/jpeg' {...register9('additonal_document', {
                                        validate: {
                                            fileupload: (files) => {
                                                    if (files.length != null) {
                                                        if (files[0]?.size > 100000000) {
                                                            return "Allow only max 10MB.";
                                                        }
                                                        // else if (!['.pdf','.doc','.docx','.xlsx','image/jpeg','image/gif','image/png','application/vnd.ms-powerpoint', 'application/vnd.ms-excel','application/msword','application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0]?.type)) {
                                                        //     return "Allow only pdf, doc, docx format.";
                                                        // }
                                                    }

                                                }
                                            }
                                        })} />
                                        {errors9.resume_new?.message && (
                                            <p className='text-danger'>{errors9.resume_new.message}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" form='additonal_doc_form'>Save {loading ?<span className="spinner-grow spinner-grow-sm"></span>:''}</button>
                        <span className="btn btn-default" onClick={additionalDocumentClose}>Cancel</span>

                    </Modal.Footer>
                </Modal>
                
            </div>
        </div>

    );
};

export default EditApplication;
